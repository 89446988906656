import _ from 'lodash';
import { createSlice, createSelector, Dispatch } from '@reduxjs/toolkit';

import utils from 'lib/utils';
import config from 'config';
import fetch from 'services/fetch';
import { RootState } from './store';

const { baseUrl } = config;

type RefData = { value: number; label: string };

type ReferenceData = {
  commodity: RefData[];
  commodityForm: RefData[];
  location: RefData[];
  payment_method: RefData[];
  vehicle_type: RefData[];
  req_offer_status_enum: RefData[];
  req_offer_response_status_enum: RefData[];
  // consignor: RefData[];
  // consignee: RefData[];
  // vehicle: RefData[];
  // driver: RefData[];
  pickUpLocation: [];
  delivery_allotment_status_enum: RefData[];
  counterparty: RefData[];
};

export interface ReferenceDataState {
  data: ReferenceData;
  busy: boolean;
}

const REFERENCE_TYPE_MAP = {
  company: 1,
  commodity: 8,
  commodityForm: 9,
  commoditySpec: 10,
  commodityTypeMaster: 'BF_SETUP_COMMODITY_TYPE_MASTER',
  commodityFormMaster: 'BF_SETUP_COMMODITY_FORM_MASTER',
  commodityResidueMaster: 'BF_SETUP_COMMODITY_RESIDUE_MASTER',
  subscriptionPlan: 'BF_SETUP_SUBSCRIPTION_PLAN',
  commodityType: 11,
  location: 17,
  payment_method: 40,
  uom: 27,
  currency: 15,
  paymentTerm: 18,
  vehicle_type: 33,
  companyAffiliateType: 30,
  hashTags: 3,
  roleTeam: 'BF_SEC_ROLE',
};

const initialState: ReferenceDataState = {
  data: {
    commodity: [],
    commodityForm: [],
    location: [],
    payment_method: [],
    vehicle_type: [],
    req_offer_status_enum: [],
    req_offer_response_status_enum: [],
    // consignor: [],
    // consignee: [],
    // vehicle: [],
    // driver: [],
    pickUpLocation: [],
    delivery_allotment_status_enum: [],
    counterparty: [],
  },
  busy: false,
};

const slice = createSlice({
  name: 'filterOptions',
  initialState,
  reducers: {
    setBusy(state, action) {
      state.busy = action.payload;
    },

    update(state, action) {
      const { entity, options } = action.payload;
      state.data[entity] = options;
      state.busy = false;
    },
  },
});

const getReferenceData = (entity: string) => {
  const entityType = _.get(REFERENCE_TYPE_MAP, entity, 0);

  let dataOperationEnum = 3;

  if (entityType === 0) {
    throw new Error('Wrong paramerter passed, check REFERENCE_TYPE_MAP');
  }
  const params = {
    Reference_Data_Enum: entityType,
    Reference_Data_Operation_Enum: dataOperationEnum,
  };
  const qs = utils.getQs(params);
  // const qs = `Reference_Data_Enum=${entityType}&Reference_Data_Operation_Enum=${dataOperationEnum}`;
  let url = `/BioFuelRegistration/api/ReferenceData/ReadReferenceData?${qs}`;

  if (entity === 'location') {
    url = `/BioFuelRegistration/api/ReferenceData/FetchAllDistrictStateCombinations_Eloc`;
  }

  return async (dispatch: Dispatch) => {
    try {
      dispatch(slice.actions.setBusy(true));
      const response = await fetch.fetchOne<any[]>(url);
      // let response = await utils.fetch(url).ready;
      // response = await utils.processApiRes(response)
      const options: any = [];

      for (const raw of response.data) {
        let option = {};

        switch (entity) {
          case 'location':
            option = {
              value: raw.displaymemberdistrict_eloc,
              text: `${raw.displaymemberdistrict_eloc}, ${raw.displaymemberstate_eloc}`,
            };
            break;
          default:
            option = {
              value: raw.value_member,
              text: raw.display_member,
            };
        }

        options.push(option);
      }

      dispatch(slice.actions.update({ entity, options }));
      return response;
    } catch (errors) {
      ///dd
    }

    dispatch(slice.actions.setBusy(false));
  };
};

const getEnumData = (entity: string) => {
  const qs = utils.getQs({ Enum_Group: 'ReqOfferStatusEnum' });
  let url = `/BioFuelRegistration/api/ReferenceData/GetEnumerator?${qs}`;

  return async (dispatch: Dispatch) => {
    try {
      dispatch(slice.actions.setBusy(true));
      const response = await fetch.fetchOne<any[]>(url);
      const options: any = [];

      for (const raw of response.data) {
        let option = {};

        switch (entity) {
          default:
            option = {
              value: raw.value_member,
              text: raw.display_member,
            };
        }
        options.push(option);
      }

      dispatch(slice.actions.update({ entity: 'req_offer_status_enum', options }));
    } catch (errors) {
      ///dd
    }

    dispatch(slice.actions.setBusy(false));
  };
};

const getPlatformChargeAtEnumData = (entity: string) => {
  const qs = utils.getQs({ Enum_Group: 'PlatformChargeAtEnum' });
  let url = `/BioFuelRegistration/api/ReferenceData/GetEnumerator?${qs}`;

  return async (dispatch: Dispatch) => {
    try {
      dispatch(slice.actions.setBusy(true));
      const response = await fetch.fetchOne<any[]>(url);
      const options: any = [];

      for (const raw of response.data) {
        let option = {};

        switch (entity) {
          default:
            option = {
              value: raw.value_member,
              text: raw.display_member,
            };
        }
        options.push(option);
      }

      dispatch(slice.actions.update({ entity: 'platform_charge_at_enum', options }));
    } catch (errors) {
      ///dd
    }

    dispatch(slice.actions.setBusy(false));
  };
};
const getOfferBidEnumData = (entity: string) => {
  const qs = utils.getQs({ Enum_Group: 'ReqOfferResponseStatusEnum' });
  let url = `/BioFuelRegistration/api/ReferenceData/GetEnumerator?${qs}`;

  return async (dispatch: Dispatch) => {
    try {
      dispatch(slice.actions.setBusy(true));
      const response = await fetch.fetchOne<any[]>(url);
      const options: any = [];

      for (const raw of response.data) {
        let option = {};

        switch (entity) {
          default:
            option = {
              value: raw.value_member,
              text: raw.display_member,
            };
        }
        options.push(option);
      }

      dispatch(slice.actions.update({ entity: 'req_offer_response_status_enum', options }));
    } catch (errors) {
      ///dd
    }

    dispatch(slice.actions.setBusy(false));
  };
};

const getDealEnumData = (entity: string) => {
  let url;
  if (entity == 'processing_deal_status_enum_code') {
    const qs = utils.getQs({ Enum_Group: 'ProcessingDealStatusEnum' });
    url = `/BioFuelRegistration/api/ReferenceData/GetEnumerator?${qs}`;
  } else {
    const qs = utils.getQs({ Enum_Group: 'DealStatusEnum' });
    url = `/BioFuelRegistration/api/ReferenceData/GetEnumerator?${qs}`;
  }

  return async (dispatch: Dispatch) => {
    try {
      dispatch(slice.actions.setBusy(true));
      const response = await fetch.fetchOne<any[]>(url);
      const options: any = [];

      for (const raw of response.data) {
        let option = {};

        switch (entity) {
          default:
            option = {
              value: raw.value_member,
              text: raw.display_member,
            };
        }
        options.push(option);
      }

      dispatch(slice.actions.update({ entity: 'deal_status_enum', options }));
    } catch (errors) {
      ///dd
    }

    dispatch(slice.actions.setBusy(false));
  };
};

const getKYCStatusEnumData = (entity: string) => {
  const qs = utils.getQs({ Enum_Group: 'kycStatusEnum' });
  let url = `/BioFuelRegistration/api/ReferenceData/GetEnumerator?${qs}`;

  return async (dispatch: Dispatch) => {
    try {
      dispatch(slice.actions.setBusy(true));
      const response = await fetch.fetchOne<any[]>(url);

      const options: any = [];

      for (const raw of response.data) {
        let option = {};

        switch (entity) {
          default:
            option = {
              value: raw.value_member,
              text: raw.display_member,
            };
        }
        options.push(option);
      }

      dispatch(slice.actions.update({ entity: 'kyc_status_enum', options }));
    } catch (errors) {
      ///dd
    }

    dispatch(slice.actions.setBusy(false));
  };
};

const getInvoiceStatusEnumData = (entity: string) => {
  const qs = utils.getQs({ Enum_Group: 'invoiceStatusEnum' });
  let url = `/BioFuelRegistration/api/ReferenceData/GetEnumerator?${qs}`;

  return async (dispatch: Dispatch) => {
    try {
      dispatch(slice.actions.setBusy(true));
      const response = await fetch.fetchOne<any[]>(url);

      const options: any = [];

      for (const raw of response.data) {
        let option = {};

        switch (entity) {
          default:
            option = {
              value: raw.value_member,
              text: raw.display_member,
            };
        }
        options.push(option);
      }

      dispatch(slice.actions.update({ entity: 'invoice_status_enum', options }));
    } catch (errors) {
      ///dd
    }

    dispatch(slice.actions.setBusy(false));
  };
};

const getInvoiceVerificationStatusEnumData = (entity: string) => {
  const qs = utils.getQs({ Enum_Group: 'InvoiceVerificationEnum' });
  let url = `/BioFuelRegistration/api/ReferenceData/GetEnumerator?${qs}`;

  return async (dispatch: Dispatch) => {
    try {
      dispatch(slice.actions.setBusy(true));
      const response = await fetch.fetchOne<any[]>(url);

      const options: any = [];

      for (const raw of response.data) {
        let option = {};

        switch (entity) {
          default:
            option = {
              value: raw.value_member,
              text: raw.display_member,
            };
        }
        options.push(option);
      }

      dispatch(slice.actions.update({ entity: 'invoice_verification_enum', options }));
    } catch (errors) {
      ///dd
    }

    dispatch(slice.actions.setBusy(false));
  };
};

const getTypeOfInvoiceEnumData = (entity: string) => {
  const qs = utils.getQs({ Enum_Group: 'InvoiceVerificationEnum' });
  let url = `/BioFuelRegistration/api/ReferenceData/GetEnumerator?${qs}`;

  return async (dispatch: Dispatch) => {
    try {
      dispatch(slice.actions.setBusy(true));
      const response = await fetch.fetchOne<any[]>(url);

      const options: any = [];

      for (const raw of response.data) {
        let option = {};

        switch (entity) {
          default:
            option = {
              value: raw.value_member,
              text: raw.display_member,
            };
        }
        options.push(option);
      }

      dispatch(slice.actions.update({ entity: 'settlement_invoice_type_enum', options }));
    } catch (errors) {
      ///dd
    }

    dispatch(slice.actions.setBusy(false));
  };
};
const getPaymentStatusEnumData = (entity: string) => {
  const qs = utils.getQs({ Enum_Group: 'paymentStatusEnum' });
  let url = `/BioFuelRegistration/api/ReferenceData/GetEnumerator?${qs}`;

  return async (dispatch: Dispatch) => {
    try {
      dispatch(slice.actions.setBusy(true));
      const response = await fetch.fetchOne<any[]>(url);

      const options: any = [];

      for (const raw of response.data) {
        let option = {};

        switch (entity) {
          default:
            option = {
              value: raw.value_member,
              text: raw.display_member,
            };
        }
        options.push(option);
      }

      dispatch(slice.actions.update({ entity: 'payment_status_enum', options }));
    } catch (errors) {
      ///dd
    }

    dispatch(slice.actions.setBusy(false));
  };
};
const getPgPaymentEnum = (entity: string) => {
  const qs = utils.getQs({ Enum_Group: 'pgpaymentenum' });
  let url = `/BioFuelRegistration/api/ReferenceData/GetEnumerator?${qs}`;

  return async (dispatch: Dispatch) => {
    try {
      dispatch(slice.actions.setBusy(true));
      const response = await fetch.fetchOne<any[]>(url);

      const options: any = [];

      for (const raw of response.data) {
        let option = {};

        switch (entity) {
          default:
            option = {
              value: raw.value_member,
              label: raw.display_member,
            };
        }
        options.push(option);
      }

      dispatch(slice.actions.update({ entity: 'pg_payment_enum', options }));
    } catch (errors) {
      ///dd
    }

    dispatch(slice.actions.setBusy(false));
  };
};

const getVehicleTypeData = (entity: string) => {
  const qs = utils.getQs({ Enum_Group: 'vehicleTypeEnum' });
  let url = `/BioFuelRegistration/api/ReferenceData/GetEnumerator?${qs}`;

  return async (dispatch: Dispatch) => {
    try {
      dispatch(slice.actions.setBusy(true));
      const response = await fetch.fetchOne<any[]>(url);

      const options: any = [];

      for (const raw of response.data) {
        let option = {};

        switch (entity) {
          default:
            option = {
              value: raw.value_member,
              text: raw.display_member,
            };
        }
        options.push(option);
      }

      dispatch(slice.actions.update({ entity: 'vehicle_type_enum', options }));
    } catch (errors) {
      ///dd
    }

    dispatch(slice.actions.setBusy(false));
  };
};

const getHireTypeData = (entity: string) => {
  const qs = utils.getQs({ Enum_Group: 'hireTypeEnum' });
  let url = `/BioFuelRegistration/api/ReferenceData/GetEnumerator?${qs}`;

  return async (dispatch: Dispatch) => {
    try {
      dispatch(slice.actions.setBusy(true));
      const response = await fetch.fetchOne<any[]>(url);

      const options: any = [];

      for (const raw of response.data) {
        let option = {};

        switch (entity) {
          default:
            option = {
              value: raw.value_member,
              text: raw.display_member,
            };
        }
        options.push(option);
      }

      dispatch(slice.actions.update({ entity: 'hire_type_enum', options }));
    } catch (errors) {
      ///dd
    }

    dispatch(slice.actions.setBusy(false));
  };
};

const getTaxApplicabilityData = (entity: string) => {
  const qs = utils.getQs({ Enum_Group: 'taxApplicabilityEnum' });
  let url = `/BioFuelRegistration/api/ReferenceData/GetEnumerator?${qs}`;

  return async (dispatch: Dispatch) => {
    try {
      dispatch(slice.actions.setBusy(true));
      const response = await fetch.fetchOne<any[]>(url);

      const options: any = [];

      for (const raw of response.data) {
        let option = {};

        switch (entity) {
          default:
            option = {
              value: raw.value_member,
              text: raw.display_member,
            };
        }
        options.push(option);
      }

      dispatch(slice.actions.update({ entity: 'tax_applicability_enum', options }));
    } catch (errors) {
      ///dd
    }

    dispatch(slice.actions.setBusy(false));
  };
};

const getConsignorData = (entity: string) => {
  let url;
  if (
    window.location.href.includes('market') ||
    window.location.href.includes('commodity/deals') ||
    window.location.href.includes('commodity/offers') ||
    window.location.href.includes('commodity/requirements')
  ) {
    const qs = utils.getQs({ is_market: true, Filter_Enum: 'Consignor' });
    url = `${baseUrl}/BioFuelCommonUtilities/api/Filter/GetFilterData?${qs}`;
  } else {
    const qs = utils.getQs({ is_market: false, Filter_Enum: 'Consignor' });
    url = `${baseUrl}/BioFuelCommonUtilities/api/Filter/GetFilterData?${qs}`;
  }

  return async (dispatch: Dispatch) => {
    try {
      dispatch(slice.actions.setBusy(true));
      const response1 = await utils.fetch(url).ready;
      const resData = await utils.processApiRes(response1);
      const response = {
        data: resData,
      };
      const options: any = [];

      for (const raw of response.data) {
        let option = {};

        switch (entity) {
          default:
            option = {
              value: raw.id,
              text: raw.code,
            };
        }
        options.push(option);
      }

      dispatch(slice.actions.update({ entity: 'consignor_id', options }));
    } catch (errors) {
      console.log('error in filter', errors);
    }

    dispatch(slice.actions.setBusy(false));
  };
};

const getConsigneeData = (entity: string) => {
  let url;
  if (
    window.location.href.includes('market') ||
    window.location.href.includes('commodity/deals') ||
    window.location.href.includes('commodity/offers') ||
    window.location.href.includes('commodity/requirements')
  ) {
    const qs = utils.getQs({ is_market: true, Filter_Enum: 'Consignee' });
    url = `${baseUrl}/BioFuelCommonUtilities/api/Filter/GetFilterData?${qs}`;
  } else {
    const qs = utils.getQs({ is_market: false, Filter_Enum: 'Consignee' });
    url = `${baseUrl}/BioFuelCommonUtilities/api/Filter/GetFilterData?${qs}`;
  }

  return async (dispatch: Dispatch) => {
    try {
      dispatch(slice.actions.setBusy(true));
      const response1 = await utils.fetch(url).ready;
      const resData = await utils.processApiRes(response1);
      const response = {
        data: resData,
      };
      const options: any = [];

      for (const raw of response.data) {
        let option = {};

        switch (entity) {
          default:
            option = {
              value: raw.id,
              text: raw.code,
            };
        }
        options.push(option);
      }

      dispatch(slice.actions.update({ entity: 'consignee_id', options }));
    } catch (errors) {
      console.log('error in filter', errors);
    }

    dispatch(slice.actions.setBusy(false));
  };
};
const getVehicleData = (entity: string) => {
  let url;
  if (
    window.location.href.includes('market') ||
    window.location.href.includes('commodity/deals') ||
    window.location.href.includes('commodity/offers') ||
    window.location.href.includes('commodity/requirements')
  ) {
    const qs = utils.getQs({ is_market: true, Filter_Enum: 'Vehicle' });
    url = `${baseUrl}/BioFuelCommonUtilities/api/Filter/GetFilterData?${qs}`;
  } else {
    const qs = utils.getQs({ is_market: false, Filter_Enum: 'Vehicle' });
    url = `${baseUrl}/BioFuelCommonUtilities/api/Filter/GetFilterData?${qs}`;
  }

  return async (dispatch: Dispatch) => {
    try {
      dispatch(slice.actions.setBusy(true));
      const response1 = await utils.fetch(url).ready;
      const resData = await utils.processApiRes(response1);
      const response = {
        data: resData,
      };
      const options: any = [];

      for (const raw of response.data) {
        let option = {};

        switch (entity) {
          default:
            option = {
              value: raw.id,
              text: raw.code,
            };
        }
        options.push(option);
      }

      dispatch(slice.actions.update({ entity: 'vehicle_id', options }));
    } catch (errors) {
      console.log('error in filter', errors);
    }

    dispatch(slice.actions.setBusy(false));
  };
};
const getDriverData = (entity: string) => {
  let url;
  if (
    window.location.href.includes('market') ||
    window.location.href.includes('commodity/deals') ||
    window.location.href.includes('commodity/offers') ||
    window.location.href.includes('commodity/requirements')
  ) {
    const qs = utils.getQs({ is_market: true, Filter_Enum: 'Driver' });
    url = `${baseUrl}/BioFuelCommonUtilities/api/Filter/GetFilterData?${qs}`;
  } else {
    const qs = utils.getQs({ is_market: false, Filter_Enum: 'Driver' });
    url = `${baseUrl}/BioFuelCommonUtilities/api/Filter/GetFilterData?${qs}`;
  }

  return async (dispatch: Dispatch) => {
    try {
      dispatch(slice.actions.setBusy(true));
      const response1 = await utils.fetch(url).ready;
      const resData = await utils.processApiRes(response1);
      const response = {
        data: resData,
      };
      const options: any = [];

      for (const raw of response.data) {
        let option = {};

        switch (entity) {
          default:
            option = {
              value: raw.id,
              text: raw.code,
            };
        }
        options.push(option);
      }

      dispatch(slice.actions.update({ entity: 'driver_id', options }));
    } catch (errors) {
      console.log('error in filter', errors);
    }

    dispatch(slice.actions.setBusy(false));
  };
};

const getPaidReceivedData = (entity: string) => {
  let url;
  if (
    window.location.href.includes('market') ||
    window.location.href.includes('commodity/deals') ||
    window.location.href.includes('commodity/offers') ||
    window.location.href.includes('commodity/requirements')
  ) {
    const qs = utils.getQs({ is_market: true, Filter_Enum: 'paidReceived' });
    url = `${baseUrl}/BioFuelCommonUtilities/api/Filter/GetFilterData?${qs}`;
  } else {
    const qs = utils.getQs({ is_market: false, Filter_Enum: 'paidReceived' });
    url = `${baseUrl}/BioFuelCommonUtilities/api/Filter/GetFilterData?${qs}`;
  }

  return async (dispatch: Dispatch) => {
    try {
      dispatch(slice.actions.setBusy(true));
      const response1 = await utils.fetch(url).ready;
      const resData = await utils.processApiRes(response1);
      const response = {
        data: resData,
      };
      const options: any = [];

      for (const raw of response.data) {
        let option = {};

        switch (entity) {
          default:
            option = {
              value: raw.id,
              text: raw.code,
            };
        }
        options.push(option);
      }

      dispatch(slice.actions.update({ entity: 'paid_received', options }));
    } catch (errors) {
      console.log('error in filter', errors);
    }

    dispatch(slice.actions.setBusy(false));
  };
};

const getCounterpartyData = (entity: string) => {
  let url;
  if (
    window.location.href.includes('market') ||
    window.location.href.includes('commodity/deals') ||
    window.location.href.includes('commodity/offers') ||
    window.location.href.includes('commodity/requirements')
  ) {
    const qs = utils.getQs({ is_market: true, Filter_Enum: 'counterparty' });
    url = `${baseUrl}/BioFuelCommonUtilities/api/Filter/GetFilterData?${qs}`;
  } else {
    const qs = utils.getQs({ is_market: false, Filter_Enum: 'counterparty' });
    url = `${baseUrl}/BioFuelCommonUtilities/api/Filter/GetFilterData?${qs}`;
  }

  return async (dispatch: Dispatch) => {
    try {
      dispatch(slice.actions.setBusy(true));
      const response1 = await utils.fetch(url).ready;
      const resData = await utils.processApiRes(response1);
      const response = {
        data: resData,
      };
      const options: any = [];

      for (const raw of response.data) {
        let option = {};

        switch (entity) {
          default:
            option = {
              value: raw.id,
              text: raw.code,
            };
        }
        options.push(option);
      }

      dispatch(slice.actions.update({ entity: 'counterparty', options }));
    } catch (errors) {
      console.log('error in filter', errors);
    }

    dispatch(slice.actions.setBusy(false));
  };
};

const getPickUpLocationData = (entity: string) => {
  let url;
  if (
    window.location.href.includes('market') ||
    window.location.href.includes('commodity/deals') ||
    window.location.href.includes('commodity/offers') ||
    window.location.href.includes('commodity/requirements')
  ) {
    const qs = utils.getQs({ is_market: true, Filter_Enum: 'PickupLocation' });
    url = `${baseUrl}/BioFuelCommonUtilities/api/Filter/GetFilterData_ELOC?${qs}`;
  } else {
    const qs = utils.getQs({ is_market: false, Filter_Enum: 'PickupLocation' });
    url = `${baseUrl}/BioFuelCommonUtilities/api/Filter/GetFilterData_ELOC?${qs}`;
  }

  return async (dispatch: Dispatch) => {
    try {
      dispatch(slice.actions.setBusy(true));
      const response1 = await utils.fetch(url).ready;
      const resData = await utils.processApiRes(response1);
      const response = {
        data: resData,
      };
      const options: any = [];

      for (const raw of response.data) {
        let option = {};

        switch (entity) {
          default:
            option = {
              value: raw.id,
              text: raw.code,
            };
        }
        options.push(option);
      }

      dispatch(slice.actions.update({ entity: 'base_location_id', options }));
    } catch (errors) {
      console.log('error in filter', errors);
    }

    dispatch(slice.actions.setBusy(false));
  };
};

const getDeliveryLocationData = (entity: string) => {
  let url;
  if (
    window.location.href.includes('market') ||
    window.location.href.includes('commodity/deals') ||
    window.location.href.includes('commodity/offers') ||
    window.location.href.includes('commodity/requirements')
  ) {
    const qs = utils.getQs({ is_market: true, Filter_Enum: 'DeliveryLocation' });
    url = `${baseUrl}/BioFuelCommonUtilities/api/Filter/GetFilterData_ELOC?${qs}`;
  } else {
    const qs = utils.getQs({ is_market: false, Filter_Enum: 'DeliveryLocation' });
    url = `${baseUrl}/BioFuelCommonUtilities/api/Filter/GetFilterData_ELOC?${qs}`;
  }

  return async (dispatch: Dispatch) => {
    try {
      dispatch(slice.actions.setBusy(true));
      const response1 = await utils.fetch(url).ready;
      const resData = await utils.processApiRes(response1);
      const response = {
        data: resData,
      };
      const options: any = [];

      for (const raw of response.data) {
        let option = {};

        switch (entity) {
          default:
            option = {
              value: raw.id,
              text: raw.code,
            };
        }
        options.push(option);
      }

      dispatch(slice.actions.update({ entity: 'delivery_location_id', options }));
    } catch (errors) {
      console.log('error in filter', errors);
    }

    dispatch(slice.actions.setBusy(false));
  };
};

const getTypeOfInvoiceData = (entity: string) => {
  let url;
  if (
    window.location.href.includes('market') ||
    window.location.href.includes('commodity/deals') ||
    window.location.href.includes('commodity/offers') ||
    window.location.href.includes('commodity/requirements')
  ) {
    const qs = utils.getQs({ is_market: true, Filter_Enum: 'typeOfInvoice' });
    url = `${baseUrl}/BioFuelCommonUtilities/api/Filter/GetFilterData?${qs}`;
  } else {
    const qs = utils.getQs({ is_market: false, Filter_Enum: 'typeOfInvoice' });
    url = `${baseUrl}/BioFuelCommonUtilities/api/Filter/GetFilterData?${qs}`;
  }

  return async (dispatch: Dispatch) => {
    try {
      dispatch(slice.actions.setBusy(true));
      const response1 = await utils.fetch(url).ready;
      const resData = await utils.processApiRes(response1);
      const response = {
        data: resData,
      };
      const options: any = [];

      for (const raw of response.data) {
        let option = {};

        switch (entity) {
          default:
            option = {
              value: raw.id,
              label: raw.code,
            };
        }
        options.push(option);
      }

      dispatch(slice.actions.update({ entity: 'type_of_invoice', options }));
    } catch (errors) {
      console.log('error in filter', errors);
    }

    dispatch(slice.actions.setBusy(false));
  };
};

const getDeliveryAllotmentStatusEnumData = (entity: string) => {
  const qs = utils.getQs({ Enum_Group: 'TransportDeliveryPhaseFilterEnum' });
  let url = `/BioFuelRegistration/api/ReferenceData/GetEnumerator?${qs}`;

  return async (dispatch: Dispatch) => {
    try {
      dispatch(slice.actions.setBusy(true));
      const response = await fetch.fetchOne<any[]>(url);

      const options: any = [];

      for (const raw of response.data) {
        let option = {};

        switch (entity) {
          default:
            option = {
              value: raw.value_member,
              text: raw.display_member,
            };
        }
        options.push(option);
      }

      dispatch(slice.actions.update({ entity: 'delivery_allotment_status_enum', options }));
    } catch (errors) {
      ///dd
    }

    dispatch(slice.actions.setBusy(false));
  };
};
const getstockInOutType = (entity: string) => {
  const qs = utils.getQs({ Enum_Group: 'StockInOutTypeEnum' });
  let url = `/BioFuelRegistration/api/ReferenceData/GetEnumerator?${qs}`;

  return async (dispatch: Dispatch) => {
    try {
      dispatch(slice.actions.setBusy(true));
      const response = await fetch.fetchOne<any[]>(url);

      const options: any = [];

      for (const raw of response.data) {
        let option = {};

        switch (entity) {
          default:
            option = {
              value: raw.value_member,
              text: raw.display_member,
            };
        }
        options.push(option);
      }

      dispatch(slice.actions.update({ entity: 'stock_in_out_type_enum', options }));
    } catch (errors) {
      ///dd
    }

    dispatch(slice.actions.setBusy(false));
  };
};
const getTransportProvider = (entity: string) => {
  let url;
  if (
    window.location.href.includes('market') ||
    window.location.href.includes('commodity/deals') ||
    window.location.href.includes('commodity/offers') ||
    window.location.href.includes('commodity/requirements')
  ) {
    const qs = utils.getQs({ is_market: true, Filter_Enum: 'TransportProvider' });
    url = `${baseUrl}/BioFuelCommonUtilities/api/Filter/GetFilterData?${qs}`;
  } else {
    const qs = utils.getQs({ is_market: false, Filter_Enum: 'TransportProvider' });
    url = `${baseUrl}/BioFuelCommonUtilities/api/Filter/GetFilterData?${qs}`;
  }

  return async (dispatch: Dispatch) => {
    try {
      dispatch(slice.actions.setBusy(true));
      const response1 = await utils.fetch(url).ready;
      const resData = await utils.processApiRes(response1);
      const response = {
        data: resData,
      };
      const options: any = [];

      for (const raw of response.data) {
        let option = {};

        switch (entity) {
          default:
            option = {
              value: raw.id,
              text: raw.code,
            };
        }
        options.push(option);
      }

      dispatch(slice.actions.update({ entity: 'transport_company_id', options }));
    } catch (errors) {
      console.log('error in filter', errors);
    }

    dispatch(slice.actions.setBusy(false));
  };
};
const getFetchListCT = (entity: string) => {
  const url = `${baseUrl}/BioFuelDeliveries/api/DeliveryAssignment/FetchDeliveries_CT_ELOC_Lite`;

  return async (dispatch: Dispatch) => {
    try {
      dispatch(slice.actions.setBusy(true));
      const response1 = await utils.fetch(url).ready;
      const resData = await utils.processApiRes(response1);
      const response = {
        data: resData,
      };
      const options: any = [];

      for (const raw of response.data) {
        let option = {};

        switch (entity) {
          default:
            option = {
              value: raw.id,
              label: raw.code,
            };
        }
        options.push(option);
      }

      dispatch(slice.actions.update({ entity: 'transport_company_id', options }));
    } catch (errors) {
      console.log('error in filter', errors);
    }

    dispatch(slice.actions.setBusy(false));
  };
};
const getLanguageList = (entity: string) => {
  const qs = utils.getQs({ Enum_Group: 'LanguageTypeEnum' });
  let url = `/BioFuelRegistration/api/ReferenceData/GetEnumerator?${qs}`;

  return async (dispatch: Dispatch) => {
    try {
      dispatch(slice.actions.setBusy(true));
      const response = await fetch.fetchOne<any[]>(url);

      const options: any = [];

      for (const raw of response.data) {
        let option = {};

        switch (entity) {
          default:
            option = {
              value: raw.value_member,
              label: raw.display_member,
            };
        }
        options.push(option);
      }

      dispatch(slice.actions.update({ entity: 'languageList', options }));
    } catch (errors) {
      ///dd
    }

    dispatch(slice.actions.setBusy(false));
  };
};

const getFilterDataEloc = (entity: string) => {
  let url = `${baseUrl}/BioFuelCommonUtilities/api/Filter/GetFilterData_ELOC`;

  return async (dispatch: Dispatch) => {
    try {
      dispatch(slice.actions.setBusy(true));
      const response1 = await utils.fetch(url).ready;
      const resData = await utils.processApiRes(response1);
      const response = {
        data: resData,
      };
      const options: any = [];

      for (const raw of response.data) {
        let option = {};

        switch (entity) {
          default:
            option = {
              value: raw.id,
              label: raw.code,
            };
        }
        options.push(option);
      }

      dispatch(slice.actions.update({ entity: 'locationEloc', options }));
    } catch (errors) {
      console.log('error in filter', errors);
    }

    dispatch(slice.actions.setBusy(false));
  };
};

const getPackagingTypeAgainstTypeFormCode = (commodity_type_code: string, commodity_form_code: string) => {
  let url = `/BioFuelRegistration/api/ReferenceData/FetchPackagingTypeAgainstTypeFormCode?commodity_type_code=${commodity_type_code}&commodity_form_code=${commodity_form_code}`;

  return async (dispatch: Dispatch) => {
    try {
      dispatch(slice.actions.setBusy(true));
      const response = await fetch.fetchOne<any[]>(url);

      const options: any = [];

      for (const raw of response.data) {
        let option = {
          value: raw.value_member,
          text: raw.display_member,
        };
        options.push(option);
      }

      dispatch(slice.actions.update({ entity: 'packaging_code', options }));
    } catch (errors) {}

    dispatch(slice.actions.setBusy(false));
  };
};

const getAuctionResponsePhaseEnumData = (entity: string) => {
  const qs = utils.getQs({ Enum_Group: 'AuctionResponsePhaseEnum' });
  let url = `/BioFuelRegistration/api/ReferenceData/GetEnumerator?${qs}`;

  return async (dispatch: Dispatch) => {
    try {
      dispatch(slice.actions.setBusy(true));
      const response = await fetch.fetchOne<any[]>(url);

      const options: any = [];

      for (const raw of response.data) {
        let option = {};

        switch (entity) {
          default:
            option = {
              value: raw.value_member,
              text: raw.display_member,
            };
        }
        options.push(option);
      }

      dispatch(slice.actions.update({ entity: 'auction_response_phase_enum', options }));
    } catch (errors) {
      ///dd
    }

    dispatch(slice.actions.setBusy(false));
  };
};

const getRegisteredUserFilterData = (entity: string) => {
  let url;
  if (entity.includes('Enum')) {
    const params = {
      Enum_Group: entity,
    };
    const qs = utils.getQs(params);
    url = `/BioFuelRegistration/api/ReferenceData/GetEnumerator?${qs}`;
  } else {
    const params = {
      Reference_Data_Enum: entity,
      Reference_Data_Operation_Enum: 'RefDataForDropDown',
    };
    const qs = utils.getQs(params);
    url = `/BioFuelRegistration/api/ReferenceData/ReadReferenceData?${qs}`;
  }

  return async (dispatch: Dispatch) => {
    try {
      dispatch(slice.actions.setBusy(true));
      const response = await fetch.fetchOne<any[]>(url);
      // let response = await utils.fetch(url).ready;
      // response = await utils.processApiRes(response)

      const options: any = [];

      for (const raw of response.data) {
        let option = {};

        switch (entity) {
          default:
            option = {
              value: raw.value_member,
              text: raw.display_member,
              code: raw.maximum_cap_uom_code,
            };
        }
        options.push(option);
      }

      dispatch(slice.actions.update({ entity: entity, options }));
    } catch (errors) {
      ///dd
    }

    dispatch(slice.actions.setBusy(false));
  };
};

const getFilterDataByFilterEnum = (
  entity: string,
  isLocation: boolean = false,
  isBusinessAndAffLocation: boolean = false,
  person_id: number = 0,
  isBusinessAndAff: boolean = false,
  isFarmer: boolean = false,
) => {
  let url;
  if (isLocation) {
    if (
      window.location.href.includes('market') ||
      window.location.href.includes('commodity/deals') ||
      window.location.href.includes('commodity/offers') ||
      window.location.href.includes('commodity/requirements')
    ) {
      const qs = utils.getQs({ is_market: true, Filter_Enum: entity });
      url = `${baseUrl}/BioFuelCommonUtilities/api/Filter/GetFilterData_ELOC?${qs}`;
    } else {
      const qs = utils.getQs({ is_market: false, Filter_Enum: entity });
      url = `${baseUrl}/BioFuelCommonUtilities/api/Filter/GetFilterData_ELOC?${qs}`;
    }
  } else if (isBusinessAndAffLocation) {
    const qs = utils.getQs({ person_id });
    url = `${baseUrl}/BioFuelRegistration/api/BusinessProfile/FetchBusinessAndAffiliateLocationsAgainstPersonId_ELOC_PhaseTwo?${qs}`;
  } else if (isFarmer) {
    const qs = utils.getQs({ person_id });
    url = `${baseUrl}/BioFuelRegistration/api/FarmDetails/FetchAllFarmsAgainstPersonId_ELOC?${qs}`;
  } else {
    const qs = utils.getQs({ Filter_Enum: entity });
    url = `${baseUrl}/BioFuelCommonUtilities/api/Filter/GetFilterData?${qs}`;
  }

  return async (dispatch: Dispatch) => {
    try {
      dispatch(slice.actions.setBusy(true));
      const response1 = await utils.fetch(url).ready;
      const resData = await utils.processApiRes(response1);
      const response = {
        data: resData,
      };
      let options: any = [];

      for (const raw of response.data) {
        let option = {};
        if (isBusinessAndAffLocation && !isBusinessAndAff) {
          option = {
            ...raw,
            value: raw.address_text,
            text: raw.address_text,
          };
        } else if (isBusinessAndAffLocation && isBusinessAndAff) {
          option = {
            ...raw,
            value: raw.company_code,
            text: raw.company_code,
          };
        } else if (isFarmer) {
          option = {
            ...raw?.farm_address,
            value: raw?.farm_address.address_text,
            text: raw?.farm_address.address_text,
          };
        } else {
          switch (entity) {
            default:
              option = {
                ...raw,
                value: raw.id,
                text: raw.code,
              };
          }
        }
        options.push(option);
      }

      dispatch(slice.actions.update({ entity: entity, options }));
    } catch (errors) {
      console.log('error in filter', errors);
    }

    dispatch(slice.actions.setBusy(false));
  };
};

const getFilterData = (entity: string) => {
  const qs = utils.getQs({ Filter_Enum: entity });
  let url = `${baseUrl}/BioFuelCommonUtilities/api/Filter/GetFilterData?${qs}`;

  return async (dispatch: Dispatch) => {
    try {
      dispatch(slice.actions.setBusy(true));
      const response1 = await utils.fetch(url).ready;
      const resData = await utils.processApiRes(response1);
      const response = {
        data: resData,
      };
      const options: any = [];

      for (const raw of response.data) {
        let option = {};

        switch (entity) {
          default:
            option = {
              value: raw.id,
              text: raw.code,
            };
        }
        options.push(option);
      }

      dispatch(slice.actions.update({ entity: entity, options }));
    } catch (errors) {
      console.log('error in filter', errors);
    }

    dispatch(slice.actions.setBusy(false));
  };
};

const getPaymentTermsForEquipment = (entity: string) => {
  let url = `${baseUrl}/BioFuelRequirementOffers/api/EquipmentDetails/GetPaymentTermsForEquipment`;
  return async (dispatch: Dispatch) => {
    try {
      dispatch(slice.actions.setBusy(true));
      const response1 = await utils.fetch(url).ready;
      const resData = await utils.processApiRes(response1);
      const response = {
        data: resData,
      };

      const options: any = [];

      for (const raw of response.data) {
        let option = {};

        switch (entity) {
          default:
            option = {
              value: raw.payment_term_id,
              text: raw.payment_term_code,
            };
        }
        options.push(option);
      }

      dispatch(slice.actions.update({ entity: entity, options }));
    } catch (errors) {
      console.log('error in filter', errors);
    }

    dispatch(slice.actions.setBusy(false));
  };
};

const getFilterDataELOC = (entity: string) => {
  const qs = utils.getQs({ Filter_Enum: entity });
  let url = `${baseUrl}/BioFuelCommonUtilities/api/Filter/GetFilterData_ELOC?${qs}`;

  return async (dispatch: Dispatch) => {
    try {
      dispatch(slice.actions.setBusy(true));
      const response1 = await utils.fetch(url).ready;
      const resData = await utils.processApiRes(response1);
      const response = {
        data: resData,
      };
      const options: any = [];

      for (const raw of response.data) {
        let option = {};

        switch (entity) {
          default:
            option = {
              value: raw.id,
              text: raw.code,
            };
        }
        options.push(option);
      }

      dispatch(slice.actions.update({ entity: entity, options }));
    } catch (errors) {
      console.log('error in filter', errors);
    }

    dispatch(slice.actions.setBusy(false));
  };
};

const getSignatureTypeEnumData = (entity: string) => {
  const qs = utils.getQs({ Enum_Group: 'signatureTypeEnum' });
  let url = `/BioFuelRegistration/api/ReferenceData/GetEnumerator?${qs}`;

  return async (dispatch: Dispatch) => {
    try {
      dispatch(slice.actions.setBusy(true));
      const response = await fetch.fetchOne<any[]>(url);

      const options: any = [];

      for (const raw of response.data) {
        let option = {};

        switch (entity) {
          default:
            option = {
              value: raw.value_member,
              text: raw.display_member,
            };
        }
        options.push(option);
      }

      dispatch(slice.actions.update({ entity: 'signature_type_code', options }));
    } catch (errors) {
      ///dd
    }

    dispatch(slice.actions.setBusy(false));
  };
};

const getPgPaymentStatusEnum = (entity: string) => {
  const qs = utils.getQs({ Enum_Group: 'pgpaymentenum' });
  let url = `/BioFuelRegistration/api/ReferenceData/GetEnumerator?${qs}`;

  return async (dispatch: Dispatch) => {
    try {
      dispatch(slice.actions.setBusy(true));
      const response = await fetch.fetchOne<any[]>(url);
      const options: any = [];

      for (const raw of response.data) {
        let option = {};

        switch (entity) {
          default:
            option = {
              value: raw.value_member,
              text: raw.display_member,
            };
        }
        options.push(option);
      }

      dispatch(slice.actions.update({ entity: 'pg_payment_status_enum', options }));
    } catch (errors) {
      ///dd
    }

    dispatch(slice.actions.setBusy(false));
  };
};

const getFinanceEnumData = (entity: string) => {
  const qs = utils.getQs({ Enum_Group: 'FinanceEnum' });
  let url = `/BioFuelRegistration/api/ReferenceData/GetEnumerator?${qs}`;

  return async (dispatch: Dispatch) => {
    try {
      dispatch(slice.actions.setBusy(true));
      const response = await fetch.fetchOne<any[]>(url);
      const options: any = [];

      for (const raw of response.data) {
        let option = {};

        switch (entity) {
          default:
            option = {
              value: raw.value_member,
              text: raw.display_member,
            };
        }
        options.push(option);
      }

      dispatch(slice.actions.update({ entity: 'finance_enum', options }));
    } catch (errors) {
      ///dd
    }

    dispatch(slice.actions.setBusy(false));
  };
};

const getBusinessTypeEnum = (entity: string) => {
  const qs = utils.getQs({ Enum_Group: 'BusinessTypeEnum' });
  let url = `/BioFuelRegistration/api/ReferenceData/GetEnumerator?${qs}`;

  return async (dispatch: Dispatch) => {
    try {
      dispatch(slice.actions.setBusy(true));
      const response = await fetch.fetchOne<any[]>(url);
      const options: any = [];

      for (const raw of response.data) {
        let option = {};

        switch (entity) {
          default:
            option = {
              value: raw.value_member,
              text: raw.display_member,
            };
        }
        options.push(option);
      }

      dispatch(slice.actions.update({ entity: 'business_type_enum', options }));
    } catch (errors) {
      ///dd
    }

    dispatch(slice.actions.setBusy(false));
  };
};
const inspectionVerificatioEnumData = (entity: string) => {
  const qs = utils.getQs({ Enum_Group: 'InspectionEnum' });
  let url = `/BioFuelRegistration/api/ReferenceData/GetEnumerator?${qs}`;

  return async (dispatch: Dispatch) => {
    try {
      dispatch(slice.actions.setBusy(true));
      const response = await fetch.fetchOne<any[]>(url);
      const options: any = [];

      for (const raw of response.data) {
        let option = {};
        switch (entity) {
          default:
            option = {
              value: raw.value_member,
              text: raw.display_member,
            };
        }
        options.push(option);
      }

      dispatch(slice.actions.update({ entity: 'inspection_enum', options }));
    } catch (errors) {
      ///dd
    }

    dispatch(slice.actions.setBusy(false));
  };
};

const extendedProfileStatusEnumData = (entity: string) => {
  const qs = utils.getQs({ Enum_Group: 'ExtendedProfileEnum' });
  let url = `/BioFuelRegistration/api/ReferenceData/GetEnumerator?${qs}`;

  return async (dispatch: Dispatch) => {
    try {
      dispatch(slice.actions.setBusy(true));
      const response = await fetch.fetchOne<any[]>(url);
      const options: any = [];
      const transformedResponseData = (response?.data || []).sort((a, b) => a.sequence - b.sequence);

      for (const raw of transformedResponseData) {
        let option = {};
        switch (entity) {
          default:
            option = {
              value: raw.value_member,
              text: raw.display_member,
            };
        }
        options.push(option);
      }

      dispatch(slice.actions.update({ entity: 'extended_profile_enum', options }));
    } catch (errors) {
      ///dd
    }

    dispatch(slice.actions.setBusy(false));
  };
};

const extendedProfileVerificationStatusEnumData = (entity: string) => {
  const qs = utils.getQs({ Enum_Group: 'TickColorEnum' });
  let url = `/BioFuelRegistration/api/ReferenceData/GetEnumerator?${qs}`;

  return async (dispatch: Dispatch) => {
    try {
      dispatch(slice.actions.setBusy(true));
      const response = await fetch.fetchOne<any[]>(url);
      const options: any = [];

      for (const raw of response.data) {
        let option = {};
        switch (entity) {
          default:
            option = {
              value: raw.value_member,
              text: raw.display_member,
            };
        }
        options.push(option);
      }

      dispatch(slice.actions.update({ entity: 'tick_color_enum_location', options }));
    } catch (errors) {
      ///dd
    }

    dispatch(slice.actions.setBusy(false));
  };
};

const getCashflowStatusEnumData = (entity: string) => {
  const qs = utils.getQs({ Enum_Group: 'cashflowStatusEnum' });
  let url = `/BioFuelRegistration/api/ReferenceData/GetEnumerator?${qs}`;

  return async (dispatch: Dispatch) => {
    try {
      dispatch(slice.actions.setBusy(true));
      const response = await fetch.fetchOne<any[]>(url);

      const options: any = [];

      for (const raw of response.data) {
        let option = {};

        switch (entity) {
          default:
            option = {
              value: raw.value_member,
              text: raw.display_member,
            };
        }
        options.push(option);
      }

      dispatch(slice.actions.update({ entity: 'cashflow_status_enum', options }));
    } catch (errors) {
      ///dd
    }

    dispatch(slice.actions.setBusy(false));
  };
};

const getManualInvoiceStatusEnumData = (entity: string) => {
  const qs = utils.getQs({ Enum_Group: 'PendingApprovedEnum' });
  let url = `/BioFuelRegistration/api/ReferenceData/GetEnumerator?${qs}`;

  return async (dispatch: Dispatch) => {
    try {
      dispatch(slice.actions.setBusy(true));
      const response = await fetch.fetchOne<any[]>(url);

      const options: any = [];

      for (const raw of response.data) {
        let option = {};

        switch (entity) {
          default:
            option = {
              value: raw.value_member,
              text: raw.display_member,
            };
        }
        options.push(option);
      }

      dispatch(slice.actions.update({ entity: 'pending_approved_enum', options }));
    } catch (errors) {
      ///dd
    }

    dispatch(slice.actions.setBusy(false));
  };
};
export const actions = {
  getReferenceData,
  getEnumData,
  getPaymentTermsForEquipment,
  getPlatformChargeAtEnumData,
  getOfferBidEnumData,
  getDealEnumData,
  getKYCStatusEnumData,
  getInvoiceStatusEnumData,
  getPaymentStatusEnumData,
  getTypeOfInvoiceEnumData,
  getVehicleTypeData,
  getHireTypeData,
  getTaxApplicabilityData,
  getConsignorData,
  getConsigneeData,
  getVehicleData,
  getDriverData,
  getPaidReceivedData,
  getCounterpartyData,
  getPickUpLocationData,
  getDeliveryLocationData,
  getDeliveryAllotmentStatusEnumData,
  getTypeOfInvoiceData,
  getstockInOutType,
  getTransportProvider,
  getFetchListCT,
  getPgPaymentEnum,
  getLanguageList,
  getFilterDataEloc,
  getPackagingTypeAgainstTypeFormCode,
  getAuctionResponsePhaseEnumData,
  getRegisteredUserFilterData,
  getSignatureTypeEnumData,
  getPgPaymentStatusEnum,
  getFinanceEnumData,
  getBusinessTypeEnum,
  getFilterDataByFilterEnum,
  getFilterData,
  inspectionVerificatioEnumData,
  extendedProfileStatusEnumData,
  extendedProfileVerificationStatusEnumData,
  getCashflowStatusEnumData,
  getManualInvoiceStatusEnumData,
  getFilterDataELOC,
  getInvoiceVerificationStatusEnumData,
};

export default slice.reducer;
