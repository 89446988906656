import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { getIn } from 'formik';
import { TextField, InputAdornment, MenuItem, ButtonBase } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { colord as colorFormat } from 'colord';

import getColor from '../colors';

type styleType = {
  color: string;
  size: 'small' | 'medium';
};

const useStyles = makeStyles(() => ({
  root: {
    '& label.Mui-focused': {
      color: (props: styleType) => getColor(`${props?.color}`),
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: (props: styleType) => getColor(`${props?.color}`),
    },
    '& .MuiInputLabel-outlined': {
      transform: (props: styleType) => props.size === 'medium' && 'translate(14px, 18px) scale(1) !important',
    },
    '& .MuiOutlinedInput-root': {
      '& .MuiOutlinedInput-input': {
        padding: (props: styleType) => (props.size === 'medium' ? '16px 14px' : '10.5px 12px'),
      },
      '& textarea.MuiOutlinedInput-input': {
        padding: '0px !important',
      },
      '& fieldset': {
        borderColor: (props: styleType) => getColor(`${props?.color}`),
      },
      '&:hover fieldset': {
        borderColor: (props: styleType) => getColor(`${props?.color}`),
      },
      '&.Mui-error:hover fieldset': {
        borderColor: '#f44336',
      },
      '&.Mui-focused fieldset': {
        borderColor: (props: styleType) => getColor(`${props?.color}`),
      },
      '&.Mui-focused.Mui-error fieldset': {
        borderColor: '#f44336',
      },
      '&.Mui-disabled fieldset': {
        borderColor: (props: styleType) => getColor(`rgba(0, 0, 0, 0.26)`),
      },
      '& input,textarea': {
        letterSpacing: '1px',
      },
      '& textarea': {
        paddingRight: '4px',
        '&::-webkit-scrollbar': {
          width: '6px',
        },
        '&::-webkit-scrollbar-track': {
          boxShadow: `inset 0 0 5px ${(props: styleType) => getColor(`${props?.color}`)}`,
          borderRadius: '10px',
          backgroundColor: (props: styleType) =>
            `${colorFormat(getColor(`${props?.color}`))
              .alpha(0.13)
              .toHex()} !important`,
        },
        '&::-webkit-scrollbar-thumb': {
          background: (props: styleType) => getColor(`${props?.color}`),
          borderRadius: '10px',
        },
        '&::-webkit-scrollbar-thumb:hover': {
          background: (props: styleType) => colorFormat(getColor(`${props?.color}`)).darken(50),
        },
      },
    },
    '& .MuiInputLabel-root': {
      letterSpacing: '1px',
      fontFamily: 'Segoe UI',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(15px, -6px) scale(0.70) !important',
      color: (props: styleType) => getColor(`${props?.color}`),
    },
    '& .MuiInputLabel-shrink': {
      color: (props: styleType) => getColor(`${props?.color}`),
    },
    '& .MuiInput-underline:before, & .MuiInput-underline:hover:not(.Mui-disabled):before, & .MuiFilledInput-underline:before':
      {
        borderColor: (props: styleType) => getColor(`${props?.color}`),
      },
    '& .MuiFilledInput-root': {
      background: (props: styleType) =>
        `${colorFormat(getColor(`${props?.color}`))
          .alpha(0.09)
          .toHex()} !important`,
      '&:hover': {
        background: (props: styleType) =>
          `${colorFormat(getColor(`${props?.color}`))
            .alpha(0.13)
            .toHex()} !important`,
      },
    },
    '& .MuiOutlinedInput-root:has(.MuiInputAdornment-positionStart) legend': {
      marginLeft: '42px !important',
      transitionDuration: '0ms !important',
      transitionDelay: '0ms !important',
    },
    '& .MuiOutlinedInput-root:not(.Mui-focused):has(.MuiInputAdornment-positionStart + .MuiInputBase-input[value=""]) legend':
      {
        maxWidth: '0px',
      },
    '& .MuiInputLabel-root:has(+ .MuiOutlinedInput-root .MuiInputAdornment-positionStart)': {
      transform: 'translate(55px, 12px) scale(1) !important',
      color: 'rgba(0, 0, 0, 0.54)',
    },
    '& .MuiInputLabel-root:has(+ .MuiOutlinedInput-root .MuiInputAdornment-positionStart + .MuiInputBase-input:not([value=""]))':
      {
        transform: 'translate(56px, -6px) scale(0.70) !important',
        color: (props: styleType) => getColor(`${props?.color}`),
      },
    '& .MuiInputLabel-root.Mui-focused:has(+ .MuiOutlinedInput-root .MuiInputAdornment-positionStart + .MuiInputBase-input[value=""])':
      {
        transform: 'translate(56px, -6px) scale(0.70) !important',
        color: (props: styleType) => getColor(`${props?.color}`),
      },
    '& .MuiInputAdornment-positionStart': {
      marginRight: 0,
    },
    '& .MuiFormHelperText-root.Mui-error': {
      margin: '4px 0 0 0',
    },
    '& .MuiInputAdornment-root': {
      height: 'auto',
      maxHeight: 'max-content',
    },
    '& .Mui-disabled': {
      color: (props: styleType) => `${getColor(`rgba(0, 0, 0, 0.38)`)} !important`,
    },
    '& .Mui-error': {
      color: (props: styleType) => `${getColor(`#f44336`)} !important`,
    },
  },
}));

type TextFieldPropTypes = {
  size?: 'small' | 'medium';
  variant?: 'outlined' | 'standard' | 'filled';
  color?: 'old' | 'primary' | 'muiPrimary' | 'secondary' | 'ternary' | string;
  value?: string | number;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  field?: any;
  form?: any;
  required?: boolean;
  isError?: boolean;
  errorMsg?: string;
  inputRef?: any;
};

const CustomDateInput = React.memo(
  ({
    size = 'small',
    variant = 'outlined',
    color = 'muiPrimary',
    value = '',
    label,
    placeholder,
    fullWidth = true,
    disabled = false,
    field,
    form,
    required = false,
    isError = false,
    errorMsg = '',
    inputRef,
    ...rest
  }: TextFieldPropTypes) => {
    const config = useSelector((state) => state?.['user']?.profile?.ui_config);
    const classes = useStyles({ color: config.theme_color || color, size });

    const bgColor = getColor(config.theme_color || color);
    const isLight = colorFormat(bgColor).isLight();
    const textColor = isLight ? colorFormat(bgColor).darken(1).toHex() : colorFormat(bgColor).lighten(1).toHex();

    const hasError = !!(getIn(form?.touched, field?.name) && getIn(form?.errors, field?.name)) || isError;
    const errText = hasError ? getIn(form?.errors, field?.name) || errorMsg : '';

    const inputStartAdornmentStyles = useMemo(
      () => ({
        background: disabled ? 'rgba(0,0,0,0.26)' : hasError ? '#f44336' : bgColor,
        height: '40px',
        padding: '10px',
        borderTopLeftRadius: '4px',
        borderBottomLeftRadius: '4px',
        fontSize: '26px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }),
      [disabled, hasError, bgColor],
    );

    const dateIconStyles = useMemo(
      () => ({
        fontSize: 'var(--fs-base__twenty-four)',
        color: textColor,
        height: 24,
        width: 24,
        fill: textColor,
      }),
      [textColor],
    );

    return (
      <TextField
        {...field}
        {...rest}
        className={classes.root}
        label={label}
        placeholder={placeholder}
        size={size}
        variant={variant}
        disabled={disabled}
        fullWidth={fullWidth}
        required={required}
        error={hasError}
        helperText={errText}
        value={value}
        autoComplete="off"
        InputProps={{
          startAdornment: (
            <InputAdornment style={inputStartAdornmentStyles} position="start">
              <DateRangeIcon style={dateIconStyles} />
            </InputAdornment>
          ),
        }}
      />
    );
  },
);
export default CustomDateInput;
