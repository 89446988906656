import React from 'react';
import Logo from './../img/logo/BiofuelCircle_Logo.png';

import AndroidLogo from '../../assets/images/icons/google-play-badge.png';
import IosLogo from '../../assets/images/icons/ios-icon.png';
import config from 'config';
import { isMobile } from 'react-device-detect';
import { getDecryptedUrl } from 'lib/utils';


const WelcomeCard = ({ isFarmerUser }) => {
  const loc = getDecryptedUrl(window.location.href);

  const { indentDealsUrl } = config;

  return (
    (!isMobile && isFarmerUser) || !isFarmerUser  ? <div className="container-fluid bfc-welcome-card">
      <div className="bfc-welcome-card-header">
        <h3 className="bfc-title">
          WELCOME <br /> TO
        </h3>
      </div>
      <div className="bfc-welcome-card-body">
        <img className="img-fluid bfc-logo" src={Logo} alt="BiofuelCircle" style={{ width: '90%' }} />
      </div>
      {loc.includes(indentDealsUrl) && (
        <h4 className="mb-0 mt-10 text-center" style={{ color: 'var(--unnamed-color-90d632)' }}>Supply Portal</h4>
      )}
      <div className="bfc-welcome-card-footer">
        <p className="bfc-footer-title">Personalise your experience with the community by registering</p>
      </div>
      {/* <div className="bfc-welcome-card-footer-link text-center p-15">
        <div className="">
          <a href="https://apps.apple.com/in/app/biofuelcircle/id1556027472">
            <img src={IosLogo} alt="" height="47" />
          </a>
        </div>
        <div className="mt-10">
          <a href="https://play.google.com/store/apps/details?id=com.biofuelcircle.android">
            <img src={AndroidLogo} alt="" height="70" />
          </a>
        </div>
      </div> */}
      <div className="bfc-welcome-card-footer-link text-center">
        <div className="">
          <a href="https://apps.apple.com/in/app/biofuelcircle/id1556027472">
            <img src={IosLogo} alt="" height="47" />
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.biofuelcircle.android">
            <img src={AndroidLogo} alt="" height="70" />
          </a>
        </div>
      </div>
    </div> : isMobile && isFarmerUser && <div className="bfc-welcome-card-body d-flex justify-content-center">
      <img className="img-fluid bfc-logo" src={Logo} alt="BiofuelCircle" style={{ width: '30%' }} />
    </div>
  )
};

export default WelcomeCard;
