import React, { ElementType, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { getIn } from 'formik';
import { TextField, InputAdornment, MenuItem } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import PhoneIcon from '@material-ui/icons/Phone';
import Email from '@material-ui/icons/Email';
import RupeeIcon from 'mdi-react/RupeeIcon';
import ExposureIcon from '@material-ui/icons/Exposure';
import { colord as colorFormat } from 'colord';

import getColor from '../colors';
import MUIButton from '../Button';
import MUITypography from '../Typography';

type styleType = {
  color: string;
  size: 'small' | 'medium';
  uomType: 'text' | 'dropdown' | 'button';
};

const useStyles = makeStyles(() => ({
  root: {
    '& label.Mui-focused': {
      color: (props: styleType) => getColor(`${props?.color}`),
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: (props: styleType) => getColor(`${props?.color}`),
    },
    '& .MuiInputLabel-outlined': {
      transform: (props: styleType) => props.size === 'medium' && 'translate(14px, 18px) scale(1) !important',
    },
    '& .MuiOutlinedInput-root': {
      '& .MuiOutlinedInput-input': {
        padding: (props: styleType) => (props.size === 'medium' ? '16px 14px' : '10.5px 12px'),
      },
      '& textarea.MuiOutlinedInput-input': {
        padding: '0px !important',
      },
      '& fieldset': {
        borderColor: (props: styleType) => getColor(`${props?.color}`),
      },
      '&:hover fieldset': {
        borderColor: (props: styleType) => getColor(`${props?.color}`),
      },
      '&.Mui-error:hover fieldset': {
        borderColor: '#f44336',
      },
      '&.Mui-focused fieldset': {
        borderColor: (props: styleType) => getColor(`${props?.color}`),
      },
      '&.Mui-focused.Mui-error fieldset': {
        borderColor: '#f44336',
      },
      '&.Mui-disabled fieldset': {
        borderColor: (props: styleType) => getColor(`rgba(0, 0, 0, 0.26)`),
      },
      '& input,textarea': {
        letterSpacing: '1px',
      },
      '& textarea': {
        paddingRight: '4px',
        '&::-webkit-scrollbar': {
          width: '6px',
        },
        '&::-webkit-scrollbar-track': {
          boxShadow: `inset 0 0 5px ${(props: styleType) => getColor(`${props?.color}`)}`,
          borderRadius: '10px',
          backgroundColor: (props: styleType) =>
            `${colorFormat(getColor(`${props?.color}`))
              .alpha(0.13)
              .toHex()} !important`,
        },
        '&::-webkit-scrollbar-thumb': {
          background: (props: styleType) => getColor(`${props?.color}`),
          borderRadius: '10px',
        },
        '&::-webkit-scrollbar-thumb:hover': {
          background: (props: styleType) => colorFormat(getColor(`${props?.color}`)).darken(50),
        },
      },
    },
    '& .MuiOutlinedInput-adornedEnd': {
      padding: (props: styleType) => (props.uomType === 'button' || props.uomType === 'dropdown') && `0 !important`,
    },
    '& .MuiInputLabel-root': {
      letterSpacing: '1px',
      fontFamily: 'Segoe UI',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(15px, -6px) scale(0.70) !important',
      color: (props: styleType) => getColor(`${props?.color}`),
    },
    '& .MuiInputLabel-shrink': {
      color: (props: styleType) => getColor(`${props?.color}`),
    },
    '& .MuiInput-underline:before, & .MuiInput-underline:hover:not(.Mui-disabled):before, & .MuiFilledInput-underline:before':
      {
        borderColor: (props: styleType) => getColor(`${props?.color}`),
      },
    '& .MuiFilledInput-root': {
      background: (props: styleType) =>
        `${colorFormat(getColor(`${props?.color}`))
          .alpha(0.09)
          .toHex()} !important`,
      '&:hover': {
        background: (props: styleType) =>
          `${colorFormat(getColor(`${props?.color}`))
            .alpha(0.13)
            .toHex()} !important`,
      },
    },
    '& .MuiOutlinedInput-root:has(.MuiInputAdornment-positionStart) legend': {
      marginLeft: '42px !important',
      transitionDuration: '0ms !important',
      transitionDelay: '0ms !important',
    },
    '& .MuiOutlinedInput-root:not(.Mui-focused):has(.MuiInputAdornment-positionStart + .MuiInputBase-input[value=""]) legend':
      {
        maxWidth: '0px',
      },
    '& .MuiInputLabel-root:has(+ .MuiOutlinedInput-root .MuiInputAdornment-positionStart)': {
      transform: 'translate(55px, 12px) scale(1) !important',
      color: 'rgba(0, 0, 0, 0.54)',
    },
    '& .MuiInputLabel-root:has(+ .MuiOutlinedInput-root .MuiInputAdornment-positionStart + .MuiInputBase-input:not([value=""]))':
      {
        transform: 'translate(56px, -6px) scale(0.70) !important',
        color: (props: styleType) => getColor(`${props?.color}`),
      },
    '& .MuiInputLabel-root.Mui-focused:has(+ .MuiOutlinedInput-root .MuiInputAdornment-positionStart + .MuiInputBase-input[value=""])':
      {
        transform: 'translate(56px, -6px) scale(0.70) !important',
        color: (props: styleType) => getColor(`${props?.color}`),
      },
    '& .MuiInputAdornment-positionStart': {
      marginRight: 0,
    },
    '& .MuiFormHelperText-root.Mui-error': {
      margin: '4px 0 0 0',
    },
    '& .MuiInputAdornment-root': {
      height: 'auto',
      maxHeight: 'max-content',
    },
    '& .Mui-disabled': {
      color: (props: styleType) => `${getColor(`rgba(0, 0, 0, 0.38)`)} !important`,
    },
    '& .Mui-error': {
      color: (props: styleType) => `${getColor(`#f44336`)} !important`,
    },
  },
}));

type TextFieldPropTypes = {
  size?: 'small' | 'medium';
  variant?: 'outlined' | 'standard' | 'filled';
  color?: 'old' | 'primary' | 'muiPrimary' | 'secondary' | 'ternary' | string;
  fieldType?:
    | 'text'
    | 'textarea'
    | 'numeric'
    | 'plainNumeric'
    | 'numericWithSpecialCharacters'
    | 'alphabetic'
    | 'alphanumeric'
    | 'percent'
    | 'price'
    | 'qty'
    | 'email'
    | 'mobile';
  isPassword?: boolean;
  uomType?: 'text' | 'dropdown' | 'button';
  dropdownData?: [{}];
  dropdownSelectId?: number;
  dropdownDataStructure?: { labelApiParam: string; valueApiParam: string | number };
  handelDropdownChange?: (value: any) => void;
  value?: string | number;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  maxRows?: number;
  fullWidth?: boolean;
  StartIcon?: ElementType | string;
  EndIcon?: ElementType | string;
  field?: any;
  form?: any;
  required?: boolean;
  qtyPrecision?: number;
  pricePrecision?: number;
  isError?: boolean;
  errorMsg?: string;
  onChange?: (value: any) => void;
  inputRef?: any;
  autocomplete?: 'on' | 'off';
  endIconBtnDisabled?: boolean;
  endIconBtnOnClick?: Function;
};

const UOMSelectDropdown = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: 'none',
      },
      '& .MuiSelect-select': {
        fontWeight: 'bold',
      },
    },
  },
})(TextField);

const getEndIcon = (fieldType) => {
  let icon;
  switch (fieldType) {
    case 'percent':
      icon = '%';
      break;
    case 'price':
    case 'qty':
      icon = 'MT';
      break;
    default:
      icon = '';
  }
  return icon;
};

const getStartIcon = (fieldType) => {
  let icon;
  switch (fieldType) {
    case 'email':
      icon = Email;
      break;
    case 'mobile':
      icon = PhoneIcon;
      break;
    case 'price':
      icon = RupeeIcon;
      break;
    case 'percent':
      icon = ExposureIcon;
      break;
    default:
      icon = '';
  }
  return icon;
};

const convertFirstLetterToUppercase = (text: string) => text.charAt(0).toUpperCase() + text.slice(1);

const formatNumber = (number, maximumFractionDigits = 4, country = 'en-IN') => {
  let formattedPrice = new Intl.NumberFormat(country, maximumFractionDigits ? { maximumFractionDigits } : {}).format(
    number,
  );
  if (number.includes('.', number.length - 1)) {
    formattedPrice += '.';
  }
  return formattedPrice;
};

const MUITextField = ({
  size = 'small',
  variant = 'outlined',
  color = 'muiPrimary',
  fieldType = 'text',
  isPassword = false,
  uomType = 'text',
  dropdownData,
  dropdownSelectId,
  dropdownDataStructure = { labelApiParam: 'uom_code', valueApiParam: 'uom_id' },
  handelDropdownChange,
  value = '',
  label,
  placeholder,
  fullWidth = true,
  disabled = false,
  maxRows = 4,
  StartIcon = getStartIcon(fieldType),
  EndIcon = getEndIcon(fieldType),
  field,
  form,
  required = false,
  qtyPrecision = 3,
  pricePrecision = 2,
  isError = false,
  errorMsg = '',
  onChange,
  inputRef,
  autocomplete = 'on',
  endIconBtnDisabled = false,
  endIconBtnOnClick,
  ...rest
}: TextFieldPropTypes) => {
  const config = useSelector((state) => state?.['user']?.profile.ui_config);
  const classes = useStyles({ color: config?.theme_color || color, size, uomType });
  const bgColor = getColor(config?.theme_color || color);
  const isLight = colorFormat(bgColor).isLight();
  const textColor = isLight ? colorFormat(bgColor).darken(1).toHex() : colorFormat(bgColor).lighten(1).toHex();

  const [fieldValue, setValue] = useState<string | number>('');

  const precision =
    fieldType === 'price' ? config?.price_precision || pricePrecision : config?.qty_precision || qtyPrecision;

  useEffect(() => {
    if (value) {
      const formattedValue = ['price', 'qty', 'numeric'].includes(fieldType)
        ? formatNumber(`${value}`, precision)
        : value;
      setValue(formattedValue);
    } else if (field?.value) {
      const formattedValue = ['price', 'qty', 'numeric'].includes(fieldType)
        ? formatNumber(`${field?.value}`, precision)
        : field?.value;
      setValue(formattedValue);
    }
  }, [value, field?.value]);

  const decimalRegex = new RegExp(
    `^-?[0-9]{0,2}(\.)?([0-9]{1,${config?.qty_precision || qtyPrecision}})?$|^-?(100)(\.)?([0]{1,${
      config?.qty_precision || qtyPrecision
    }})?$`,
  );

  const currencyRegex = new RegExp(`^-?[0-9]+(\.)?([0-9]{1,${precision}})?$`);

  const formattedDropdownData = dropdownData?.map((data) => ({
    ...data,
    label: data?.[`${dropdownDataStructure?.labelApiParam}`],
    value: data?.[`${dropdownDataStructure?.valueApiParam}`],
  }));

  const handelChange = (newValue: string) => {
    const formattedValue =
      ['price', 'qty', 'numeric'].includes(fieldType) && newValue ? formatNumber(`${newValue}`, precision) : newValue;
    const floatValue =
      ['price', 'qty', 'numeric', 'percent'].includes(fieldType) && newValue ? parseFloat(newValue) : '';
    setValue(formattedValue);
    onChange && onChange(floatValue || newValue);
    form && form?.setFieldValue(field?.name, floatValue || newValue);
  };

  let fieldName;
  if (field?.name?.includes(':')) {
    fieldName = field?.name?.split(':')[0];
  } else {
    fieldName = field?.name;
  }
  const hasError = !!(getIn(form?.touched, fieldName) && getIn(form?.errors, fieldName)) || isError;
  const errText = hasError ? getIn(form?.errors, fieldName) || errorMsg : '';

  const inputStartAdornmentStyles = {
    background: disabled ? 'rgba(0,0,0,0.26)' : hasError ? '#f44336' : bgColor,
    height: '40px',
    padding: '10px',
    borderTopLeftRadius: '4px',
    borderBottomLeftRadius: '4px',
    fontSize: '26px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  return (
    <TextField
      {...field}
      {...rest}
      type={isPassword ? 'password' : 'text'}
      inputRef={inputRef}
      className={classes.root}
      label={label}
      placeholder={placeholder}
      size={size}
      variant={variant}
      disabled={disabled}
      fullWidth={fullWidth}
      required={required}
      error={hasError}
      helperText={errText}
      multiline={fieldType === 'textarea'}
      maxRows={maxRows}
      value={fieldValue}
      autoComplete="off"
      onKeyPress={(e) => {
        if (e.key === 'Enter' && uomType === 'button') {
          endIconBtnOnClick && endIconBtnOnClick();
        }
      }}
      onChange={(e) => {
        const plainTextValue = e.target.value.replace(/,/g, '').trim();
        if (fieldType === 'text') {
          handelChange(convertFirstLetterToUppercase(e.target.value));
        }
        if (fieldType === 'alphabetic' && /^[A-Za-z'_@?!,.#\$%\^\&*\)\(+=/ ]+$/gm.test(e.target.value)) {
          handelChange(convertFirstLetterToUppercase(e.target.value));
        }
        if (fieldType === 'textarea') {
          handelChange(convertFirstLetterToUppercase(e.target.value));
        }
        if (fieldType === 'alphanumeric' && /^[A-Za-z0-9'_@?!,.#\$%\^\&*\)\(+=/ ]+$/gm.test(e.target.value)) {
          handelChange(convertFirstLetterToUppercase(e.target.value));
        }
        if (fieldType === 'plainNumeric' && /^[0-9]+$/gm.test(e.target.value)) {
          handelChange(e.target.value);
        }
        if (fieldType === 'numericWithSpecialCharacters' && /^[0-9'_@?!,.#\$%\^\&*\)\(+=/ ]+$/gm.test(e.target.value)) {
          handelChange(e.target.value);
        }
        if (
          (fieldType === 'price' || fieldType === 'qty' || fieldType === 'numeric') &&
          currencyRegex.test(plainTextValue)
        ) {
          handelChange(plainTextValue);
        }
        if (fieldType === 'percent' && parseFloat(plainTextValue) <= 100 && decimalRegex.test(plainTextValue)) {
          handelChange(plainTextValue);
        }
        if (fieldType === 'mobile' && plainTextValue?.length <= 10 && /^\d+$/.test(plainTextValue)) {
          handelChange(plainTextValue);
        }
        if (fieldType === 'email' && /^[a-zA-Z0-9@.]+$/.test(plainTextValue)) {
          handelChange(plainTextValue);
        }
        if (!plainTextValue) {
          handelChange(plainTextValue);
        }
      }}
      InputProps={{
        startAdornment: StartIcon ? (
          <InputAdornment style={inputStartAdornmentStyles} position="start">
            {typeof StartIcon === 'string' ? (
              <span style={{ fontSize: 'var(--fs-base__twenty-four)', color: textColor }}>{StartIcon}</span>
            ) : (
              <StartIcon
                style={{
                  fontSize: 'var(--fs-base__twenty-four)',
                  color: textColor,
                  height: 24,
                  width: 24,
                  fill: textColor,
                }}
              />
            )}
          </InputAdornment>
        ) : (
          ''
        ),
        endAdornment: EndIcon ? (
          <InputAdornment position="end">
            {uomType === 'dropdown' ? (
              formattedDropdownData?.length ? (
                <UOMSelectDropdown
                  disabled={formattedDropdownData?.length < 2}
                  select
                  variant="outlined"
                  size="small"
                  value={dropdownSelectId}
                  onChange={(e) => {
                    form?.setFieldValue('qty_uom_id', e.target.value);
                    form?.setFieldValue(
                      'qty_uom_code',
                      formattedDropdownData?.find((item) => item?.['value'] === parseInt(e.target.value)),
                    );
                    handelDropdownChange && handelDropdownChange(e.target.value);
                  }}
                >
                  {formattedDropdownData?.map((option) => (
                    <MenuItem key={option?.['value']} value={option?.['value']}>
                      /{option?.['label']}
                    </MenuItem>
                  ))}
                </UOMSelectDropdown>
              ) : (
                ''
              )
            ) : uomType === 'button' ? (
              <MUIButton
                onClick={() => {
                  endIconBtnOnClick && endIconBtnOnClick();
                }}
                disabled={endIconBtnDisabled}
                style={{
                  color: 'rgba(0, 0, 0, 0.26)',
                  boxShadow: 'none',
                  ...inputStartAdornmentStyles,
                  borderRadius: 0,
                  borderTopRightRadius: 4,
                  borderBottomRightRadius: 4,
                  background: endIconBtnDisabled ? 'rgba(0,0,0,0.26)' : hasError ? '#f44336' : bgColor,
                }}
              >
                <MUITypography color={textColor} style={{ fontWeight: 'bold' }} variant="button">
                  {EndIcon}
                </MUITypography>
              </MUIButton>
            ) : (
              <span style={{ fontWeight: 'bold', color: '#000' }}>
                {fieldType === 'qty' || fieldType === 'percent' ? (
                  typeof EndIcon === 'string' ? (
                    EndIcon
                  ) : (
                    <EndIcon style={{ fontSize: 'var(--fs-base__twenty-four)', height: 24, width: 24 }} />
                  )
                ) : typeof EndIcon === 'string' ? (
                  `/${EndIcon}`
                ) : (
                  <EndIcon
                    id={field?.['name'] || rest?.['id']}
                    onClick={
                      !endIconBtnOnClick
                        ? false
                        : (e) => {
                            endIconBtnOnClick(e);
                          }
                    }
                    style={{
                      cursor: 'pointer',
                      color: bgColor,
                      fontSize: 'var(--fs-base__twenty-four)',
                      height: 24,
                      width: 24,
                    }}
                  />
                )}
              </span>
            )}
          </InputAdornment>
        ) : (
          ''
        ),
      }}
    />
  );
};
export default MUITextField;
