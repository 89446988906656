import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';
import store from 'redux/store';
import * as helpers from 'services/helpers';

export const reqTransform = {
  GetNearByVendors(filters, Id) {
    let bodyParams;
    if (!_.isEmpty(filters)) {
      bodyParams = {
        filters: {
          Nature_Of_Business_Enum: `${filters.isNatureEnum}`,
          Company_Address_Id: filters.company_addr_id ? `${filters.company_addr_id}` : '',
        },
        commodity_Req_Offer_Id: 0,
      };
    } else {
      bodyParams = {
        filters: {},
        commodity_Req_Offer_Id: 0,
      };
    }
    return bodyParams;
  },
};

export const resTransform = {
  fetchList(resData) {
    let storageData = (_.isArray(resData) ? resData : []).map((details) => {
      return resTransform.fetchDetails(details);
    });
    return _.keyBy(storageData, 'company_address_id');
  },
  fetchDetails(resData) {
    return {
      ...resData,
      id: _.get(resData, 'company_address_id') || '',
    };
  },
  fetchListPickupMap(resData) {
    let storageData = (_.isArray(resData) ? resData : []).map((details) => {
      return resTransform.fetchDetailsPickupMap(details);
    });
    return _.keyBy(storageData, 'bill_to_address_id');
  },
  fetchDetailsPickupMap(resData) {
    return {
      ...resData,
      id: _.get(resData, 'bill_to_address_id') || '',
    };
  },
};
