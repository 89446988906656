import _ from 'lodash';
import classNames from 'classnames';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import utils, {  getUrlWithEncryptedQueryParams } from 'lib/utils';
import CloseIcon from '@material-ui/icons/Close';
import Bidicon from 'assets/images/icons/icon-bid.svg';

const PopupForm = ({ children, icon, title, onClose, isAdmin, isShow, isAutoBid, isSeller, sellId, isWorkFlowPopup }) => {
  const [stickToBottom, setStickToBottom] = useState(false);
  const modalFormClass = classNames(['bfc-modalform', { stickToBottomStyle: stickToBottom }]);
  const encodeBackUrl = utils.encodeBackUrl();
  return (
    <div className={modalFormClass}>
      <div className="bfc-modalform-header" style={isAutoBid ? { backgroundColor: '#FFF9F5' } : null}>
        <div
          className="icons-wrapper cursor-pointer w-100"
          onClick={(e) => {
            e.preventDefault();
            setStickToBottom(!stickToBottom);
          }}>
          <img src={icon || Bidicon} style={{width: 40, height: 40, marginRight: 10}} alt="" />
          <span className="title p-relative" style={{ fontSize: 'var(--fs-base__zero)', fontFamily: 'var(--unnamed-font-segoeUI)', }}>
            {title || 'pls set title prop '}
          </span>
          {isAutoBid && isSeller && (
            <Link to={getUrlWithEncryptedQueryParams(`/commodity/offers/${sellId}/overview?back=${encodeBackUrl}`)} target="_blank">
              <sub className="ml-5" style={{ fontSize: 'var(--fs-base__three)', }}>(Sell # {sellId})</sub>
            </Link>
          )}
        </div>
        <div className="close-wrapper">
          <span
            className="close"
            style={{
              cursor: 'pointer',
              color: 'var(--unnamed-color-1b62ab)',
              fontWeight: '700',
            }}
            onClick={onClose}>
            <CloseIcon />
          </span>
        </div>
      </div>

      <div
        className="modalform-body"
        style={{
          overflow: 'auto',
          height: '100%',
          paddingBottom: '100px',
          marginBottom: '10px',
          paddingTop: isWorkFlowPopup ? "10px" : "",
        }}>
        {children}
      </div>
    </div>
  );
};

export default PopupForm;
