import React, { useState, useRef, useEffect } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/styles/makeStyles';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import MoreVert from '@material-ui/icons/MoreVert';
import PublishIcon from '@material-ui/icons/Publish';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import IconButton from '@material-ui/core/IconButton';
import { colord as colorFormat } from 'colord';
import { Tooltip } from 'antd';

import getColor from '../colors';

type stylePropTypes = {
  color: string;
  textColor: string;
  variant: string;
  disableRipple: boolean;
};

const useStyles = makeStyles(() => ({
  contained: {
    color: (props: stylePropTypes) => props?.textColor,
    backgroundColor: (props: stylePropTypes) => props?.color,
    borderColor: (props: stylePropTypes) => props?.color,
    '&:hover': {
      backgroundColor: (props: stylePropTypes) => props?.color,
    },
    '&.Mui-disabled .MuiButton-label': {
      color: 'rgba(0, 0, 0, 0.26)',
    },
  },
  outlined: {
    borderColor: (props: stylePropTypes) => props?.color,
    color: (props: stylePropTypes) => props?.textColor,
    '&:hover': {
      backgroundColor: (props: stylePropTypes) => colorFormat(props?.color).alpha(0.2).toHex(),
    },
    '&.Mui-disabled .MuiButton-label': {
      color: 'rgba(0, 0, 0, 0.26)',
    },
  },
  text: {
    color: (props: stylePropTypes) => `${props?.color} !important`,
    '&:hover': {
      backgroundColor: (props: stylePropTypes) => colorFormat(props?.color).alpha(0.2).toHex(),
    },
    '&.Mui-disabled .MuiButton-label': {
      color: 'rgba(0, 0, 0, 0.26)',
    },
  },
  label: {
    color: (props: stylePropTypes) => (props?.variant === 'contained' ? props?.textColor : props?.color),
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontFamily: 'SEGOE UI',
    fontSizeAdjust: 'true',
    letterSpacing: '1px',
  },
  selected: {
    backgroundColor: (props: stylePropTypes) => `${colorFormat(props?.color).alpha(0.1).toHex()} !important`,
  },
  menuItemRoot: {
    fontFamily: 'SEGOE UI',
    fontSizeAdjust: 'true',
    letterSpacing: '1px',
    '&:hover': {
      backgroundColor: (props: stylePropTypes) => `${colorFormat(props?.color).alpha(0.1).toHex()} !important`,
    },
  },
  menuListRoot: {
    padding: 0,
  },
  iconButtonRoot: {
    // padding: (props: stylePropTypes) => (props?.variant === 'contained' ? '7px !important' : '5px !important'),
    cursor: (props: stylePropTypes) => (props?.disableRipple ? 'default !important' : 'pointer !important'),
    '&:hover': {
      backgroundColor: (props: stylePropTypes) =>
        props?.disableRipple
          ? 'transparent !important'
          : `${
              props?.variant === 'contained'
                ? colorFormat(props?.color).alpha(0.9).toHex()
                : colorFormat(props?.color).alpha(0.08).toHex()
            } !important`,
    },
  },
  iconButtonRootHover: {
    cursor: (props: stylePropTypes) => (props?.disableRipple ? 'default !important' : 'pointer !important'),
    '&:hover': {
      backgroundColor: (props: stylePropTypes) =>
        props?.disableRipple
          ? 'transparent !important'
          : `${
              props?.variant === 'contained'
                ? colorFormat(props?.color).alpha(0.9).toHex()
                : colorFormat(props?.color).alpha(0.08).toHex()
            } !important`,
    },
  },
}));

type MUIButtonPropTypes = {
  type?: 'normal' | 'icon' | 'group' | 'buttonDropdown' | 'iconDropdown' | 'fileUpload';
  size?: 'small' | 'medium' | 'large';
  variant?: 'contained' | 'outlined' | 'text';
  color?: 'old' | 'primary' | 'muiPrimary' | 'secondary' | 'ternary' | string;
  onClick?: Function;
  startIcon?: React.ElementType | React.ReactElement | string;
  endIcon?: React.ElementType | React.ReactElement | string;
  disabled?: boolean;
  linkUrl?: string;
  children?: React.ElementType | string | React.ReactNode | HTMLElement;
  options?: { label: string; disabled?: boolean }[];
  indexForPrimaryBtn?: number;
  disableMenuItemIndex?: number;
  Icon?: React.ElementType | string;
  iconBtnSize?: 'xSmall' | 'small' | 'medium';
  className?: string;
  style?: object;
  iconStyle?: object;
  disableRipple?: boolean;
  tooltipText?: string;
  tooltipPlacement?:
    | 'top'
    | 'topLeft'
    | 'topRight'
    | 'right'
    | 'rightTop'
    | 'rightBottom'
    | 'bottom'
    | 'bottomLeft'
    | 'bottomRight'
    | 'left'
    | 'leftTop'
    | 'leftBottom';
  dropdownPlacement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';
};

const MUIButton = ({
  type = 'normal',
  size = 'medium',
  variant = 'contained',
  color = '',
  onClick,
  startIcon,
  endIcon,
  disabled,
  linkUrl,
  children,
  options,
  indexForPrimaryBtn = 0,
  disableMenuItemIndex,
  Icon,
  iconBtnSize = 'medium',
  iconStyle = {},
  ...rest
}: MUIButtonPropTypes) => {
  const state = useSelector((state) => state);
  const config = _.get(state, 'user.profile.ui_config')!;
  const bgColor = getColor(color || config['theme_color']);
  const isLight = colorFormat(bgColor).isLight();
  const textColor = isLight ? colorFormat(bgColor).darken(1).toHex() : colorFormat(bgColor).lighten(1).toHex();
  const classes = useStyles({ color: bgColor, textColor, variant, disableRipple: rest?.disableRipple || false });
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = useState(indexForPrimaryBtn);

  const handleClick = (event) => {
    onClick && onClick(event);
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    onClick && onClick(event.currentTarget.innerText, event, index);
    setOpen(false);
  };

  useEffect(() => {
    setSelectedIndex(indexForPrimaryBtn);
  }, [indexForPrimaryBtn]);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef?.current?.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const styles = {
    label: classes.label,
    contained: classes.contained,
    outlined: classes.outlined,
    text: classes.text,
  };

  const getDropdownContent = () => (
    <Popper
      open={open}
      placement={rest?.dropdownPlacement}
      anchorEl={anchorRef.current}
      role={undefined}
      transition
      disablePortal
      style={{ zIndex: 2 }}
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
          }}
        >
          <Paper>
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList classes={{ root: classes.menuListRoot }} id="split-button-menu">
                {options?.map((option, index) => (
                  <MenuItem
                    classes={{ selected: classes.selected, root: classes.menuItemRoot }}
                    key={option?.label}
                    disabled={option?.disabled}
                    selected={index === selectedIndex}
                    onClick={(event) => handleMenuItemClick(event, index)}
                  >
                    {option?.label}
                  </MenuItem>
                ))}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );

  return type === 'normal' ? (
    <Button
      {...rest}
      classes={styles}
      size={size}
      variant={variant}
      disabled={disabled}
      startIcon={startIcon}
      endIcon={endIcon}
      href={linkUrl}
      onClick={handleClick}
    >
      {children}
    </Button>
  ) : type === 'buttonDropdown' ? (
    <>
      <ButtonGroup
        {...rest}
        size={size}
        variant={variant}
        disabled={disabled || options?.every((option) => option.disabled === true)}
        ref={anchorRef}
        aria-label="split button"
      >
        <Button
          startIcon={startIcon}
          endIcon={endIcon}
          classes={styles}
          onClick={handleClick}
          disabled={options?.[selectedIndex]?.disabled}
        >
          {options?.[selectedIndex]?.label}
        </Button>
        <Button
          classes={styles}
          color="primary"
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          disabled={options
            ?.filter((option, index) => index !== selectedIndex)
            ?.every((option) => option?.disabled === true)}
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      {getDropdownContent()}
    </>
  ) : type === 'iconDropdown' ? (
    <Tooltip placement={rest?.tooltipPlacement} title={rest?.tooltipText}>
      <IconButton
        innerRef={anchorRef}
        size={iconBtnSize === 'xSmall' || iconBtnSize === 'small' ? 'small' : 'medium'}
        classes={{ root: iconBtnSize === 'medium' ? classes.iconButtonRoot : classes?.iconButtonRootHover }}
        style={
          variant === 'contained'
            ? { background: bgColor, color: textColor }
            : variant === 'outlined'
            ? { color: bgColor, border: `2px solid ${bgColor}` }
            : { color: bgColor }
        }
        disabled={disabled}
        onClick={handleToggle}
      >
        <MoreVert />
      </IconButton>
      {getDropdownContent()}
    </Tooltip>
  ) : type === 'group' ? (
    <ButtonGroup {...rest} size={size} variant={variant} disabled={disabled} aria-label="outlined primary button group">
      {options?.map((option, index) => (
        <Button
          classes={styles}
          key={option?.label}
          disabled={option?.disabled}
          onClick={(event) => handleMenuItemClick(event, index)}
        >
          {option?.label}
        </Button>
      ))}
    </ButtonGroup>
  ) : type === 'fileUpload' ? (
    <>
      <input
        accept="image/*"
        className={'d-none'}
        id="icon-button-file"
        type="file"
        onChange={(e) => {
          handleClick(e);
        }}
      />
      <Tooltip placement={rest?.tooltipPlacement} title={rest?.tooltipText || 'Upload Image'} zIndex={1300}>
        <label style={{ margin: 0 }} htmlFor="icon-button-file">
          <IconButton
            {...rest}
            size={iconBtnSize === 'xSmall' || iconBtnSize === 'small' ? 'small' : 'medium'}
            classes={{
              root:
                iconBtnSize === 'medium'
                  ? classes.iconButtonRoot
                  : variant !== 'contained'
                  ? classes?.iconButtonRootHover
                  : '',
            }}
            style={
              variant === 'contained'
                ? {
                    background: bgColor,
                    color: textColor,
                    padding: iconBtnSize === 'medium' ? 7 : iconBtnSize === 'xSmall' ? 2 : 5,
                  }
                : variant === 'outlined'
                ? {
                    color: bgColor,
                    border: `2px solid ${bgColor}`,
                    padding: rest?.disableRipple ? 0 : iconBtnSize === 'medium' ? 7 : iconBtnSize === 'xSmall' ? 2 : 5,
                  }
                : {
                    color: bgColor,
                    padding: rest?.disableRipple ? 0 : iconBtnSize === 'medium' ? 7 : iconBtnSize === 'xSmall' ? 2 : 5,
                  }
            }
            disabled={disabled}
            aria-label="upload picture"
            component="span"
          >
            {Icon ? (
              <Icon
                style={{
                  fill: 'currentColor',
                  height: iconBtnSize === 'xSmall' ? 14 : iconBtnSize === 'small' ? 20 : 24,
                  width: iconBtnSize === 'xSmall' ? 14 : iconBtnSize === 'small' ? 20 : 24,
                  ...iconStyle,
                }}
              />
            ) : (
              <PublishIcon
                style={{
                  fill: 'currentColor',
                  height: iconBtnSize === 'xSmall' ? 14 : iconBtnSize === 'small' ? 20 : 24,
                  width: iconBtnSize === 'xSmall' ? 14 : iconBtnSize === 'small' ? 20 : 24,
                  ...iconStyle,
                }}
              />
            )}
          </IconButton>
        </label>
      </Tooltip>
    </>
  ) : (
    <Tooltip placement={rest?.tooltipPlacement} title={rest?.tooltipText}>
      <IconButton
        {...rest}
        size={iconBtnSize === 'xSmall' || iconBtnSize === 'small' ? 'small' : 'medium'}
        classes={{
          root:
            iconBtnSize === 'medium'
              ? classes.iconButtonRoot
              : variant !== 'contained'
              ? classes?.iconButtonRootHover
              : '',
        }}
        style={
          variant === 'contained'
            ? {
                background: bgColor,
                color: textColor,
                padding: iconBtnSize === 'medium' ? 7 : iconBtnSize === 'xSmall' ? 2 : 5,
              }
            : variant === 'outlined'
            ? {
                color: bgColor,
                border: `2px solid ${bgColor}`,
                padding: rest?.disableRipple ? 0 : iconBtnSize === 'medium' ? 7 : iconBtnSize === 'xSmall' ? 2 : 5,
              }
            : {
                color: bgColor,
                padding: rest?.disableRipple ? 0 : iconBtnSize === 'medium' ? 7 : iconBtnSize === 'xSmall' ? 2 : 5,
              }
        }
        disabled={disabled}
        onClick={handleClick}
      >
        {Icon ? (
          typeof Icon === 'string' ? (
            Icon
          ) : (
            <Icon
              style={{
                fill: 'currentColor',
                height: iconBtnSize === 'xSmall' ? 14 : iconBtnSize === 'small' ? 20 : 24,
                width: iconBtnSize === 'xSmall' ? 14 : iconBtnSize === 'small' ? 20 : 24,
                ...iconStyle,
              }}
            />
          )
        ) : (
          ''
        )}
      </IconButton>
    </Tooltip>
  );
};
export default MUIButton;
