// const prodUrl = 'http://52.172.150.57:8050';
// const devUrl = 'http://40.121.33.120:4000/node/proxy/http://52.172.150.57:8050';
// const localUrl = 'http://localhost:4000/node/proxy/http://52.172.150.57:8050';

// let baseUrl = devUrl;

// if (process.env.NODE_ENV === 'production') {
//   baseUrl = prodUrl;
// }

// baseUrl = devUrl;

// // baseUrl = localUrl
// // baseUrl = prodUrl;

const baseUrl = process.env.REACT_APP_BASE_URL || '';
// const baseUrl = 'https://prepapi.biofuelcircle.com:8060' || '';
const buyerToken = process.env.REACT_APP_BUYER_TOKEN;
const livechatUrl = process.env.REACT_APP_LIVECHAT_URL;
const notificationUrl = process.env.REACT_APP_NOTIFICATION_URL;
const liveChatPassword = process.env.REACT_APP_LIVECHAT_PASSWORD;
const indentDealsUrl = process.env.REACT_APP_INDENT_DEALS_URL;
const iProfitUrl = process.env.REACT_APP_IPROFIT_URL;
const isApiEncryptionEnabled = process.env.REACT_APP_API_ENCRYPTION_ENABLED;
const isRouteEncryptionEnabled = process.env.REACT_APP_API_ROUTE_ENCRYPTION_ENABLED;
const encryption_secret_key = process.env.REACT_APP_ENCRYPTION_SECRET_KEY;
const encryption_secret_iv = process.env.REACT_APP_ENCRYPTION_SECRET_IV;

const isProd =
  baseUrl.includes('8060') ||
  baseUrl.includes('https://api.biofuelcircle.com') ||
  baseUrl.includes('https://demoapi.biofuelcircle.com:8055');

// console.log('\n================================');
// console.log('process.env-------->', process.env);
// console.log('baseUrl----->', baseUrl);
// console.log('buyerToken---->', buyerToken);
// console.log('================================');

export default {
  baseUrl,
  buyerToken,
  isProd,
  livechatUrl,
  notificationUrl,
  liveChatPassword,
  indentDealsUrl,
  iProfitUrl,
  isApiEncryptionEnabled,
  isRouteEncryptionEnabled,
  encryption_secret_key,
  encryption_secret_iv,
};
