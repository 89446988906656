import _ from 'lodash';
import utils from 'lib/utils';
import dayjs from 'dayjs';
import config from 'config';
import store from 'redux/store';

export const reqTransform = {
  fetchList(params) {
    let bodyParams;
    const filterLength = Object.keys(params.filters).length;
    if (filterLength === 0) {
      bodyParams = {
        ...params,
        filters: {
          ...(params.filters || {}),
        },
        offset: params.offset || 0,
        invoking_person_id: params['person_id'] || _.get(window, 'user.userid', 0),
      };
    } else {
      bodyParams = {
        ...params,
        filters: {
          ...(params.filters || {}),
        },
        offset: 0,
        invoking_person_id: params['person_id'] || _.get(window, 'user.userid', 0),
      };
    }

    return bodyParams;
  },
  fetchDetails(id) {
    return id;
  },
  fetchListAgainstPersonId(params = {}) {
    let bodyParams = {
      ...params,
      invoking_person_id: params['person_id'] || _.get(window, 'user.userid', 0),
    };
    return bodyParams;
  },
  fetchSellerBankList(params = {}) {
    let qsParams = {
      ...params,
    };
    return utils.getQs(qsParams);
  },
  //Invoice Details
  fetchInvoiceDetails(params = {}) {
    const qsParams = {
      ...params,
      invoking_person_id: params['person_id'] || _.get(window, 'user.userid', 0),
    };
    return utils.getQs(qsParams);
  },

  //update invoice master details
  update(id, params) {
    let bodyParams = {
      ...params,
      invoking_person_id: params['person_id'] || _.get(window, 'user.userid', 0),
      operation_Enum: 3,
    };
    return bodyParams;
  },

  //Update PDF
  updatePDF(params) {
    const qsParams = {
      ...params,
      updating_person_id: _.get(window, 'user.userid', 0),
    };
    return utils.getQs(qsParams);
  },

  createRecordPayment(params = {}) {
    let bodyParams = {
      ...params,
      paid_to_bank: params['paid_to_bank'] + '',
      invoking_person_id: params['person_id'] || _.get(window, 'user.userid', 0),
      payment_method_id: 1,
      // payment_status_enum: 1,
      payment_made_date: dayjs(params['payment_made_date']).format('YYYY-MM-DD'),
      operation_enum: 1,
      online_offline_enum: 2,
    };
    delete bodyParams['summary_amount_to_pay'];
    return bodyParams;
  },

  fetchCustomerList(params) {
    const qsParams = {
      ...params,
      invoking_person_id: _.get(window, 'user.userid', 0),
    };
    return utils.getQs(qsParams);
  },

  createCRorDRNote(params) {
    let qsParams;
    if (!params.qty || !params.price) {
      if (!params.qty && params.price) {
        qsParams = {
          invoice_master_id: params['invoice_master_id'],
          noteType: params['noteType'],
          price: params['price'],
        };
      }
      if (!params.price && params.qty) {
        qsParams = {
          invoice_master_id: params['invoice_master_id'],
          noteType: params['noteType'],
          qty: params['qty'],
        };
      }
      if (!params.price && !params.qty && !params.lumpSum_price) {
        qsParams = {
          invoice_master_id: params['invoice_master_id'],
          noteType: params['noteType'],
        };
      }
      if (params.lumpSum_price) {
        qsParams = {
          invoice_master_id: params['invoice_master_id'],
          noteType: params['noteType'],
          LumpSum_Price: params['lumpSum_price'],
        };
      }
    } else {
      qsParams = {
        ...params,
      };
    }
    return utils.getQs(qsParams);
  },
  createESign(params) {
    let qsParams = {
      ...params,
    };
    return utils.getQs(qsParams);
  },
  createESignResponse(params) {
    let bodyParams = {
      ...params,
    };
    return bodyParams;
  },
  fetchDealBalancePosition(params = {}) {
    let qsParams = {
      ...params,
    };
    return utils.getQs(qsParams);
  },
  updateInvoiceActionStatus(params = {}) {
    let qsParams = {
      ...params,
    };
    return utils.getQs(qsParams);
  },
  getInvoiceVerificationStatusHistory(params = {}) {
    let qsParams = {
      invoice_master_id: params['invoice_master_id'],
    };
    return utils.getQs(qsParams);
  },
  saveUpdateInvoiceVerificationStatus(params = {}) {
    let bodyParams = {
      invoice_Master_Id: params['invoice_Master_Id'],
      invoice_Verification_Enum: params['invoice_Verification_Enum'],
      operation_Enum: params['operation_Enum'],
      amount_To_Pay: params['amount_To_Pay'],
      pay_On_Date: params['pay_On_Date'],
      remarks: params['remarks'],
      mapped_invoice_master_id: params['mapped_invoice_master_id'],
    };
    return bodyParams;
  },
  SaveUpdateInvoiceVerificationStatus_Bulk(params = {}) {
    let bodyParams = {
      ...params,
    };
    return bodyParams;
  },
};

export const resTransform = {
  fetchList(resData) {
    let deliveriesData = (_.isArray(resData) ? resData : []).map((details) => {
      return resTransform.fetchDetails(details);
    });
    return _.keyBy(deliveriesData, 'invoice_master_id');
  },
  fetchDetails(resData) {
    let data = {
      ...resData,
      id: _.get(resData, 'invoice_master_id') || 0,
    };
    return data;
  },
  fetchListAgainstPersonId(resData) {
    let deliveriesData = (_.isArray(resData) ? resData : []).map((details) => {
      return resTransform.fetchDetailsAgainstPersonId(details);
    });
    return _.keyBy(deliveriesData, 'invoice_master_id');
  },
  fetchDetailsAgainstPersonId(resData) {
    let data = {
      ...resData,
      id: _.get(resData, 'invoice_master_id') || 0,
    };
    return data;
  },
  //Invoice Details
  fetchInvoiceDetails(resData) {
    let data = {
      ...resData,
      id: _.get(resData, 'invoice_master.invoice_master_id') || 0,
    };
    return data;
  },
  update(resData) {
    return resData;
  },

  createESign(resData) {
    return resData;
  },
  createESignResponse(resData) {
    return resData;
  },

  updatePDF(resData) {
    return resData;
  },

  createRecordPayment(resData) {
    return resData;
  },

  fetchCustomerList(resData) {
    let customerData = (_.isArray(resData) ? resData : []).map((details) => {
      return resTransform.fetchCustomerDetails(details);
    });
    return _.keyBy(customerData, 'entity_id');
  },
  fetchCustomerDetails(resData) {
    let data = {
      ...resData,
      id: _.get(resData, 'entity_id') || 0,
    };
    return data;
  },
  createCRorDRNote(resData) {
    return resData;
  },

  fetchListAgainstPersonIdReport(resData) {
    let deliveriesData = (_.isArray(resData) ? resData : []).map((details) => {
      return resTransform.fetchDetailsAgainstPersonIdReport(details);
    });
    return _.keyBy(deliveriesData, 'invoice_master_id');
  },
  fetchDetailsAgainstPersonIdReport(resData) {
    let data = {
      ...resData,
      id: _.get(resData, 'invoice_master_id') || 0,
      created_datetime: resData.created_datetime && dayjs(resData.created_datetime).format('DD-MMM-YYYY'),
      type: _.get(resData, 'settlement_invoice_type_code') || '',
      category: _.get(resData, 'settlement_invoice_category_code') || '',
      invoice_date: resData.invoice_date && dayjs(resData.invoice_date).format('DD-MMM-YYYY'),
      payment_due_date: resData.payment_due_date && dayjs(resData.payment_due_date).format('DD-MMM-YYYY'),
      buyer_order_date: resData.buyer_order_date && dayjs(resData.buyer_order_date).format('DD-MMM-YYYY HH:mm:ss a'),
      eway_bill_date: resData.eway_bill_date && dayjs(resData.eway_bill_date).format('DD-MMM-YYYY  HH:mm:ss a'),
    };
    delete data['total_count'];
    delete data['invoice_type_enum'];
    delete data['qty_uom_id'];
    delete data['invoice_status_enum'];
    delete data['internal_company_id'];
    delete data['invoice_running_seq'];
    delete data['is_editable'];
    delete data['commodity_code'];
    delete data['base_location_id'];
    delete data['delivery_location_id'];
    delete data['counterpart_company_id'];
    delete data['type_of_invoice'];
    return data;
  },

  fetchSellerBankList(resData) {
    let customerData = (_.isArray(resData) ? resData : []).map((details) => {
      return resTransform.fetchSellerBankDetails(details);
    });
    return _.keyBy(customerData, 'account_number');
  },
  fetchSellerBankDetails(resData) {
    let data = {
      ...resData,
      id: _.get(resData, 'account_number') || 0,
    };
    return data;
  },

  fetchDealBalancePosition(resData) {
    let customerData = (_.isArray(resData) ? resData : []).map((details) => {
      return resTransform.fetchDealBalancePositionDetails(details);
    });
    return _.keyBy(customerData, 'table_id');
  },
  fetchDealBalancePositionDetails(resData) {
    let data = {
      ...resData,
      id: _.get(resData, 'table_id') || 0,
    };
    return data;
  },
  updateInvoiceActionStatus(resData) {
    let data = {
      ...resData,
      id: _.get(resData, 'invoice_master_id') || 0,
    };
    return data;
  },
  getInvoiceVerificationStatusHistory(resData) {
    // let data = {
    //   ...resData,
    //   id: _.get(resData, 'Invoice_Master_Id') || 0,
    // };
    return resData;
  },
  saveUpdateInvoiceVerificationStatus(resData) {
    return resData;
  },
  SaveUpdateInvoiceVerificationStatus_Bulk(resData) {
    return resData;
  },
};
