import _ from 'lodash';
import utils, { getDecryptedUrl } from './utils';

import config from '../config';

import dayjs from 'dayjs';
import profile from './profile';

import transport from './transport';
// import * as mockData from './mockData';
import normalize from 'normalize-object';

const { baseUrl } = config;

const api = (() => {
  const fetchDeliveryDetails = async (id = 64553) => {
    try {
      // TODO : get user id from window for
      // all apis

      const bodyParams = {
        transport_delivery_schedule_allotment_id: id,
      };

      // const qs = Object.keys(bodyParams)
      //   .map((key) => `${key}=${bodyParams[key]}`)
      //   .join('&');
      const qs = utils.getQs(bodyParams);

      const url = `${baseUrl}/BioFuelDeliveries/api/DeliveryAssignment/FetchTransportDeliveryDetailsAgainstTransportDeliveryScheduleAllotedId_ELOC?${qs}`;
      const res = await utils.fetch(url).ready;

      const resData = await utils.processApiRes(res);

      return resData;
    } catch (err) {
      //TODO : change variable name to errData..it alrealy has key errors
      const errors = await utils.processApiErrors(err);
      throw errors;
    }
  };

  const fetchDeliveryDocuments = async (delivery_id = 0) => {
    try {
      const bodyParams = {
        delivery_id: delivery_id,
        invoking_person_id: parseInt(_.get(globalThis, 'user.userid') || 0),
      };

      // const qs = Object.keys(bodyParams)
      //   .map((key) => `${key}=${bodyParams[key]}`)
      //   .join('&');
      const qs = utils.getQs(bodyParams);
      const url = `${baseUrl}/BioFuelDeliveries/api/DeliveryAssignment/FetchCargoDetailsAgainstDeliveryId?${qs}`;

      const res = await utils.fetch(url).ready;

      const resData = await utils.processApiRes(res);
      return resData;
    } catch (err) {
      const errors = await utils.processApiErrors(err);
      throw errors;
    }
  };

  const getFilteredData = async (params = {}) => {
    const storeState = globalThis.store.getState();
    const userInfo = _.get(storeState, 'user') || {};
    const is_indent_enabled = _.get(userInfo, 'profile.is_indent_enabled') || false;
    const is_seller_indent_enabled = _.get(userInfo, 'profile.is_seller_indent_enabled') || false;
    const is_supply_partner = _.get(userInfo, 'profile.is_supply_partner') || false;

    let loc = getDecryptedUrl(window.location.href);
    const { indentDealsUrl } = config;

    const is_current_instance_indent_based = _.get(userInfo, 'profile.is_current_instance_indent_based');
    if (is_current_instance_indent_based) {
      params['controller_enum'] = 4;
    }
    if (
      (loc.includes(indentDealsUrl) && !is_seller_indent_enabled && !is_indent_enabled) ||
      (loc.includes(indentDealsUrl) && is_supply_partner)
    ) {
      params['controller_enum'] = 2;
    }
    try {
      const bodyParams = {
        ...params,
      };
      bodyParams['filters'] = {
        ...(params.filters || {}),
      };

      if (params['self']) {
        bodyParams['filters']['person_id'] = `${_.get(window, 'user.person_id', 0)}`;

        if (!params.filters.req_offer_status_enum) {
          delete bodyParams['filters']['req_offer_status_enum'];
        }
      } else {
        delete bodyParams['filters']['person_id'];
        bodyParams['filters']['req_offer_status_enum'] = '2';
      }

      bodyParams['filters']['is_followed_by_person_id'] = `${_.get(window, 'user.person_id', 0)}`;

      const url = `${baseUrl}/BioFuelCommonUtilities/api/Filter/GetControllerData_ELOC`;

      const res = await utils.fetch(url, {
        method: 'POST',
        body: JSON.stringify(bodyParams),
      }).ready;

      const resData = await utils.processApiRes(res);
      return resData;
    } catch (err) {
      console.log('getFilteredData->', err);
      const errors = await utils.processApiErrors(err);

      throw errors;
    }
  };

  const getCommodityDropdownData = async (params = {}) => {
    try {
      const entity = _.get(params, 'entity') || '';
      const qparams = _.get(params, 'qparams') || {};
      // const qs = Object.keys(qparams)
      //   .map((key) => `${key}=${qparams[key]}`)
      //   .join('&');
      const qs = utils.getQs(qparams);
      let url = '';
      if (entity === 'commoditySpecs') {
        url = `${baseUrl}/BioFuelRegistration/api/ReferenceData/FetchAllCommoditySpecsAgainstCommodityId?${qs}`;
      } else if (entity === 'commodityForm') {
        url = `${baseUrl}/BioFuelRegistration/api/ReferenceData/FetchCommodityFormAgainstCommodityId?${qs}`;
      } else if (entity === 'commodityResidue') {
        url = `${baseUrl}/BioFuelRegistration/api/ReferenceData/GetCommodityResidueAgainstCommodityId?${qs}`;
      } else if (entity === 'commodityTypeForm') {
        url = `${baseUrl}/BioFuelRegistration/api/ReferenceData/FetchCombinationOfCommodityTypeForm`;
      } else if (entity === 'typeFormCommodity') {
        url = `${baseUrl}/BioFuelRegistration/api/ReferenceData/FetchAllCommodityAgainstTypeFormCode?${qs}`;
      } else if (entity === 'commodityForExtendedProfile') {
        url = `${baseUrl}/BioFuelRegistration/api/ReferenceData/ReadReferenceData?${qs}`;
      } else if (entity === 'residueForExtendedProfile') {
        url = `${baseUrl}/BioFuelRegistration/api/ReferenceData/FetchCommodityResidueAgainstCommodityId?${qs}`;
      }

      const res = await utils.fetch(url).ready;

      let resData = await utils.processApiRes(res);

      //TODO : review this
      if (entity === 'commoditySpecs') {
        const tmpData = resData.map((d) => {
          d = {
            ...d,
            displaymember: d['commodity_spec_code'] || 'test spec',
            valuemember: d['commodity_spec_id'] || 0,
            display_member: d['commodity_spec_code'] || 'test spec',
            value_member: d['commodity_spec_id'] || 0,
            qs_uom_id: d['qs_uom_id'] || 1,
            qs_uom_id: d['qs_uom_id'] || 1,
            qs_uom_code: d['qs_uom_code'] || 'mt',
            qs_uom_code: d['qs_uom_code'] || 'mt',
            difference_by_enum: d['difference_by_enum'] || 2,
            difference_by_enum: d['difference_by_enum'] || 2,
            difference_by_enum_code: d['difference_by_enum_code'] || 'percent',
            difference_by_enum_code: d['difference_by_enum_code'] || 'percent',
          };

          return d;
        });
        return tmpData;
      }

      if (entity === 'commodityTypeForm') {
        const tmpData = resData.map((item, index) => {
          return {
            display_member: `${item.commodity_type_code} - ${item.commodity_form_code}`,
            value_member: `${item.commodity_type_code}${item.commodity_form_code}`,
            ...item,
          };
        });

        return tmpData;
      }

      return resData;
    } catch (err) {
      const errors = await utils.processApiErrors(err);
      console.log('getCommodityDropdownData->err--->', errors);
      throw errors;
    }
  };

  const getReferenceData = async (params = {}) => {
    try {
      //TODO : temp code. remove later
      // debugger;

      // if(params['entity'] === 'commodity'){
      //   let mockData = [{
      //       displaymember : 'Suger Cane',
      //       valuemember : 3
      //     },{
      //       displaymember : 'Rice',
      //       valuemember : 4
      //     }]

      //   return mockData
      // }

      // if (params['entity'] === 'location') {
      //   return mockData.setupLocations;
      // }

      if (params['entity'] === 'vehicleTypeDiscount') {
        params['entity'] = 'vehicleType';
      }

      // BF_SETUP_COMPANY = 1,
      // BF_SETUP_PERSON = 2,
      // BF_SETUP_HASHTAGS = 3,
      // BF_SEC_ROLE_MODULE_PERMISSION = 4,
      // BF_SEC_MODULE = 5,
      // BF_SETUP_CIRCLE = 6,
      // BF_SETUP_CIRCLE_DETAIL = 7,
      // BF_SETUP_COMMODITY = 8,
      // BF_SETUP_COMMODITY_FORM = 9,
      // BF_SETUP_COMMODITY_SPEC = 10,
      // BF_SETUP_COMMODITY_TYPE = 11,
      // BF_SETUP_COMPANY_ADDRESS = 12,
      // BF_SETUP_COMPANY_BANK_ADDRESS = 13,
      // BF_SETUP_COST_TYPE = 14,
      // BF_SETUP_CURRENCY = 15,
      // BF_SETUP_EXTERNAL_SHARING = 16,
      // BF_SETUP_LOCATION = 17,
      // BF_SETUP_PAYMENT_TERM = 18,
      // BF_SETUP_PERSON_ADDRESS = 19,
      // BF_SETUP_PERSON_BANK_ACCOUNT = 20,
      // BF_SETUP_PERSON_CIRCLE = 21,
      // BF_SETUP_PERSON_COMMODITY = 22,
      // BF_SETUP_STORAGE = 23,
      // BF_SETUP_STORAGE_LEVEL = 24,
      // BF_SETUP_TAX_DETAIL = 25,
      // BF_SETUP_TAX_HEADER = 26,
      // BF_SETUP_UOM = 27,
      // BF_SETUP_VEHICLE = 28

      const referenceTypeMap = {
        company: 1,
        commodity: 8,
        // commodityForm: 9,
        commoditySpec: 10,
        commodityType: 11,
        location: 17,
        uom: 27,
        currency: 15,
        paymentTerm: 18,
        vehicleType: 33,
        companyAffiliateType: 30,
        hashTags: 3,
        roleTeam: 'BF_SEC_ROLE',
        storage: 'BF_SETUP_STORAGE',
        commodityTypeMaster: 'BF_SETUP_COMMODITY_TYPE_MASTER',
        commodityForm: 'BF_SETUP_COMMODITY_FORM_MASTER',
        commodityResidue: 'BF_SETUP_COMMODITY_RESIDUE_MASTER',
        gstMaster: 'BF_STEUP_GST_MASTER',
        subscriptionPlan: 'BF_SETUP_SUBSCRIPTION_PLAN',
        qualitySpecs: 'BF_SETUP_QUALITY_SPEC',
        qsUom: 'BF_SETUP_UOM',
        equipmentType: 'BF_SETUP_EQUIPMENT_TYPE',
        equipmentCategory: 'BF_SETUP_EQUIPMENT_CATEGORY',
      };

      const backendFields = {
        Reference_Data_Enum: _.get(referenceTypeMap, params.entity, 0) || 0,
        Reference_Data_Operation_Enum: _.get(params, 'optype', 3),
        Entity_Id: _.get(params, 'entity_id', 0),
      };

      if (params.entity === 'vehicleType') {
        backendFields['Reference_Data_Operation_Enum'] = params?.optype ? params?.optype : 1;
      }

      if (params.entity === 'roleTeam') {
        backendFields['Reference_Data_Operation_Enum'] = 1;
      }

      const bodyParams = utils.getBackendParams(backendFields, params);

      // const qs = Object.keys(bodyParams)
      //   .map((key) => `${key}=${bodyParams[key]}`)
      //   .join('&');
      const qs = utils.getQs(bodyParams);

      const url = `${baseUrl}/BioFuelRegistration/api/ReferenceData/ReadReferenceData?${qs}`;
      const res = await utils.fetch(url).ready;

      let resData = await utils.processApiRes(res);

      if (params.entity === 'vehicleType' && params?.optype === 1) {
        resData = resData.map((item) => {
          return {
            displaymember: `${item['vehicle_type_code']}`,
            valuemember: item['vehicle_type_id'],
            displayMember: `${item['vehicle_type_code']}`,
            valueMember: item['vehicle_type_id'],
            display_member: `${item['vehicle_type_code']}`,
            value_member: item['vehicle_type_id'],
            maximum_capacity: item['maximum_capacity'],
          };
        });
      }

      if (params.entity === 'roleTeam') {
        resData = resData.map((item) => {
          return {
            displaymember: `${item['role_name']}`,
            valuemember: item['role_id'],
            displayMember: `${item['role_name']}`,
            valueMember: item['role_id'],
            display_member: `${item['role_name']}`,
            value_member: item['role_id'],
            display_member: `${item['role_name']}`,
            value_member: item['role_id'],
            display_member: `${item['role_name']}`,
            value_member: item['role_id'],
            display_member: `${item['role_name']}`,
            value_member: item['role_id'],
          };
        });
      }

      //triming values
      resData = resData.map((item) => {
        return {
          ...item,
          displaymember: `${item['display_member'] || ''}`.trim(),
          valuemember: parseInt(item['value_member']),
          displayMember: `${item['display_member'] || ''}`.trim(),
          valueMember: parseInt(item['value_member']),
          display_member: `${item['display_member'] || ''}`.trim(),
          value_member: parseInt(item['value_member']),
        };
      });

      //sorting values
      resData = _.orderBy(resData, ['display_member']);

      if (params.entity === 'roleTeam') {
        resData = _.orderBy(resData, ['value_member']);
      }

      return resData;
    } catch (err) {
      const errors = await utils.processApiErrors(err);

      throw errors;
    }
  };

  const getEnumData = async (params = {}) => {
    const backendFields = {
      Enum_Group: _.get(params, 'enumgroup', ''),
    };

    try {
      const bodyParams = utils.getBackendParams(backendFields, params);

      // const qs = Object.keys(bodyParams)
      //   .map((key) => `${key}=${bodyParams[key]}`)
      //   .join('&');
      const qs = utils.getQs(bodyParams);
      const url = `${baseUrl}/BioFuelRegistration/api/ReferenceData/GetEnumerator?${qs}`;
      const res = await utils.fetch(url).ready;

      let resData = await utils.processApiRes(res);
      //triming values
      resData = resData.map((item) => {
        return {
          displaymember: `${item['display_member'] || ''}`.trim(),
          valuemember: parseInt(item['value_member']),
          displayMember: `${item['display_member'] || ''}`.trim(),
          valueMember: parseInt(item['value_member']),
          display_member: `${item['display_member'] || ''}`.trim(),
          value_member: parseInt(item['value_member']),
          sequence: item['sequence'],
        };
      });

      //sorting values
      resData = _.orderBy(resData, 'sequence');

      return resData;
    } catch (err) {
      const errors = await utils.processApiErrors(err);

      throw errors;
    }
  };

  const getPaymentTermForEquipmentRental = async (params = {}) => {
    try {
      const url = `${baseUrl}/BioFuelRequirementOffers/api/EquipmentDetails/GetPaymentTermsForEquipment`;
      const res = await utils.fetch(url).ready;

      let resData = await utils.processApiRes(res);
      //triming values
      resData = resData.map((item) => {
        return {
          displaymember: `${item['payment_term_code'] || ''}`.trim(),
          valuemember: parseInt(item['payment_term_id']),
          display_member: `${item['payment_term_code'] || ''}`.trim(),
          value_member: parseInt(item['payment_term_id']),
        };
      });

      //sorting values
      resData = _.orderBy(resData, 'sequence');

      return resData;
    } catch (err) {
      const errors = await utils.processApiErrors(err);

      throw errors;
    }
  };

  const createRequirement = async (params) => {
    try {
      const backendFields = {
        commodity_req_offer_id: 0,
        commodity_id: 0,
        commodity_form_id: 0,
        commodity_residue_id: 0,
        qty: 0,
        qty_uom_id: 1,
        periodicity_enum: 'once = 1',
        from_date: '',
        to_date: '',
        min_qty: 0,
        total_qty: 0,
        expected_price: 0,
        price_currency_id: 0,
        payment_term_id: 0,
        additional_comments: 'string',
        person_id: 0,
        user_type_enum: 'enterprise = 1',
        address_id: 0,
        company_affiliate_enum: 'company = 1',
        closing_on: '',
        quality_measured_at_enum: 1,
        vehicle_type_id: 1,
        valid_until: '',
        ...params,
        commodity_req_offer_quality_spec: [
          // {
          //     "commodity_Req_Offer_Quality_Spec_Id": 0,
          //     "commodity_Spec_Id": 0,
          //     "not_Less_Than_Value": 0,
          //     "not_More_Than_Value": 0,
          //     "penalty_Trigger_Value": 0,
          //     "above_Below_Penalty_Enum": "Above = 1",
          //     "rejection_Trigger_Value": 0,
          //     "above_Below_Rejection_Enum": "Above = 1"
          // }
        ],
      };

      const bodyParams = utils.getBackendParams(backendFields, params);

      //TODO : tmp hard coding and changes remove later
      bodyParams.periodicity_enum = parseInt(bodyParams.periodicity_enum);
      bodyParams.commodity_form_id = parseInt(bodyParams.commodity_form_id);
      bodyParams.commodity_residue_id = parseInt(bodyParams.commodity_residue_id);

      bodyParams.total_qty = utils.caculateFinalQuantity(
        bodyParams.qty,
        bodyParams.periodicity_enum,
        bodyParams.from_date,
        bodyParams.to_date,
      );

      bodyParams.person_id = _.get(window, 'user.userid', 0);
      // bodyParams.delivery_location_id = 1584;
      // bodyParams.address_id = 1;
      // bodyParams.company_affiliate_enum = 1;

      bodyParams.user_type_enum = _.get(window, 'user.profile.person.user_type_enum') || 2;
      ///////////////////

      const acl = utils.getACL(_.get(window, 'user') || {});
      if (!acl.isCompany) {
        bodyParams['entity_id'] = bodyParams['person_id'];
      }

      // debugger;

      const url = `${baseUrl}/BioFuelRequirementOffers/api/Requirement/SaveRequirement_PhaseTwo`;

      const res = await utils.fetch(url, {
        method: 'POST',
        body: JSON.stringify(bodyParams),
      }).ready;

      const resData = await utils.processApiRes(res);

      return resData;
    } catch (err) {
      const errors = await utils.processApiErrors(err);

      throw errors;
    }
  };

  const editRequirement = async (params) => {
    try {
      const backendFields = {
        vehicle_type_id: 1, //todo : add this fields in ui when confirmed
        quality_measured_at_enum: 1, //todo : add this fields in ui when confirmed

        commodity_req_offer_id: 0,
        commodity_id: 0,
        commodity_form_id: 0,
        commodity_residue_id: 0,
        qty: 0,
        qty_uom_id: 0,
        periodicity_enum: 'once = 1',
        from_date: '',
        to_date: '',
        min_qty: 0,
        total_qty: 0,
        expected_price: 0,
        price_currency_id: 0,
        payment_term_id: 0,
        additional_comments: 'string',
        person_id: 0,
        user_type_enum: 'enterprise = 1',
        address_id: 0,
        company_affiliate_enum: 'company = 1',
        closing_on: '',
        valid_until: '',
        quality_measured_at_enum: 1,
        vehicle_type_id: 1,
        ...params,
        commodity_req_offer_quality_spec: [
          // {
          //     "commodity_Req_Offer_Quality_Spec_Id": 0,
          //     "commodity_Spec_Id": 0,
          //     "not_Less_Than_Value": 0,
          //     "not_More_Than_Value": 0,
          //     "penalty_Trigger_Value": 0,
          //     "above_Below_Penalty_Enum": "Above = 1",
          //     "rejection_Trigger_Value": 0,
          //     "above_Below_Rejection_Enum": "Above = 1"
          // }
        ],
      };

      const bodyParams = utils.getBackendParams(backendFields, params);

      //TODO : tmp hard coding and changes remove later
      bodyParams.periodicity_enum = parseInt(bodyParams.periodicity_enum);
      bodyParams.commodity_form_id = parseInt(bodyParams.commodity_form_id);
      bodyParams.commodity_residue_id = parseInt(bodyParams.commodity_residue_id);

      bodyParams.total_qty = utils.caculateFinalQuantity(
        bodyParams.qty,
        bodyParams.periodicity_enum,
        bodyParams.from_date,
        bodyParams.to_date,
      );

      bodyParams.person_id = _.get(window, 'user.userid', 0);
      // bodyParams.delivery_location_id = 1584;
      // bodyParams.address_id = 1;
      // bodyParams.company_affiliate_enum = 1;
      bodyParams.user_type_enum = _.get(window, 'user.profile.person.user_type_enum') || 2; ///////////////////

      const acl = utils.getACL(_.get(window, 'user') || {});
      if (!acl.isCompany) {
        bodyParams['entity_id'] = bodyParams['person_id'];
      }

      const url = `${baseUrl}/BioFuelRequirementOffers/api/Requirement/EditRequirement`;

      const res = await utils.fetch(url, {
        method: 'PUT',
        body: JSON.stringify(bodyParams),
      }).ready;

      const resData = await utils.processApiRes(res);

      return resData;
    } catch (err) {
      const errors = await utils.processApiErrors(err);

      throw errors;
    }
  };

  const createReqOffer = async (params, isOpenBuySell) => {
    const storeState = globalThis.store.getState();
    try {
      const backendFields = {
        person_id: 0,
        commodity_req_offer_id: 0,
        offer_bid_qty: 0,
        offer_bid_uom_id: 0,
        start_date: '',
        end_date: '',
        offer_bid_price: 0,
        // total_offer_bid_qty: 0,
        periodicity_enum: 0,
        address_id: 0,
        // company_affiliate_enum: 1, //TODO : remove hardcoding
        user_type_enum:
          _.get(params, 'user_type_enum') ||
          _.get(window, 'user.profile.person.user_type_enum') ||
          _.get(storeState, 'user.profile.person.user_type_enum') ||
          2,
        transportation_cost: 0,
        vehicle_type_id: 0,
        address_id: 0,
        ...params,
      };

      const bodyParams = utils.getBackendParams(backendFields, params);
      //TODO : tmp hard coding and changes remove later
      bodyParams.periodicity_enum = parseInt(bodyParams.periodicity_enum);

      bodyParams.total_offer_bid_qty = utils.caculateFinalQuantity(
        bodyParams.offer_bid_qty,
        bodyParams.periodicity_enum,
        bodyParams.start_date,
        bodyParams.end_date,
      );

      if (bodyParams?.special_buy_sell_type_enum !== 6) {
        bodyParams.person_id = _.get(window, 'user.userid', 0);
      }

      // bodyParams.delivery_location_id = 1584;
      // bodyParams.address_Id = 1; //TODO : remove hardcoding
      // bodyParams.company_affiliate_enum = 1; //TODO : remove hardcoding
      bodyParams.user_type_enum =
        _.get(bodyParams, 'user_type_enum') ||
        _.get(window, 'user.profile.person.user_type_enum') ||
        _.get(storeState, 'user.profile.person.user_type_enum') ||
        2;
      ///////////////////

      //start date and end date valid unit issues

      bodyParams['open_buy_sell_valid_until'] =
        bodyParams['open_buy_sell_valid_until'] &&
        dayjs(bodyParams['open_buy_sell_valid_until']).format('YYYY-MM-DDTHH:mm:ssZ[Z]');
      bodyParams['start_date'] = dayjs(bodyParams['start_date']).format('YYYY-MM-DD');
      bodyParams['end_date'] = dayjs(bodyParams['end_date']).format('YYYY-MM-DD');

      const acl = utils.getACL(_.get(window, 'user') || {});
      if (!acl.isCompany) {
        bodyParams['entity_id'] = bodyParams['person_id'];
      }

      let url;
      if (isOpenBuySell) {
        url = `${baseUrl}/BioFuelRequirementOffers/api/OpenBuySell/SaveOffer_Eloc_PhaseTwo`;
      } else {
        url = `${baseUrl}/BioFuelRequirementOffers/api/Offer/SaveOffer_ELOC_PhaseTwo`;
      }

      const res = await utils.fetch(url, {
        method: 'POST',
        body: JSON.stringify(bodyParams),
      }).ready;

      const resData = await utils.processApiRes(res);

      return resData;
    } catch (err) {
      console.log('createReqOffer--->err', err);

      const errors = await utils.processApiErrors(err);

      throw errors;
    }
  };

  const updateReqOffer = async (params, isOpenBuySell) => {
    try {
      const backendFields = {
        person_id: 0,
        commodity_req_offer_id: 0,
        commodity_req_offer_response_id: 0,
        offer_bid_qty: 0,
        offer_bid_uom_id: 0,
        start_date: '',
        end_date: '',
        offer_bid_price: 0,
        // total_offer_bid_qty: 0,
        periodicity_enum: 0,
        address_id: 0,
        // company_affiliate_enum: 1, //todo : remove hardcoding
        user_type_enum: _.get(params, 'user_type_enum') || _.get(window, 'user.profile.person.user_type_enum') || 2,
        transportation_cost: 0,
        vehicle_type_id: 0,
        address_id: 0,
        ...params,
      };

      const bodyParams = utils.getBackendParams(backendFields, params);

      //TODO : tmp hard coding and changes remove later
      bodyParams.periodicity_enum = parseInt(bodyParams.periodicity_enum);

      bodyParams.total_offer_bid_qty = utils.caculateFinalQuantity(
        bodyParams.offer_bid_qty,
        bodyParams.periodicity_enum,
        bodyParams.start_date,
        bodyParams.end_date,
      );

      if (bodyParams?.special_buy_sell_type_enum !== 6) {
        bodyParams.person_id = _.get(window, 'user.userid', 0);
      }
      // bodyParams.delivery_location_id = 1584;
      // bodyParams.address_Id = 1; //TODO : remove hardcoding
      // bodyParams.company_affiliate_enum = 1; //TODO : remove hardcoding
      bodyParams.user_type_enum =
        _.get(bodyParams, 'user_type_enum') || _.get(window, 'user.profile.person.user_type_enum') || 2; //TODO : remove hardcoding
      ///////////////////

      //start date and end date valid unit issues
      bodyParams['open_buy_sell_valid_until'] =
        bodyParams['open_buy_sell_valid_until'] &&
        dayjs(bodyParams['open_buy_sell_valid_until']).format('YYYY-MM-DDTHH:mm:ssZ[Z]');
      bodyParams['start_date'] = dayjs(bodyParams['start_date']).format('YYYY-MM-DD');
      bodyParams['end_date'] = dayjs(bodyParams['end_date']).format('YYYY-MM-DD');

      const acl = utils.getACL(_.get(window, 'user') || {});
      if (!acl.isCompany) {
        bodyParams['entity_id'] = bodyParams['person_id'];
      }

      let url;
      if (isOpenBuySell) {
        url = `${baseUrl}/BioFuelRequirementOffers/api/OpenBuySell/EditOffer`;
      } else {
        url = `${baseUrl}/BioFuelRequirementOffers/api/Offer/EditOffer_PhaseTwo`;
      }

      const res = await utils.fetch(url, {
        method: 'PUT',
        body: JSON.stringify(bodyParams),
      }).ready;

      const resData = await utils.processApiRes(res);

      return resData;
    } catch (err) {
      console.log('EditOffer--->err', err);

      const errors = await utils.processApiErrors(err);

      throw errors;
    }
  };

  const editAutoOffer = async (params, id) => {
    let newparams = {
      ...params,
      user_type_enum: _.get(window, 'user.profile.person.user_type_enum') || 1,
      company_affiliate_enum: 1,
      person_id: _.get(window, 'user.userid') || 0,
    };
    const qs = utils.getQs({ buy_sell_compatibility_matrix_id: id });
    try {
      const url = `${baseUrl}/BioFuelRequirementOffers/api/AutoBidOffer/EditAutoBidOffer?${qs}`;

      const res = await utils.fetch(url, {
        method: 'PUT',
        body: JSON.stringify(newparams),
      }).ready;

      const resData = await utils.processApiRes(res);
      return resData;
    } catch (err) {
      const errors = await utils.processApiErrors(err);

      throw errors;
    }
  };
  const updateSellFromDerivedOffer = async (params) => {
    try {
      const qs = utils.getQs(params);
      const url = `${baseUrl}/BioFuelRequirementOffers/api/AutoBidOffer/UpdateSellFromDerivedOffer?${qs}`;
      const res = await utils.fetch(url, {
        method: 'PUT',
      }).ready;
      const resData = await utils.processApiRes(res);
      return resData;
    } catch (err) {
      const errors = await utils.processApiErrors(err);
      throw errors;
    }
  };

  const createBuySellFromOfferBid = async (params) => {
    try {
      const qs = utils.getQs(params);
      const url = `${baseUrl}/BioFuelRequirementOffers/api/AutoBidOffer/CreateBuySellFromOfferBid?${qs}`;
      const res = await utils.fetch(url, {
        method: 'PUT',
      }).ready;
      const resData = await utils.processApiRes(res);
      return resData;
    } catch (err) {
      const errors = await utils.processApiErrors(err);
      throw errors;
    }
  };
  const publishRequirement = async (id = 0, indentIds, newParams) => {
    try {
      let backendFields = {
        Commodity_Req_Offer_Id: id,
        Person_Id: _.get(window, 'user.userid', 0), // TODO remove hardcoding
      };
      if (newParams?.['indentString'] === 'IndentForm') {
        if (indentIds?.length > 0) {
          backendFields = {
            Commodity_Req_Offer_Id: id,
            Person_Id: _.get(window, 'user.userid', 0), // TODO remove hardcoding
            // indent_commodity_req_offer_ids: [indentIds],
            has_attachment: newParams?.has_attachment,
          };
        }
      } else {
        if (indentIds?.length > 0) {
          if (newParams?.['indentString'] === 'converttobuy') {
            backendFields = {
              Commodity_Req_Offer_Id: id,
              Person_Id: _.get(window, 'user.userid', 0), // TODO remove hardcoding
              indent_commodity_req_offer_ids: [indentIds],
            };
          } else {
            backendFields = {
              Commodity_Req_Offer_Id: id,
              Person_Id: _.get(window, 'user.userid', 0), // TODO remove hardcoding
              // indent_commodity_req_offer_ids: [indentIds],
            };
          }
        }
      }

      const bodyParams = backendFields;

      // const qs = Object.keys(bodyParams)
      //   .map((key) => `${key}=${bodyParams[key]}`)
      //   .join('&');
      const qs = utils.getQs(bodyParams);
      // const qs = Object.keys(bodyParams)
      // .map((key) => {
      //   if (key == 'indent_commodity_req_offer_ids') {
      //     return `${key}=[${bodyParams[key]}]`
      //   }
      //   return `${key}=${bodyParams[key]}`
      // })
      // .join('&');

      // const bodyParams = utils.getBackendParams(backendFields, params);

      const url = `${baseUrl}/BioFuelRequirementOffers/api/Requirement/PublishRequirment_PhaseTwo?${qs}`;

      const res = await utils.fetch(url, {
        method: 'PUT',
      }).ready;

      const resData = await utils.processApiRes(res);

      return resData;
    } catch (err) {
      const errors = await utils.processApiErrors(err);

      throw errors;
    }
  };

  const publishOpenBuy = async (id = 0, indentIds, newParams) => {
    try {
      let backendFields = {
        Commodity_Req_Offer_Id: id,
        Person_Id: _.get(window, 'user.userid', 0), // TODO remove hardcoding
      };

      if (newParams['indentString'] === 'IndentForm') {
        if (indentIds.length > 0) {
          backendFields = {
            Commodity_Req_Offer_Id: id,
            Person_Id: _.get(window, 'user.userid', 0), // TODO remove hardcoding
            indent_commodity_req_offer_ids: [indentIds],
            has_attachment: newParams.has_attachment,
          };
        }
      } else {
        if (indentIds.length > 0) {
          if (newParams['indentString'] === 'converttobuy') {
            backendFields = {
              Commodity_Req_Offer_Id: id,
              Person_Id: _.get(window, 'user.userid', 0), // TODO remove hardcoding
              indent_commodity_req_offer_ids: [indentIds],
            };
          } else {
            backendFields = {
              Commodity_Req_Offer_Id: id,
              Person_Id: _.get(window, 'user.userid', 0), // TODO remove hardcoding
              // indent_commodity_req_offer_ids: [indentIds],
            };
          }
        }
      }

      const bodyParams = backendFields;

      // const qs = Object.keys(bodyParams)
      //   .map((key) => `${key}=${bodyParams[key]}`)
      //   .join('&');
      const qs = utils.getQs(bodyParams);
      // const bodyParams = utils.getBackendParams(backendFields, params);

      const url = `${baseUrl}/BioFuelRequirementOffers/api/OpenBuySell/PublishRequirment_PhaseTwo?${qs}`;

      const res = await utils.fetch(url, {
        method: 'PUT',
      }).ready;

      const resData = await utils.processApiRes(res);

      return resData;
    } catch (err) {
      const errors = await utils.processApiErrors(err);

      throw errors;
    }
  };

  const followUnfollowRequirement = async (id = 0, follow = true) => {
    try {
      const backendFields = {
        Commodity_Req_Offer_Id: id,
        Follow_UnFollow_Enum: follow ? 1 : 2,
        Person_Id: _.get(window, 'user.userid', 0), // TODO remove hardcoding
        User_Type_Enum: _.get(window, 'user.profile.person.user_type_enum') || 2, // TODO remove hardcoding
      };

      const bodyParams = backendFields;

      // const qs = Object.keys(bodyParams)
      //   .map((key) => `${key}=${bodyParams[key]}`)
      //   .join('&');
      const qs = utils.getQs(bodyParams);
      // const bodyParams = utils.getBackendParams(backendFields, params);

      const url = `${baseUrl}/BioFuelRequirementOffers/api/Requirement/FollowUnfollowRequirement?${qs}`;

      const res = await utils.fetch(url, {
        method: 'PUT',
      }).ready;

      const resData = await utils.processApiRes(res);

      return resData;
    } catch (err) {
      const errors = await utils.processApiErrors(err);

      throw errors;
    }
  };

  const withdrawRequirement = async (id = 0) => {
    try {
      const backendFields = {
        Commodity_Req_Offer_Id: id,
        // Follow_UnFollow_Enum : follow ? 1 : 2,
        Person_Id: _.get(window, 'user.userid', 0), // TODO remove hardcoding
        // User_Type_Enum: 2, // TODO remove hardcoding
      };

      const bodyParams = backendFields;

      // const qs = Object.keys(bodyParams)
      //   .map((key) => `${key}=${bodyParams[key]}`)
      //   .join('&');
      const qs = utils.getQs(bodyParams);
      // const bodyParams = utils.getBackendParams(backendFields, params);

      const url = `${baseUrl}/BioFuelRequirementOffers/api/Requirement/WithdrawRequirementById?${qs}`;

      const res = await utils.fetch(url, {
        method: 'PUT',
      }).ready;

      const resData = await utils.processApiRes(res);

      return resData;
    } catch (err) {
      const errors = await utils.processApiErrors(err);

      throw errors;
    }
  };

  const getAllRequirements = async (params = {}) => {
    try {
      // TODO : get user id from window for
      // all apis

      // debugger;

      const backendFields = {
        Address_Id: 1, // TODO remove hardcoding
        Page_Size: 5000, // TODO remove hardcoding
        User_Type_Enum: _.get(window, 'user.profile.person.user_type_enum') || 2, // TODO remove hardcoding
        Offset: 0, // TODO remove hardcoding
        Entity_Id: _.get(window, 'user.userid', 0), // TODO remove hardcoding
      };

      const bodyParams = utils.getBackendParams(backendFields, params);

      // const qs = Object.keys(bodyParams)
      //   .map((key) => `${key}=${bodyParams[key]}`)
      //   .join('&');
      const qs = utils.getQs(bodyParams);
      const url = `${baseUrl}/BioFuelRequirementOffers/api/Requirement/FetchAllRequirementWithinCircle?${qs}`;

      const res = await utils.fetch(url).ready;

      const resData = await utils.processApiRes(res);

      return resData;
    } catch (err) {
      console.log('getAllRequirements->', err);

      const errors = await utils.processApiErrors(err);

      throw errors;
    }
  };

  const getUserRequirements = async (params = {}) => {
    try {
      // TODO : get user id from window for
      // all apis

      // debugger;

      const backendFields = {
        Address_Id: 1, // TODO remove hardcoding
        Company_Affiliate_Enum: 1, // TODO remove hardcoding
        Page_Size: 1000,
        User_Type_Enum: _.get(window, 'user.profile.person.user_type_enum') || 2, // TODO remove hardcoding
        Offset: 0,
        Entity_Id: _.get(window, 'user.userid', 0),
      };

      const bodyParams = utils.getBackendParams(backendFields, params);

      // const qs = Object.keys(bodyParams)
      //   .map((key) => `${key}=${bodyParams[key]}`)
      //   .join('&');
      const qs = utils.getQs(bodyParams);
      const url = `${baseUrl}/BioFuelRequirementOffers/api/Requirement/FetchAllRequirementAgainstEntityId?${qs}`;

      const res = await utils.fetch(url).ready;

      const resData = await utils.processApiRes(res);

      return resData;
    } catch (err) {
      console.log('getAllRequirements->', err);

      //TODO : change variable name to errData..it alrealy has key errors
      const errors = await utils.processApiErrors(err);

      // debugger;
      //TODO : tmp fix - returning empty array if no specs
      if (_.get(errors, 'errors.entity_Id', '').includes('No requirements present')) {
        return [];
      }

      throw errors;
    }
  };

  const getRequirementDetails = async (id = 0, selectedAddressId = 0) => {
    // TODO : get user id from window for
    // all apis

    // debugger;

    const backendFields = {
      Commodity_Req_Offer_Id: id,
      Person_Id: _.get(window, 'user.userid', 0), //TODO remove hardcoding
      source_address_id: selectedAddressId,
    };

    const bodyParams = backendFields;

    // const qs = Object.keys(bodyParams)
    //   .map((key) => `${key}=${bodyParams[key]}`)
    //   .join('&');
    const qs = utils.getQs(bodyParams);
    // TODO confirm if commodity_Req_Offer_Quality_Spec data
    // will be present in FetchRequirementAgainstId
    // currently it appears to be there
    ///////////////////////////////////////////////////////////
    // const url = `${baseUrl}/BioFuelRequirementOffers/api/Requirement/FetchRequirementDetailsAgainstId?${qs}`;

    // let specsData = []

    // try{
    //   const url = `${baseUrl}/BioFuelRequirementOffers/api/Requirement/FetchRequirementDetailsAgainstId?${qs}`;
    //   const res = await utils.fetch(url).ready;

    //   specsData = await utils.processApiRes(res);
    // }catch(err){
    //   const errors = await utils.processApiErrors(err);

    //   console.log('error in FetchRequirementDetailsAgainstId ->', errors)

    // }

    // debugger;
    ///////////////////////////////////////////////////////////

    try {
      const url = `${baseUrl}/BioFuelRequirementOffers/api/Requirement/FetchRequirementAgainstId_ELOC?${qs}`;

      const res = await utils.fetch(url).ready;

      const resData = await utils.processApiRes(res);

      //TODO : deleting is_Followed_By_Person key as it gives false cause of disable api token
      // remove hack later
      // delete resData['is_Followed_By_Person'];
      // delete resData['isfollowedbyperson'];

      const finalRespose = {
        ...resData,
        // ...specsData,
      };

      /*
      //TODO : remove after api fix
      let specs = resData || [];

      if (_.isArray(specs)) {
        specs = specs.map((item) => {
          if (item['not_More_Than_Value'] === undefined && item['note_More_Than_Value'] !== undefined) {
            item['not_More_Than_Value'] = item['note_More_Than_Value'];
          }

          if (!item['above_Below_Penalty_Code']) {
            item['above_Below_Penalty_Code'] = item['above_Below_Penalty_Enum'] === 1 ? 'Above' : 'Below';
          }

          if (!item['above_Below_Rejection_Code']) {
            item['above_Below_Rejection_Code'] = item['above_Below_Rejection_Enum'] === 1 ? 'Above' : 'Below';
          }

          return item;
        });
      }
      */

      //TODO : deleting is_Followed_By_Person key as it gives false cause of disable api token
      // remove hack later
      // delete resData['is_Followed_By_Person'];
      // delete resData['isfollowedbyperson'];

      return finalRespose;
    } catch (err) {
      console.log('getRequirementDetails->', err);

      //TODO : change variable name to errData..it alrealy has key errors
      const errors = await utils.processApiErrors(err);
      throw errors;
    }
  };

  // TODO : replace this with getReferenceData whereever it's used
  const getCompanies = async () => {
    try {
      // let url = `${baseUrl}/BioFuelRegistration/api/ReferenceData/GetAllCompanies`
      const params = {
        Reference_Data_Enum: 1,
        Reference_Data_Operation_Enum: 1,
        Entity_Id: 1,
      };
      const qs = utils.getQs(params);
      // const qs = 'Reference_Data_Enum=1&Reference_Data_Operation_Enum=1&Entity_Id=1';
      const url = `${baseUrl}/BioFuelRegistration/api/ReferenceData/ReadReferenceData?${qs}`;

      const res = await utils.fetch(url).ready;

      const resData = await utils.processApiRes(res);

      return resData;
    } catch (err) {
      const errors = await utils.processApiErrors(err);

      throw errors;
    }
  };

  const getLocations = async (locText = '') => {
    try {
      // await utils.wait(2);
      // return mockData.locations;
      //TODO : remove later
      // return mockData.locations;

      let locPrefix = locText || '';

      // locPrefix = locText.trim().slice(0, 3).toLowerCase();

      // // console.log('\n\n---->locPrefix->', locPrefix)
      // // console.log('\n\n---->locPrefix->length', locPrefix.length)

      // if (locPrefix.length < 3) {
      //   throw new Error({
      //     message: 'Location length should be minium 3 characters',
      //   });
      // }

      const url = `${baseUrl}/BioFuelRegistration/api/ReferenceData/GetBootstrapLocationData?Location_Prefix=${locPrefix}`;
      const res = await utils.fetch(url).ready;

      const resData = await utils.processApiRes(res);

      return resData;
    } catch (err) {
      const errors = await utils.processApiErrors(err);

      throw errors;
    }
  };

  // const getSocialFeed = async (params) => {
  //   try {
  //     const backendFields = {
  //       person_id: 4,
  //       offset: 0,
  //       pageSize: 100,
  //     };

  //     const bodyParams = utils.getBackendParams(backendFields, params);

  //     const url = `${baseUrl}/BioFuelSocialFeed/api/SocialFeed/FetchSocialFeed`;

  //     const res = await utils.fetch(url, {
  //       body: JSON.stringify(bodyParams),
  //     }).ready;

  //     const resData = await utils.processApiRes(res);

  //     return resData;
  //   } catch (err) {
  //     console.log('getSocialFeed->', err);

  //     const errors = await utils.processApiErrors(err);

  //     throw errors;
  //   }
  // };

  const getSocialFeed = async (params) => {
    try {
      const backendFields = {
        Person_Id: 0,
        Offset: 0,
        Page_Size: 100,
      };

      const bodyParams = utils.getBackendParams(backendFields, params);

      // const qs = Object.keys(bodyParams)
      //   .map((key) => `${key}=${bodyParams[key]}`)
      //   .join('&');
      const qs = utils.getQs(bodyParams);
      const url = `${baseUrl}/BioFuelSocialFeed/api/SocialFeed/FetchSocialFeed?${qs}`;

      const res = await utils.fetch(url).ready;

      const resData = await utils.processApiRes(res);

      return resData;
    } catch (err) {
      console.log('getSocialFeed->', err);

      const errors = await utils.processApiErrors(err);

      throw errors;
    }
  };

  const getAllFeed = async (params) => {
    try {
      const backendFields = {
        Person_Id: _.get(window, 'user.userid', 0),
        Offset: 0,
        Page_Size: 100,
        // Post_Type_Enum: 1,
      };

      const bodyParams = utils.getBackendParams(backendFields, params);

      // const qs = Object.keys(bodyParams)
      //   .map((key) => `${key}=${bodyParams[key]}`)
      //   .join('&');
      const qs = utils.getQs(bodyParams);
      const url = `${baseUrl}/BioFuelSocialFeed/api/SocialFeed/FetchAllFeed?${qs}`;

      const res = await utils.fetch(url).ready;

      const resData = await utils.processApiRes(res);

      return resData;
    } catch (err) {
      console.log('getAllFeed->', err);

      const errors = await utils.processApiErrors(err);

      throw errors;
    }
  };

  const getSavedPosts = async (params) => {
    try {
      const backendFields = {
        Person_Id: _.get(window, 'user.userid', 0),
        Offset: 0,
        Page_Size: 100,
      };

      const bodyParams = utils.getBackendParams(backendFields, params);

      // const qs = Object.keys(bodyParams)
      //   .map((key) => `${key}=${bodyParams[key]}`)
      //   .join('&');
      const qs = utils.getQs(bodyParams);
      const url = `${baseUrl}/BioFuelSocialFeed/api/SocialFeed/FetchSavedPosts?${qs}`;

      const res = await utils.fetch(url).ready;

      const resData = await utils.processApiRes(res);

      return resData;
    } catch (err) {
      console.log('getAllFeed->', err);

      const errors = await utils.processApiErrors(err);

      throw errors;
    }
  };

  const getTimelinePost = async (params) => {
    try {
      const backendFields = {
        To_Person_Id: _.get(window, 'user.userid', 0),
        Invoking_Person_Id: _.get(window, 'user.userid', 0),
        Page_size: 0,
        Offset: 0,
      };
      const bodyParams = utils.getBackendParams(backendFields, params);

      // const qs = Object.keys(bodyParams)
      //   .map((key) => `${key}=${bodyParams[key]}`)
      //   .join('&');
      const qs = utils.getQs(bodyParams);
      const url = `${baseUrl}/BioFuelSocialFeed/api/SocialFeed/FetchTimelinePost?${qs}`;

      const res = await utils.fetch(url).ready;

      const resData = await utils.processApiRes(res);

      return resData;
    } catch (err) {
      console.log('getAllFeed->', err);

      const errors = await utils.processApiErrors(err);

      throw errors;
    }
  };

  const fetchUserProfile = async (sid) => {
    try {
      const backendFields = {
        social_profile_id: sid,
        from_Person_Id: _.get(window, 'user.userid', 0),
      };

      // const bodyParams = utils.getBackendParams(backendFields, params);
      const bodyParams = backendFields;
      // const qs = Object.keys(bodyParams)
      //   .map((key) => `${key}=${bodyParams[key]}`)
      //   .join('&');
      const qs = utils.getQs(bodyParams);

      const url = `${baseUrl}/BioFuelSocialFeed/api/SocialFeed/FetchUserProfile_ELOC?${qs}`;

      const res = await utils.fetch(url).ready;

      const resData = await utils.processApiRes(res);

      return resData;
    } catch (err) {
      console.log('fetchUserProfile->', err);

      const errors = await utils.processApiErrors(err);

      throw errors;
    }
  };

  const fetchPersonalDetails = async (params = {}) => {
    try {
      const backendFields = {
        Person_Id: _.get(window, 'user.userid', 0),
      };

      const bodyParams = utils.getBackendParams(backendFields, params);

      // const qs = Object.keys(bodyParams)
      //   .map((key) => `${key}=${bodyParams[key]}`)
      //   .join('&');
      const qs = utils.getQs(bodyParams);

      const url = `${baseUrl}/BioFuelRegistration/api/UserProfile/FetchPersonalDetails_ELOC?${qs}`;

      const res = await utils.fetch(url).ready;

      const resData = await utils.processApiRes(res);

      return resData;
    } catch (err) {
      console.log('fetchUserProfile->', err);

      const errors = await utils.processApiErrors(err);

      throw errors;
    }
  };

  const fetchOrganizationDetails = async (params = {}) => {
    try {
      const backendFields = {
        Person_Id: _.get(window, 'user.userid', 0),
      };

      const bodyParams = utils.getBackendParams(backendFields, params);

      // const qs = Object.keys(bodyParams)
      //   .map((key) => `${key}=${bodyParams[key]}`)
      //   .join('&');
      const qs = utils.getQs(bodyParams);
      const url = `${baseUrl}/BioFuelRegistration/api/BusinessProfile/FetchBusinessProfileDetails_ELOC?${qs}`;

      const res = await utils.fetch(url).ready;

      const resData = await utils.processApiRes(res);

      return resData;
    } catch (err) {
      console.log('fetchUserProfile->', err);

      const errors = await utils.processApiErrors(err);

      throw errors;
    }
  };

  const createSocialPost = async (postParams) => {
    try {
      // debugger;

      const formData = new FormData();
      // formData.append("person_id", 4);

      //TODO : tmp - removing html tags
      // postParams.text = postParams.text.replace(/(<([^>]+)>)/gi, '');

      // postParams.text = postParams.text.replace(/'/gi, '');
      // postParams.text = postParams.text.replace(/"/gi, '');
      // return;
      formData.append('Post_MetaData[post_Caption]', (postParams.text || '').trim());
      formData.append('Post_MetaData[posted_by_person_id]', postParams.userid);
      formData.append('Post_MetaData[post_classification_enum]', 1);
      formData.append('Post_Type_Enum', 1);
      formData.append('Post_Category', 1);
      formData.append('HashTag_Ids[0]', 1);
      formData.append('HashTag_Ids[1]', 2);

      for (let i = 0; i < postParams.images.length; i += 1) {
        formData.append('Request_Images', postParams.images[i]);
      }

      // formData.append('Cover_Image', postParams.images[0]);

      const url = `${baseUrl}/BioFuelCreatePost/api/CreatePost/CreateSocialPost`;

      const res = await utils.fetch(
        url,
        {
          method: 'POST',
          body: formData,
        },
        false,
      ).ready;

      const resData = await utils.processApiRes(res);

      return resData;
    } catch (err) {
      console.log('createSocialPost->', err);

      const errors = await utils.processApiErrors(err);

      throw errors;
    }
  };

  const followUnfollowUser = async (pid, isFollow, companyId) => {
    try {
      let backendFields = {};
      if (!companyId) {
        backendFields = {
          from_Person_Id: _.get(window, 'user.userid', 0),
          to_Person_Id: pid,
          follow: isFollow,
          to_Company_Id: null,
        };
      }

      if (companyId) {
        backendFields = {
          from_Person_Id: _.get(window, 'user.userid', 0),
          to_Person_Id: null,
          follow: isFollow,
          to_Company_Id: companyId,
        };
      }

      // const bodyParams = utils.getBackendParams(backendFields, params);
      const bodyParams = backendFields;
      const url = `${baseUrl}/BioFuelSocialFeed/api/SocialFeed/FollowUnfollowUser`;

      const res = await utils.fetch(url, {
        method: 'POST',
        body: JSON.stringify(bodyParams),
      }).ready;

      const resData = await utils.processApiRes(res);

      return resData;
    } catch (err) {
      const errors = await utils.processApiErrors(err);
      console.log('followUnfollowUser-->', err);
      throw errors;
    }
  };

  const deletePostFromTimeline = async (params) => {
    try {
      const backendFields = {
        post_id: 0,
        deleting_person_id: 0,
        reason: 'Test Delete',
      };

      const bodyParams = utils.getBackendParams(backendFields, params);

      // const qs = Object.keys(bodyParams)
      //   .map((key) => `${key}=${bodyParams[key]}`)
      //   .join('&');
      const qs = utils.getQs(bodyParams);
      // bodyParams.deleting_person_id = _.get(window, 'user.userid', 0);

      // debugger;

      const url = `${baseUrl}/BioFuelSocialFeed/api/SocialFeed/DeletePostFromTimeline?${qs}`;

      const res = await utils.fetch(url, {
        method: 'PUT',
        body: JSON.stringify(bodyParams),
      }).ready;

      const resData = await utils.processApiRes(res);

      return resData;
    } catch (err) {
      const errors = await utils.processApiErrors(err);

      throw errors;
    }
  };

  const savePost = async (post_id, isSaved) => {
    try {
      const backendFields = {
        post_id: post_id,
        person_id: _.get(window, 'user.userid', 0),
      };

      // const bodyParams = utils.getBackendParams(backendFields, params);
      const bodyParams = backendFields;

      const url = `${baseUrl}/BioFuelCreatePost/api/CreatePost/SavePost`;

      const res = await utils.fetch(url, {
        method: 'POST',
        body: JSON.stringify(bodyParams),
      }).ready;

      const resData = await utils.processApiRes(res);

      return resData;
    } catch (err) {
      const errors = await utils.processApiErrors(err);

      throw errors;
    }
  };

  const deleteSavePost = async (post_id, isSaved) => {
    try {
      const backendFields = {
        post_id: post_id,
        person_id: _.get(window, 'user.userid', 0),
      };

      // const bodyParams = utils.getBackendParams(backendFields, params);
      const bodyParams = backendFields;

      // const qs = Object.keys(bodyParams)
      //   .map((key) => `${key}=${bodyParams[key]}`)
      //   .join('&');
      const qs = utils.getQs(bodyParams);
      const url = `${baseUrl}/BioFuelSocialFeed/api/SocialFeed/DeleteSavedPost?${qs}`;

      const res = await utils.fetch(url, {
        method: 'PUT',
        // body: JSON.stringify(bodyParams),
      }).ready;

      const resData = await utils.processApiRes(res);

      return resData;
    } catch (err) {
      const errors = await utils.processApiErrors(err);

      throw errors;
    }
  };

  const deleteSavedComment = async (comment_id, isSaved) => {
    try {
      const backendFields = {
        comment_id: comment_id,
        person_id: _.get(window, 'user.userid', 0),
      };
      const bodyParams = backendFields;
      // const qs = Object.keys(bodyParams)
      //   .map((key) => `${key}=${bodyParams[key]}`)
      //   .join('&');
      const qs = utils.getQs(bodyParams);
      const url = `${baseUrl}/BioFuelSocialFeed/api/SocialFeed/DeleteComment?${qs}`;
      const res = await utils.fetch(url, {
        method: 'PUT',
      }).ready;
      const resData = await utils.processApiRes(res);
      console.log('api.js', resData);
      return resData;
    } catch (err) {
      const errors = await utils.processApiErrors(err);
      throw errors;
    }
  };

  const editPost = async (editparams) => {
    try {
      const formData = new FormData();
      //TODO : tmp - removing html tags
      // editparams.text = editparams.text.replace(/(<([^>]+)>)/gi, '');

      // editparams.text = editparams.text.replace(/'/gi, '');
      // editparams.text = editparams.text.replace(/"/gi, '');

      formData.append('Post_MetaData[Caption]', (editparams.text || '').trim());
      formData.append('Post_MetaData[Person_Id]', editparams.userid);
      formData.append('Post_MetaData[Post_Id]', editparams.post_id);
      // formData.append('Post_MetaData[Post_Images]', editparams.images);
      formData.append('Post_MetaData[Existing_Cover_Images_Count]', 0);
      formData.append('Post_MetaData[Post_Hashtags]', '[]');
      formData.append('Post_MetaData[Existing_Post_Images_Count]', 0);
      formData.append('Post_MetaData[Existing_Hashtag_Count]', 0);

      let Post_Images = [];
      for (let image of editparams.images) {
        // Post_Images = editparams.postimage

        let imgObj = {
          post_Image_Id: 0,
          image_url: image.name,
          image_Type_Enum: 1,
          is_Added: 1,
          is_Deleted: 0,
        };

        Post_Images.push(imgObj);
        // Post_Images.pop(delImgObj);
        formData.append('Request_Images', image);
      }

      // for(let img of editparams.deleteImages){
      //   let delImgObj = {
      //     post_Image_Id: 79,
      //     image_url: img,
      //     image_Type_Enum: 1,
      //     is_Added: 0,
      //     is_Deleted: 1,
      //   };
      // }

      formData.append('Post_MetaData[Post_Images]', JSON.stringify(Post_Images || []));

      const url = `${baseUrl}/BioFuelCreatePost/api/CreatePost/EditPost`;

      const res = await utils.fetch(
        url,
        {
          method: 'PUT',
          body: formData,
        },
        false,
      ).ready;

      const resData = await utils.processApiRes(res);

      return resData;
    } catch (err) {
      const errors = await utils.processApiErrors(err);

      throw errors;
    }
  };

  const reportPostOrComment = async (params) => {
    // debugger;
    try {
      const backendFields = {
        entity_Id: 0,
        post_Or_Comment_Type_Enum: 1,
        reported_Reason: '',
        reported_By_Person_Id: 0,
        entity_Created_By_Person_Id: 0,
      };

      const bodyParams = utils.getBackendParams(backendFields, params);

      const url = `${baseUrl}/BioFuelSocialFeed/api/SocialFeed/ReportPostOrComment`;

      const res = await utils.fetch(url, {
        method: 'POST',

        body: JSON.stringify(bodyParams),
      }).ready;

      const resData = await utils.processApiRes(res);

      return resData;
    } catch (err) {
      const errors = await utils.processApiErrors(err);

      throw errors;
    }
  };

  const likePost = async (postId, isLiked) => {
    try {
      const backendFields = {
        entity_Id: postId,
        liked_Person_Id: _.get(window, 'user.userid', 0),
        like_unlike_enum: 1,
        Post_Or_Comment_Type_Enum: 1,
        Is_Retract: !isLiked,
      };

      // const bodyParams = utils.getBackendParams(backendFields, params);
      const bodyParams = backendFields;

      const url = `${baseUrl}/BioFuelSocialFeed/api/SocialFeed/CreateReactions`;

      const res = await utils.fetch(url, {
        method: 'POST',
        body: JSON.stringify(bodyParams),
      }).ready;

      const resData = await utils.processApiRes(res);

      return resData;
    } catch (err) {
      console.log('likePost--->Err', err);

      const errors = await utils.processApiErrors(err);

      throw errors;
    }
  };
  const likeComment = async (postCommentId, isLiked) => {
    try {
      const backendFields = {
        entity_Id: postCommentId,
        liked_Person_Id: _.get(window, 'user.userid', 0),
        like_unlike_enum: 1,
        Post_Or_Comment_Type_Enum: 2,
        Is_Retract: !isLiked,
      };

      // const bodyParams = utils.getBackendParams(backendFields, params);
      const bodyParams = backendFields;

      const url = `${baseUrl}/BioFuelSocialFeed/api/SocialFeed/CreateReactions`;

      const res = await utils.fetch(url, {
        method: 'POST',
        body: JSON.stringify(bodyParams),
      }).ready;

      const resData = await utils.processApiRes(res);

      return resData;
    } catch (err) {
      console.log('likeComment--->Err', err);

      const errors = await utils.processApiErrors(err);

      throw errors;
    }
  };

  const getSpecificParticipant = async (params) => {
    try {
      let url;
      if (params.isQualityProfile) {
        url = `${baseUrl}/BioFuelRegistration/api/DiscountScheme/FetchAllEntityAgainstTypeOfIndividualEnum?type_of_individual_enum=${params.typeOfIndividual}&is_quality_profile=${params.isQualityProfile}`;
      } else {
        url = `${baseUrl}/BioFuelRegistration/api/DiscountScheme/FetchAllEntityAgainstTypeOfIndividualEnum?type_of_individual_enum=${params}`;
      }
      const res = await utils.fetch(url).ready;

      let resData = await utils.processApiRes(res);

      return resData;
    } catch (err) {
      const errors = await utils.processApiErrors(err);

      throw errors;
    }
  };

  const getEquiSpecDropdown = async (params = {}) => {
    try {
      const entity = _.get(params, 'entity') || '';
      const qparams = _.get(params, 'qparams') || {};

      const qs = Object.keys(qparams)
        .map((key) => `${key}=${qparams[key]}`)
        .join('&');

      let url = '';

      url = `${baseUrl}/BioFuelRegistration/api/EquipmentDetails/GetEquipmentRefData`;

      const res = await utils.fetch(url, {
        method: 'POST',
        body: JSON.stringify(qparams),
      }).ready;

      let resData = await utils.processApiRes(res);
      return resData;
    } catch (err) {
      const errors = await utils.processApiErrors(err);
      throw errors;
    }
  };

  const fetchUOMAgainstCommodityTypeForm = async (params) => {
    try {
      const qs = utils.getQs(params);
      let url = `${baseUrl}/BioFuelRegistration/api/ReferenceData/FetchUOMAgainstCommodityTypeForm?${qs}`;
      const res = await utils.fetch(url).ready;
      let resData = await utils.processApiRes(res);
      return resData;
    } catch (err) {
      const errors = await utils.processApiErrors(err);
      throw errors;
    }
  };

  const fetchUOMAgainstVehicleTypeEnum = async (params) => {
    try {
      const qs = utils.getQs(params);
      let url = `${baseUrl}/BioFuelRegistration/api/ReferenceData/FetchUOMAgainstVehicleTypeEnum?${qs}`;
      const res = await utils.fetch(url).ready;
      let resData = await utils.processApiRes(res);
      return resData;
    } catch (err) {
      const errors = await utils.processApiErrors(err);
      throw errors;
    }
  };

  const FetchYourPriceAgainstBuySell = async (params) => {
    try {
      let url = `${baseUrl}/BioFuelRequirementOffers/api/Deal/FetchYourPriceAgainstBuySell`;
      const res = await utils.fetch(url, {
        method: 'POST',
        body: JSON.stringify(params),
      }).ready;

      const resData = await utils.processApiRes(res);
      return resData;
    } catch (err) {
      throw new Error('API request error');
    }
  };

  return {
    profile,
    getLocations,
    getCompanies,
    getSocialFeed,
    getAllFeed,
    createSocialPost,
    followUnfollowUser,
    deletePostFromTimeline,
    reportPostOrComment,
    getReferenceData,
    getEnumData,
    getAllRequirements,
    likePost,
    likeComment,
    createRequirement,
    fetchUserProfile,
    getTimelinePost,
    createReqOffer,
    updateReqOffer,
    editAutoOffer,
    // offer,
    editRequirement,
    getCommodityDropdownData,
    getRequirementDetails,
    getUserRequirements,
    publishRequirement,
    publishOpenBuy,
    editPost,
    fetchPersonalDetails,
    followUnfollowRequirement,
    withdrawRequirement,
    getSavedPosts,
    savePost,
    deleteSavePost,
    deleteSavedComment,
    getFilteredData,
    transport,
    fetchOrganizationDetails,
    fetchDeliveryDetails,
    fetchDeliveryDocuments,
    getSpecificParticipant,
    fetchUOMAgainstCommodityTypeForm,
    fetchUOMAgainstVehicleTypeEnum,
    updateSellFromDerivedOffer,
    createBuySellFromOfferBid,
    FetchYourPriceAgainstBuySell,
    getEquiSpecDropdown,
    getPaymentTermForEquipmentRental,
  };
})();

export default api;
