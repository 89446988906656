import React, { ElementType, useEffect, useState } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { CircularProgress, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { colord as colorFormat } from 'colord';

import getColor from '../colors';
import { getIn } from 'formik';
import { actions as MUIAutocompleteAction } from 'redux/MUIComponentReducers/MUIAutocompleteReducer';

const icon = <CheckBoxOutlineBlankIcon />;
const checkedIcon = <CheckBoxIcon />;

type styleType = {
  color: string;
  textColor: string;
};

const useStyles = makeStyles(() => ({
  root: {
    '& label.Mui-focused': {
      color: (props: styleType) => props?.color,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: (props: styleType) => props?.color,
    },
    '& .MuiInputBase-root:has(.Mui-disabled):hover fieldset': {
      borderColor: 'rgba(0, 0, 0, 0.38)',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: (props: styleType) => props?.color,
      },
      '&:hover fieldset': {
        borderColor: (props: styleType) => props?.color,
      },
      '&.Mui-focused fieldset': {
        borderColor: (props: styleType) => props?.color,
      },
      '& input': {
        letterSpacing: '1px',
        textTransform: 'capitalize',
      },
    },
    '& .MuiOutlinedInput-adornedStart': {
      paddingLeft: '6px !important',
    },
    '& .MuiChip-root': {
      backgroundColor: (props: styleType) => props?.color,
      color: (props: styleType) => props?.textColor,
      letterSpacing: '1px',
      textTransform: 'capitalize',
      '& .MuiChip-deleteIcon': {
        fill: (props: styleType) => props?.textColor,
      },
    },
    '& .MuiInputLabel-root': {
      letterSpacing: '1px',
      fontFamily: 'Segoe UI',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(15px, -6px) scale(0.70) !important',
      color: (props: styleType) => getColor(`${props?.color}`),
    },
    '& .MuiInputLabel-shrink': {
      color: (props: styleType) => getColor(`${props?.color}`),
    },
    '& .MuiInput-underline:before, & .MuiInput-underline:hover:not(.Mui-disabled):before, & .MuiFilledInput-underline:before':
      {
        borderColor: (props: styleType) => getColor(`${props?.color}`),
      },
    '& .MuiFilledInput-root': {
      background: (props: styleType) =>
        `${colorFormat(getColor(`${props?.color}`))
          .alpha(0.09)
          .toHex()} !important`,
      '&:hover': {
        background: (props: styleType) =>
          `${colorFormat(getColor(`${props?.color}`))
            .alpha(0.13)
            .toHex()} !important`,
      },
    },
    '& .MuiOutlinedInput-root:has(.MuiInputAdornment-positionStart) legend': {
      marginLeft: '42px !important',
      maxWidth: '0px !important',
      transitionDuration: '0ms !important',
      transitionDelay: '0ms !important',
    },
    '& .MuiInputLabel-shrink:has(+ .MuiOutlinedInput-root .MuiInputAdornment-positionStart)': {
      transform: 'translate(56px, -6px) scale(0.70) !important',
    },
    '& .MuiInputLabel-root:has(+ .MuiOutlinedInput-root .MuiInputAdornment-positionStart)': {
      transform: 'translate(55px, 12px) scale(1) !important',
      color: 'rgba(0, 0, 0, 0.54)',
    },
    '& .MuiInputLabel-root.Mui-focused:has(+ .MuiOutlinedInput-root .MuiInputAdornment-positionStart)': {
      color: (props: styleType) => getColor(`${props?.color}`),
      transform: 'translate(56px, -6px) scale(0.70) !important',
    },
    '& .MuiOutlinedInput-root.Mui-focused legend': {
      maxWidth: '1000px !important',
      margin: '0 2px',
    },
    '& .MuiFormHelperText-contained': {
      margin: '4px 0 0 2px',
    },
  },
  selectedOption: {
    '& .MuiAutocomplete-option[aria-selected="true"]': {
      backgroundColor: (props: styleType) => `${colorFormat(props?.color).alpha(0.09).toHex()} !important`,
    },
    '& .MuiAutocomplete-option[data-focus="true"]': {
      backgroundColor: (props: styleType) => `${colorFormat(props?.color).alpha(0.09).toHex()} !important`,
    },
    '& .MuiIconButton-root': {
      color: (props: styleType) => `${props?.color} !important`,
    },
    '& .MuiIconButton-root:hover': {
      backgroundColor: (props: styleType) => `${colorFormat(props?.color).alpha(0.04).toHex()} !important`,
    },
    '&::-webkit-scrollbar': {
      width: '6px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: `inset 0 0 5px ${(props: styleType) => props?.color}`,
      borderRadius: '10px',
      backgroundColor: (props: styleType) => `${colorFormat(props?.color).alpha(0.13).toHex()} !important`,
    },
    '&::-webkit-scrollbar-thumb': {
      background: (props: styleType) => props?.color,
      borderRadius: '10px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: (props: styleType) => colorFormat(props?.color).darken(50),
    },
  },
}));

type MUIAutocompletePropTypes = {
  type?: 'normal' | 'multiselect' | 'search';
  size?: 'small' | 'medium';
  variant?: 'outlined' | 'standard' | 'filled';
  color?: 'old' | 'primary' | 'muiPrimary' | 'secondary' | 'ternary' | string;
  data?: [];
  selectedCodeOrId: string | number;
  dataStructure: { labelApiParam: string; valueApiParam: string | number };
  apiParams: { reqUrl: string; method: string; isQueryParams?: boolean; getReqParams?: (value: string) => {} };
  loading?: boolean;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  StartIcon?: ElementType | string;
  EndIcon?: ElementType | string;
  field?: any;
  form?: any;
  isError?: boolean;
  errorMsg?: string;
  onChange?: (value: any, setterFunction: Function) => void;
  generateEndContent?: (value: any) => HTMLElement;
  getSanitizedData?: (value: []) => [];
  onInputChange?: (value: string, reason: 'input' | 'reset' | 'clear') => void;
  multiselectArray?: Array<number>;
  limitTags?: number;
  checkBoxSize?: 'small' | 'medium';
};

const MUIAutocomplete = ({
  type = 'normal',
  size = 'small',
  variant = 'outlined',
  color = 'muiPrimary',
  data,
  selectedCodeOrId,
  apiParams,
  dataStructure,
  loading,
  label,
  placeholder,
  disabled = false,
  fullWidth = true,
  StartIcon = '',
  EndIcon = '',
  field,
  form,
  isError = false,
  errorMsg = '',
  onChange,
  generateEndContent,
  getSanitizedData,
  onInputChange,
  multiselectArray = [],
  limitTags = 2,
  checkBoxSize = 'medium',
}: MUIAutocompletePropTypes) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const config = _.get(state, 'user.profile.ui_config');
  const bgColor = getColor(config?.theme_color || color);
  const isLight = colorFormat(bgColor).isLight();
  const textColor = isLight ? colorFormat(bgColor).darken(1).toHex() : colorFormat(bgColor).lighten(1).toHex();
  const classes = useStyles({ color: bgColor, textColor });

  const entity = apiParams?.reqUrl?.split('/')?.[apiParams?.reqUrl.split('/').length - 1];
  const dropdownData = entity ? _.get(state, `MUIComponents.data.${entity}`, data || []) : data;
  const busy = _.get(state, `MUIComponents.busy`);

  const [selectedValue, setSelectedValue] = useState<object | object[]>(type === 'multiselect' ? [] : {});
  const [searchValue, setSearchValue] = useState('');

  const hasError = isError || getIn(form?.touched, field?.name) || getIn(form?.errors, field?.name);
  const errText = hasError ? errorMsg || getIn(form?.errors, field?.name) : '';

  const convertFirstLetterToUppercase = (text: string) => text.charAt(0).toUpperCase() + text.slice(1);

  const { method, reqUrl, getReqParams, isQueryParams } = apiParams;
  useEffect(() => {
    if (type === 'search' && (searchValue || selectedCodeOrId)) {
      const params = { method, reqUrl, isQueryParams, reqParams: getReqParams && getReqParams(searchValue) };
      dispatch(MUIAutocompleteAction.getDataFromApi(params));
    } else if (type !== 'search' && !_.isEmpty(apiParams)) {
      const params = { method, reqUrl, isQueryParams, reqParams: getReqParams ? getReqParams(searchValue) : {} };
      dispatch(MUIAutocompleteAction.getDataFromApi(params));
    }
  }, [searchValue, reqUrl]);

  const labelParams = dataStructure?.labelApiParam?.includes('-')
    ? dataStructure?.labelApiParam?.split('-')
    : dataStructure?.labelApiParam;
  const generateLabel = (labelData) => {
    return typeof labelParams === 'object'
      ? labelParams?.map((item) => `${labelData?.[item]}`)?.join(' - ')
      : labelData?.[labelParams] || '';
  };
  let formattedDropdownData = dropdownData?.map((item) => ({
    ...item,
    label: generateLabel(item),
    value: item?.[`${dataStructure?.valueApiParam}`],
  }));

  if (getSanitizedData && formattedDropdownData?.length) {
    formattedDropdownData = getSanitizedData(formattedDropdownData);
  }

  let selected;
  if (type === 'multiselect' && multiselectArray?.length) {
    selected = formattedDropdownData?.filter(
      (item) => multiselectArray?.includes(item?.['value']) || multiselectArray?.includes(item?.['label']),
    );
  } else if (type === 'normal' || type === 'search') {
    selected = formattedDropdownData?.find(
      (item) => item?.['value'] === selectedCodeOrId || item?.['label'] === selectedCodeOrId,
    );
  } else {
    selected = selectedCodeOrId;
  }

  useEffect(() => {
    if (!_.isEmpty(selected) && _.isEmpty(selectedValue)) {
      setSelectedValue(selected);
    } else if (_.isEmpty(selected)) {
      setSelectedValue({});
    }
  }, [!_.isEmpty(selected)]);

  useEffect(() => {
    return () => {
      dispatch(MUIAutocompleteAction.setData({ entity, resData: [] }));
    };
  }, []);

  return (
    <Autocomplete
      size={size}
      fullWidth={fullWidth}
      disabled={disabled}
      value={_.isEmpty(selectedValue) ? (type === 'multiselect' ? [] : null) : selectedValue}
      options={formattedDropdownData}
      multiple={type === 'multiselect'}
      limitTags={limitTags}
      disableCloseOnSelect={type === 'multiselect'}
      getOptionLabel={(option) => option?.['label'] || ''}
      getOptionSelected={(option, value) =>
        !_.isEmpty(option) && !_.isEmpty(value) ? option?.['value'] === value?.['value'] : null === null
      }
      classes={{ listbox: classes.selectedOption }}
      loading={loading || busy}
      renderOption={(option, { selected }) => (
        <div
          className="w-100 d-flex justify-content-between align-items-center"
          style={{ letterSpacing: 1, textTransform: 'capitalize' }}
        >
          <div className="d-flex align-items-center">
            {type === 'multiselect' ? (
              <Checkbox
                size={checkBoxSize}
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 0 }}
                checked={selected}
              />
            ) : (
              ''
            )}
            {StartIcon ? (
              <>
                {typeof StartIcon === 'string' ? (
                  <span style={{ color: bgColor, marginRight: 8 }}>{StartIcon}</span>
                ) : (
                  <StartIcon style={{ color: bgColor, height: 24, width: 24, fill: bgColor, marginRight: 8 }} />
                )}
              </>
            ) : (
              ''
            )}
            <span style={{ textTransform: 'capitalize' }}>{option?.['label']}</span>
          </div>
          <div className="d-flex align-items-center">
            {EndIcon ? (
              <>
                {typeof EndIcon === 'string' ? (
                  <div style={{ color: bgColor, marginRight: 10 }}>{EndIcon}</div>
                ) : (
                  <EndIcon style={{ height: 24, width: 24 }} />
                )}
              </>
            ) : (
              ''
            )}
            <div style={{ color: bgColor }}>{generateEndContent && generateEndContent(option)}</div>
          </div>
        </div>
      )}
      onChange={(e, newValue) => {
        setSelectedValue(newValue!);
        form?.setFieldValue(field?.name, newValue?.[`${dataStructure?.valueApiParam}`]);
        onChange && onChange(newValue, setSelectedValue);
      }}
      onInputChange={(e, newValue, reason) => {
        onInputChange && onInputChange(newValue, reason);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          className={classes.root}
          variant={variant}
          label={label}
          placeholder={type === 'search' ? placeholder || 'Enter minimum 3 characters to search' : placeholder}
          error={hasError}
          helperText={errText}
          onChange={
            type === 'search'
              ? (e) => {
                  const searchText = e.target.value;
                  if (searchText.length >= 3) {
                    setSearchValue(searchText);
                  }
                }
              : () => {}
          }
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {busy ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};
export default MUIAutocomplete;
