import _ from 'lodash';
import utils from 'lib/utils';
import qs from 'query-string';
import config from 'config';
import { reqTransform, resTransform } from './transforms';

const { baseUrl } = config;

export const fetchList = async (params = {}) => {
  try {
    let bodyParams = reqTransform.fetchList(params);
    const url = `${baseUrl}/BioFuelTransportRequirementOffers/api/TransportDeal/FetchAllTransportDealDetails_ELOC`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchList(resData);
  } catch (err) {
    console.log('api-transportDeal-fetchList->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchAllTransportDealDetails_ELOC');
    throw errors;
  }
};

export const fetchDetails = async (params = {}) => {
  try {
    const qs = reqTransform.fetchDetails(params);
    const url = `${baseUrl}/BioFuelTransportRequirementOffers/api/TransportDeal/FetchTransportDealDetailsAgainstTransportDealMasterId_ELOC?transport_deal_master_id=${qs}`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchDetails(resData);
  } catch (err) {
    console.log('api-FetchTransportDealDetailsAgainstTransportDealMasterId-fetchDetails->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchTransportDealDetailsAgainstTransportDealMasterId_ELOC');
    throw errors;
  }
};

export const create = async (params) => {
  try {
    let bodyParams = reqTransform.create(params);
    const url = `${baseUrl}/BioFuelTransportRequirementOffers/api/TransportDeal/SaveTransportContractDeal_ELOC`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.create(resData);
  } catch (err) {
    console.log('SaveTransportContractDeal-create->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'SaveTransportContractDeal_ELOC');
    throw errors;
  }
};

export const update = async (id, params = {}) => {
  try {
    let bodyParams = reqTransform.update(id, params);
    const url = `${baseUrl}/BioFuelTransportRequirementOffers/api/TransportDeal/SaveTransportContractDeal_ELOC`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.update(resData);
  } catch (err) {
    console.log('SaveTransportContractDeal-update->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'SaveTransportContractDeal_ELOC');
    throw errors;
  }
};

export const destroy = async (id) => {
  try {
    const qs = reqTransform.destroy(id);
    const url = `${baseUrl}/BioFuelRegistration/api/BusinessProfile/DeleteAffiliateAgainstComapnyAffiliateId?company_affiliate_id=${qs}`;
    const res = await utils.fetch(url, {
      method: 'PUT',
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.destroy(resData);
  } catch (err) {
    console.log('api-transport-update->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'DeleteAffiliateAgainstComapnyAffiliateId');
    throw errors;
  }
};

export const fetchFilterData = async (params) => {
  try {
    const qs = reqTransform.fetchFilterData(params);
    const url = `${baseUrl}/BioFuelCommonUtilities/api/Filter/GetFilterData?${qs}`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchFilterData(resData);
  } catch (err) {
    console.log('api-GetFilterData-fetchList->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'GetFilterData');
    throw errors;
  }
};

export const fetchTransports = async (params) => {
  try {
    let bodyParams = reqTransform.fetchTransports(params);
    const url = `${baseUrl}/BioFuelTransportRequirementOffers/api/TransportDeal/FetchMatchTransportDeal_ELOC`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchTransports(resData);
  } catch (err) {
    console.log('FetchMatchTransportDeal-create->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchMatchTransportDeal_ELOC');
    throw errors;
  }
};

export const createTransportDeal = async (id, params) => {
  console.log('values create Transport deal====>4', id, params);

  try {
    let bodyParams = reqTransform.createTransportDeal(params);
    const url = `${baseUrl}/BioFuelTransportRequirementOffers/api/TransportDeal/UpdateTransportDealToDelivery?Transport_Deal_Master_Id=${id}`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.createTransportDeal(resData);
  } catch (err) {
    console.log('UpdateTransportDealToDelivery-create->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'UpdateTransportDealToDelivery');
    throw errors;
  }
};

export const FetchDeliveryAllotmentsAgainstTransportDealMasterId = async (params) => {
  try {
    let bodyParams = reqTransform.createTransportDeal(params);
    const url = `${baseUrl}/BioFuelDeliveries/api/DeliveryAssignment/FetchDeliveryAllotmentsAgainstTransportDealMasterId_ELOC`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchTransportDeliveryContract(resData);
  } catch (err) {
    console.log('UpdateTransportDealToDelivery-create->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchDeliveryAllotmentsAgainstTransportDealMasterId_ELOC');
    throw errors;
  }
};
