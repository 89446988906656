import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';

const { baseUrl } = config;

export const getAllEquipments = async (params) => {
  try {
    const url = `${baseUrl}/BioFuelRegistration/api/EquipmentDetails/GetAllEquipments`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(params),
    }).ready;
    let resData = await utils.processApiRes(res);
    resData = resData.map((item, index) => ({ ...item, id: index }));
    return resData;
  } catch (err: any) {
    const errors = await utils.processApiErrors(err, '', 'GetAllEquipments');
    throw errors;
  }
};

export const getEquipmentMasterProfile = async (params) => {
  try {
    const url = `${baseUrl}/BioFuelRegistration/api/EquipmentDetails/GetEquipmentMasterProfile`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(params),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err: any) {
    const errors = await utils.processApiErrors(err, '', 'GetEquipmentMasterProfile');
    throw errors;
  }
};

export const verifyEquipmentProfile = async (params) => {
  try {
    const qs = utils.getQs(params);
    const url = `${baseUrl}/BioFuelRegistration/api/EquipmentDetails/VerifyEquipmentProfile?${qs}`;
    const res = await utils.fetch(url, {
      method: 'POST',
    }).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err: any) {
    const errors = await utils.processApiErrors(err, '', 'VerifyEquipmentProfile');
    throw errors;
  }
};

export const getEquipmentRentalPaymentDetails = async (params) => {
  try {
    const qs = utils.getQs(params);
    const url = `${baseUrl}/BioFuelRequirementOffers/api/EquipmentDetails/GetEquipmentRentalPaymentDetails`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(params),
    }).ready;
    let resData = await utils.processApiRes(res);
    resData = resData?.map((item) => ({ ...item, id: item.commodity_deal_master_id }));
    return resData;
  } catch (err: any) {
    const errors = await utils.processApiErrors(err, '', 'GetEquipmentRentalPaymentDetails');
    throw errors;
  }
};

export const payEquipmentRentalPayment = async (params) => {
  try {
    const qs = utils.getQs(params);
    const url = `${baseUrl}/BioFuelRequirementOffers/api/EquipmentDetails/PayEquipmentRentalPayment?${qs}`;
    const res = await utils.fetch(url, {
      method: 'POST',
    }).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err: any) {
    const errors = await utils.processApiErrors(err, '', 'PayEquipmentRentalPayment');
    throw errors;
  }
};

export const fetchAllRentalTransactions = async (params) => {
  try {
    const qs = utils.getQs(params);
    const url = `${baseUrl}/BioFuelRequirementOffers/api/EquipmentDetails/FetchAllRentalTransactions`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(params),
    }).ready;
    let resData = await utils.processApiRes(res);
    resData = resData?.map((item) => ({ ...item, id: item.commodity_deal_master_id }));
    return resData;
  } catch (err: any) {
    const errors = await utils.processApiErrors(err, '', 'FetchAllRentalTransactions');
    throw errors;
  }
};

export const updateTransportDealToEquipmentDeal = async (id, params) => {
  try {
    const url = `${baseUrl}/BioFuelTransportRequirementOffers/api/TransportDeal/UpdateTransportDealToEquipmentDeal?Transport_Deal_Master_Id=${id}`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(params),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'UpdateTransportDealToEquipmentDeal');
    throw errors;
  }
};

export const GetPrevAddressesEquipment = async (params) => {
  try {
    const url = `${baseUrl}/BioFuelRegistration/api/EquipmentDetails/GetEquipmentAddresses`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(params),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'UpdateTransportDealToEquipmentDeal');
    throw errors;
  }
};

export const createEquipmentProduct = async (params) => {
  try {
    const url = `${baseUrl}/BioFuelRegistration/api/EquipmentDetails/SaveUpdateEquipmentMaster`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(params),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'UpdateTransportDealToEquipmentDeal');
    throw errors;
  }
};

export const attachDocumentEqipmentAzureStorage = async (params) => {
  console.log('assureimmgg', params);
  try {
    let formData = new FormData();
    formData.append('entity_id', params?.entity_id);
    formData.append('document_Module_Enum', params?.document_module_enum);
    formData.append('entity_Type_Enum', params?.entity_type_enum);
    formData.append('module_Enum', params?.module_enum);
    formData.append('uploading_Person_Id', params?.uploading_person_id);
    formData.append('attachment_Id', params?.attachment_id);
    formData.append('attachment_File', params?.attachment_file);
    console.log('formDataattachment', formData);
    const url = `${baseUrl}/BioFuelCommonUtilities/api/DocumentUpload/AttachDocumentAzureStorage`;
    const res = await utils.fetch(
      url,
      {
        method: 'POST',
        body: formData,
      },
      false,
    ).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'UpdateTransportDealToEquipmentDeal');
    throw errors;
  }
};

export const fetchSuggestedEquipmentSells = async (params) => {
  try {
    const url = `${baseUrl}/BioFuelRequirementOffers/api/EquipmentDetails/GetSuggestedEquipmentSells`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(params),
    }).ready;
    let resData = await utils.processApiRes(res);
    resData = resData.map((item, index) => ({ ...item, id: index }));
    return resData;
  } catch (err: any) {
    const errors = await utils.processApiErrors(err, '', 'fetchSuggestedEquipmentSells');
    throw errors;
  }
};

export const saveUpdateEquipmentPlainOffer = async (params) => {
  try {
    const bodyParams = params;
    const url = `${baseUrl}/BioFuelRequirementOffers/api/EquipmentDetails/SaveUpdateEquipment_PlainOffer`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;

    const resData = await utils.processApiRes(res);

    return resData;
  } catch (err: any) {
    const errors = await utils.processApiErrors(err, '', 'saveUpdateEquipmentPlainOffer');
    throw errors;
  }
};

export const deleteEquipment = async (id) => {
  try {
    const url = `${baseUrl}/BioFuelRegistration/api/EquipmentDetails/DeleteEquipment?equipment_master_id=${id}`;
    const res = await utils.fetch(url, {
      method: 'PUT',
    }).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err: any) {
    const errors = await utils.processApiErrors(err, '', 'deleteEquipment');
    throw errors;
  }
};

export const setStatusForEquipmentMaster = async (params) => {
  try {
    const qs = utils.getQs(params);
    const url = `${baseUrl}/BioFuelRegistration/api/EquipmentDetails/SetStatusForEquipmentMaster?${qs}`;
    const res = await utils.fetch(url, {
      method: 'PUT',
    }).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err: any) {
    const errors = await utils.processApiErrors(err, '', 'setstatusEquipment');
    throw errors;
  }
};
