import React, { forwardRef, useEffect, useState, useMemo, useCallback } from 'react';
import DatePicker from 'react-datepicker';
import DatePickerWrapper from './DatePickerWrapper';
import format from 'date-fns/format';
import endOfDay from 'date-fns/endOfDay';
import getTime from 'date-fns/getTime';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import dayjs from 'dayjs';
import CustomDateInput from './CustomDateInput';

type MUIDatePickerPropTypes = {
  type?: 'date' | 'range' | 'time' | 'date-time';
  pickerType?: 'date' | 'month' | 'year' | 'quarter';
  label?: string;
  monthsShown?: number;
  timeIntervals?: number;
  showMonthDropdown?: boolean;
  showYearDropdown?: boolean;
  popperPlacement?:
    | 'top'
    | 'top-start'
    | 'top-end'
    | 'bottom'
    | 'bottom-start'
    | 'bottom-end'
    | 'left'
    | 'left-start'
    | 'left-end'
    | 'right'
    | 'right-start'
    | 'right-end';
  form?: any;
  field?: any;
  value?: Date | string | { start: Date | string; end: Date | string };
  onChange?: (value: Date | string | [Date | string, Date | string], dateString?: string) => void;
  minDate?: Date | string;
  minTime?: Date | string | number;
  maxTime?: Date | string;
};

const MUIDatePicker = ({
  type = 'date',
  pickerType = 'date',
  label,
  monthsShown = 1,
  timeIntervals = 15,
  showMonthDropdown = true,
  showYearDropdown = true,
  popperPlacement,
  form,
  field,
  value,
  onChange,
  minDate = new Date(),
  minTime = getTime(new Date()),
  maxTime = endOfDay(new Date()),
  ...rest
}: MUIDatePickerPropTypes) => {
  const [date, setDate] = useState(value || null);
  const [startDate, setStartDate] = useState(value?.['start'] || null);
  const [endDate, setEndDate] = useState(value?.['end'] || null);

  useEffect(() => {
    if (value) {
      if (type === 'range') {
        setStartDate(value?.['start']);
        setEndDate(value?.['end']);
      } else {
        setDate(value);
      }
    } else if (field?.value) {
      if (type === 'range') {
        setStartDate(field?.value?.['start'] || null);
        setEndDate(field?.value?.['end'] || null);
      } else {
        setDate(field?.value || null);
      }
    }
  }, [value]);

  const handleOnChange = useCallback(
    (dates) => {
      if (type === 'range') {
        let [start = null, end = null] = dates || [];
        if (pickerType !== 'date') {
          start = start ? startOfMonth(start) : null;
          end = end ? endOfMonth(end) : null;
        }
        const dateRangString = `${start ? dayjs(start).format('YYYY-MM-DD') : ''}${
          end ? `:${dayjs(end).format('YYYY-MM-DD')}` : ''
        }`;
        setStartDate(start);
        setEndDate(end);
        onChange && onChange([start, end] || [], dateRangString);
        if (field?.name?.includes(':')) {
          field.name.split(':').forEach((fieldName, index) => {
            form && form.setFieldValue(fieldName, index === 0 ? start : end);
          });
        }
      } else {
        setDate(dates);
        onChange && onChange(dates || []);
        form && form.setFieldValue(field?.name, dates);
      }
    },
    [type, pickerType, field, form, onChange],
  );

  const CustomInput = useMemo(
    () =>
      React.memo(
        forwardRef((props: any, ref) => {
          const { start, end, date } = props;
          let singleDate, dateRange;
          if (type === 'range') {
            const startDate = start ? format(start, 'dd MMM yyyy') : '';
            const endDate = end ? format(end, 'dd MMM yyyy') : '';
            dateRange = `${startDate}${endDate ? ` - ${endDate}` : ''}`;
          } else {
            singleDate = date
              ? format(date, type === 'time' ? 'h:mm aa' : type === 'date-time' ? 'dd MMM yyyy h:mm aa' : 'dd MMM yyyy')
              : '';
          }
          const value = type === 'range' ? dateRange : singleDate;
          return <CustomDateInput {...props} value={value} inputRef={ref} />;
        }),
      ),
    [type],
  );

  const customInputProps = useMemo(
    () =>
      type === 'range'
        ? {
            label,
            start: startDate as Date | null,
            end: endDate as Date | null,
            form,
            field,
            name: rest?.['name'],
          }
        : {
            label,
            date: date as Date | null,
          },
    [type, label, startDate, endDate, form, field, rest?.['name'], date],
  );

  return (
    <DatePickerWrapper>
      <div>
        <DatePicker
          {...field}
          timeCaption={
            <strong style={{ fontSize: 'var(--fs-base__two)' }} className="d-block">
              Time
            </strong>
          }
          isClearable={type === 'range' ? endDate || startDate : date}
          fixedHeight
          useWeekdaysShort
          showTimeSelect={type === 'time' || type === 'date-time'}
          showTimeSelectOnly={type === 'time'}
          timeFormat="h:mm aa"
          timeIntervals={timeIntervals}
          selectsRange={type === 'range'}
          monthsShown={monthsShown}
          endDate={type === 'range' ? endDate : undefined}
          startDate={type === 'range' ? startDate : undefined}
          selected={type === 'range' ? startDate : date}
          id={type === 'range' ? 'date-range-picker' : 'date-range-picker-months'}
          onChange={handleOnChange}
          shouldCloseOnSelect={type !== 'range'}
          showMonthDropdown={showMonthDropdown}
          showYearDropdown={showYearDropdown}
          popperPlacement={popperPlacement}
          yearDropdownItemNumber={6}
          useShortMonthInDropdown
          showMonthYearPicker={pickerType === 'month'}
          showYearPicker={pickerType === 'year'}
          showQuarterYearPicker={pickerType === 'quarter'}
          showFourColumnMonthYearPicker={pickerType !== 'date'}
          minDate={minDate}
          minTime={minTime}
          maxTime={maxTime}
          customInput={<CustomInput {...customInputProps} />}
          {...rest}
        />
      </div>
    </DatePickerWrapper>
  );
};

export default MUIDatePicker;
