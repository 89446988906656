import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import { actions as filterOptionsActions } from 'redux/filterOptions';
import { Space, Button, Select, AutoComplete, Checkbox, Row, Col } from 'antd';

function MultiSelectFilter({ setScrollToFirstRow, scrollToFirstRow, dataIndex, data, onFilterChange, handleReset, value, name, isStorage, isCodeBasedFilter, needSemiColan }) {
  const { confirm, clearFilters } = data;
  const dispatch = useDispatch();
  const filterOptions = useSelector((state) => state.filterOptions);
  const referenceData = useSelector((state) => state.referenceData);

  let valueArray = [];
  if (value != undefined && value != '') {
    if(needSemiColan){
      valueArray = value.split(';');
    }else{
      valueArray = value.split(',');
    }
  } else {
    valueArray = null;
  }
  const [checkboxValues, setCheckboxValues] = useState([]);

  let options = [];
  if (dataIndex) {
    if (dataIndex === 'preferred_blocked_enum' || dataIndex === 'preferred_blocked_code') {
      options = [
        { value: 1, text: 'Preferred' },
        { value: 2, text: 'Exclude Blocked' },
        { value: 3, text: 'Following' },
      ];
    }
    if(dataIndex === "agreement"){
      let optionsRaw = _.get(filterOptions, 'data.Agreement') || [];
      options = optionsRaw.map((data)=> {return {value: data.text, text: data.text}})
      // console.log("MYOPTIONS:", options);
    }
    if (dataIndex === 'delivery_allotment_status_enum_code' || dataIndex === 'stock_in_out_delivery_status_enum') {
      options = _.get(filterOptions, 'data.delivery_allotment_status_enum') || [];
    }
    if (dataIndex === 'goods_services') {
      options = _.get(filterOptions, 'data.goods') || [];
    }
    if (dataIndex === 'type_of_user') {
      options = _.get(filterOptions, 'data.TypeOfIndividualEnum') || [];
    }
    if (dataIndex === 'business_type_enum') {
      options = _.get(filterOptions, 'data.BusinessTypeEnum') || [];
    }
    if (dataIndex === 'nature_of_business') {
      options = _.get(filterOptions, 'data.NatureOfBusinessEnum') || [];
    }
    if (dataIndex === 'subscription_plan') {
      options = _.get(filterOptions, 'data.subscriptionPlan') || [];
    }
    if (dataIndex === 'deal_status_enum_code' || dataIndex === 'deal_1_status_enum_code' || dataIndex === 'deal_2_status_enum' || dataIndex === 'processing_deal_status_enum_code') {
      options = _.get(filterOptions, 'data.deal_status_enum') || [];
    }
    if (dataIndex === 'ManualInvoiceGoodsServices') {
      options = _.get(filterOptions, 'data.ManualInvoiceGoodsServices') || [];
    }
    if (dataIndex === 'commodity_code') {
      options = _.get(filterOptions, 'data.commodity') || [];
    }
    if (dataIndex === 'commodity_residue_code' || dataIndex === 'commodity_residue_id') {
      options = _.get(filterOptions, 'data.commodityResidue') || [];
    }

    if (dataIndex === 'commodity_residue_master_code' || dataIndex === 'commodity_residue_code') {
      options = _.get(filterOptions, 'data.commodityResidueMaster') || [];
    }
    if (dataIndex === 'kyc_status_enum_code') {
      options = _.get(filterOptions, 'data.kyc_status_enum') || [];
    }
    if (dataIndex === 'commodity_standard_enum_code' || dataIndex === 'grade' || dataIndex === 'commodity_standard') {
      // options = [
      //   { value: 1, text: 'Premium' },
      //   { value: 2, text: 'Superior' },
      //   { value: 3, text: 'Standard' },
      // ];
      options = _.get(filterOptions, 'data.CommodityStandardEnum') || [];
    }
    if (dataIndex === 'address_type_enum_code') {
      options = _.get(filterOptions, 'data.AddressTypeEnum') || [];
    }
    if (dataIndex === 'offer_bid_type_enum') {
      options = [
        { value: -1, text: 'Quotation' },
        { value: 1, text: 'Offer' },
        { value: 2, text: 'Bid' },
      ];
    }
    if (dataIndex === 'transport_rate_status_enum_code') {
      options = _.get(filterOptions, 'data.TransportRateStatusEnum') || [];
    }
    if (dataIndex === 'MSME_Class_Code') {
      options = _.get(filterOptions, 'data.MSMEClassificationEnum') || [];
    }
    if (dataIndex === 'type_of_invoice') {
      options = _.get(filterOptions, 'data.settlement_invoice_type_enum') || [];
    }
    if (dataIndex === 'settlement_invoice_type_code') {
      options = _.get(filterOptions, 'data.SettlementInvoiceType') || [];
    }
    if (dataIndex === 'settlement_invoice_category_code') {
      options = _.get(filterOptions, 'data.SettlementInvoiceCategory') || [];
    }
    if (dataIndex === 'eq_status') {
      options = _.get(filterOptions, 'data.EquipmentRentalTransactionsStatus') || [];
    }
    if (dataIndex === 'payment_term_id') {
      options = _.get(filterOptions, 'data.undefined') || [];
    }
    if (dataIndex === 'invoice_status_enum' || dataIndex === 'linked_invoicestatusenum') {
      options = _.get(filterOptions, 'data.invoice_status_enum') || [];
    }
    if (dataIndex === 'invoice_verification_enum' || dataIndex === 'workflow_invoice_verification_enum') {
      options = _.get(filterOptions, 'data.invoice_verification_enum') || [];
    }
    if (dataIndex === 'pg_payment_enum_code') {
      options = _.get(filterOptions, 'data.pg_payment_status_enum') || [];
    }
    if (dataIndex === 'equipment_type_id') {
      options = _.get(filterOptions, 'data.BF_SETUP_EQUIPMENT_TYPE') || [];
    }
    if (dataIndex === 'equipment_type_and_goods') {
      let equipmentTypeOptions = _.get(filterOptions, 'data.BF_SETUP_EQUIPMENT_TYPE') || [];
      let goodsOptions = _.get(filterOptions, 'data.goods') || [];
      options = [...equipmentTypeOptions, ...goodsOptions] || [];
    }
    useEffect(() => {
      if (value === undefined || value === null) {
        setCheckboxValues([])
      } else if (valueArray?.length) {
        setCheckboxValues(valueArray)
      }
    }, [value]);

    useEffect(() => {
      if (dataIndex !== '') {
        switch (dataIndex) {
          case 'delivery_allotment_status_enum_code':
          case 'stock_in_out_delivery_status_enum':
            dispatch(filterOptionsActions.getDeliveryAllotmentStatusEnumData('delivery_allotment_status_enum'));
            break;
          case 'goods_services':
            dispatch(filterOptionsActions.getFilterDataByFilterEnum('goods'));
            break;
          case 'type_of_user':
            dispatch(filterOptionsActions.getRegisteredUserFilterData('TypeOfIndividualEnum'));
            break;
          case 'business_type_enum':
            dispatch(filterOptionsActions.getRegisteredUserFilterData('BusinessTypeEnum'));
            break;
          case 'nature_of_business':
            dispatch(filterOptionsActions.getRegisteredUserFilterData('NatureOfBusinessEnum'));
            break;
          case 'subscription_plan':
            dispatch(filterOptionsActions.getReferenceData('subscriptionPlan'));
            break;
          case 'ManualInvoiceGoodsServices':
            dispatch(filterOptionsActions.getFilterData('ManualInvoiceGoodsServices'));
            break;
          case 'commodity_code':
            dispatch(filterOptionsActions.getReferenceData('commodity'));
            break;
          case 'deal_status_enum_code':
            case 'deal_1_status_enum_code':
            case 'deal_2_status_enum':
            case 'processing_deal_status_enum_code':
              dispatch(filterOptionsActions.getDealEnumData(dataIndex));
              break;
          case 'commodity_residue_master_code':
          case 'commodity_residue_code':
            case 'commodity_residue_id':
              dispatch(filterOptionsActions.getReferenceData('commodityResidueMaster'));
              break;
          case 'kyc_status_enum_code':
            dispatch(filterOptionsActions.getKYCStatusEnumData('kycStatusEnum'));
            break;
          case 'type_of_invoice':
            dispatch(filterOptionsActions.getTypeOfInvoiceEnumData('settlement_invoice_type_enum'));
            break;
          case 'address_type_enum_code':
            dispatch(filterOptionsActions.getRegisteredUserFilterData('AddressTypeEnum'));
            break;
          case 'commodity_standard_enum_code':
          case 'commodity_standard':
          case 'grade':
            dispatch(filterOptionsActions.getRegisteredUserFilterData('CommodityStandardEnum'));
            break;
          case 'transport_rate_status_enum_code':
            dispatch(filterOptionsActions.getRegisteredUserFilterData('TransportRateStatusEnum'));
            break;
          case 'MSME_Class_Code':
            dispatch(filterOptionsActions.getRegisteredUserFilterData('MSMEClassificationEnum'));
            break;
          case 'settlement_invoice_type_code':
            dispatch(filterOptionsActions.getFilterData('SettlementInvoiceType'));
            break;
          case 'settlement_invoice_category_code':
            dispatch(filterOptionsActions.getFilterData('SettlementInvoiceCategory'));
            break;
          case 'eq_status':
            dispatch(filterOptionsActions.getFilterData('EquipmentRentalTransactionsStatus'));
            break;
          case 'agreement':
            dispatch(filterOptionsActions.getFilterDataELOC('Agreement'));
            break;
          case 'payment_term_id':
            dispatch(filterOptionsActions.getPaymentTermsForEquipment());
          case 'invoice_status_enum':
          case 'linked_invoicestatusenum':
            dispatch(filterOptionsActions.getInvoiceStatusEnumData('invoice_status_enum'));
            break;
          case 'workflow_invoice_verification_enum':
          case 'invoice_verification_enum':
            dispatch(filterOptionsActions.getInvoiceVerificationStatusEnumData('invoice_verification_enum'));
            break;
          case 'pg_payment_enum_code':
            dispatch(filterOptionsActions.getPgPaymentStatusEnum());
            break;
          case 'equipment_type_id':
            dispatch(filterOptionsActions.getRegisteredUserFilterData('BF_SETUP_EQUIPMENT_TYPE'));
            break;
          case 'equipment_type_and_goods':
            dispatch(filterOptionsActions.getRegisteredUserFilterData('BF_SETUP_EQUIPMENT_TYPE'));
            dispatch(filterOptionsActions.getFilterDataByFilterEnum('goods'));
            break;
        }
      }
    }, [dataIndex]);

    if (!_.isEmpty(options) && isStorage) {
      options = options.filter((item) => (item.text != "Closed") && (item.text != "CloseOut") && (item.text != "Rejected"))
    }

    return (
      <>
        <div
          className="p-10"
          // style={options?.length < 5 ? { display: 'inline-grid', width: '550px' } : { display: 'grid', width: '800px' }}
          style={{maxHeight: 300, overflow: 'auto'}}
        >
          <Checkbox.Group
            onChange={(e) => {
              setCheckboxValues(e);
            }}
            value={checkboxValues}
            // defaultValue={valueArray}
            loading={options?.length > 0 ? false : true}
            >
            <Col>
              {options?.map((option, index) => (
                <Row md={(dataIndex === 'payment_term_id' || dataIndex === 'agreement') ? 12 : 6}>
                  <Checkbox value={isCodeBasedFilter ? option.text.toString() : option.value.toString()}>
                    <span style={{ wordBreak: 'break-all' }}>{option.text}</span>
                  </Checkbox>
                </Row>
              ))}
            </Col>
          </Checkbox.Group>
        </div>
        <div className="pl-10 pr-10 pb-10">
          <Space className="mt-10">
            <Button
              onClick={() => {
                if (checkboxValues?.length) {
                  if(needSemiColan){
                    onFilterChange(checkboxValues.join(';'), dataIndex, confirm);
                  } else {
                    onFilterChange(checkboxValues.join(), dataIndex, confirm);
                  }
                  if (scrollToFirstRow) {
                    setScrollToFirstRow(true);
                  }
                } else {
                  handleReset(clearFilters, dataIndex);
                  setCheckboxValues([]);
                }
              }}
              size="small"
              type="primary"
              className="w-100">
              Ok
            </Button>
            <Button
              onClick={(e) => {
                // valueArray = [];
                handleReset(clearFilters, dataIndex);
                setCheckboxValues([]);
              }}
              size="small"
              style={{ width: 70, marginLeft: '5px' }}>
              Reset
            </Button>
          </Space>
        </div>
      </>
    );
  }
}

export default MultiSelectFilter;
