import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { Col, Container, Row, Card, CardBody, ButtonToolbar, Button } from 'reactstrap';
import { Formik, Field, Form, FieldArray, ErrorMessage } from 'formik';
import Bidicon from 'containers/Html/images/icon-bid.svg';
import FormikMaterialTextField from './../components/FormikMaterialTextField';
import FormikLocationSelect from 'common/Form/FormikLocationSelectEloc';
import searchLocation from 'common/Form/searchLocation';
import searchLocationform from 'common/Form/searchLocationFrom';
import searchLocationFacilitator from 'common/Form/searchLocationFacilliator';
import MUITextField from '@material-ui/core/TextField';
import { FarmCropsSchema } from './../handlers';
import { useSelector, useDispatch } from 'react-redux';
import LogoLoader from 'common/LogoLoader';
import { actions as userActions } from 'redux/user';
import { locationElocService } from 'services';
import { Popover, Tooltip } from 'antd';
// import { locationIdService } from 'services';
// import LocationModal from '../Organisations/components/locationModal'
// import LocationOnIcon from '@material-ui/icons/LocationOn';

// import Collapse from './../components/Collapse';
import utils from 'lib/utils';
import CropFields from './CropFields';
import * as handlers from './handlers';
import MUIButton from 'common/MUIComponents/Button';
import MUITypography from 'common/MUIComponents/Typography';
import LocationModal from 'containers/Account/Profile/Organisations/components/locationModal';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import * as helpers from 'services/helpers';
import * as yup from 'yup';

const getInitialValues = (farmDetails = {}) => {
  const crops = _.get(farmDetails, 'crops') || [];
  // console.log('farms farmDetails', farmDetails);
  let formFields = {
    id: _.get(farmDetails, 'id') || _.get(farmDetails, 'person_address_id') || '',
    name_of_farm: _.get(farmDetails, 'name_of_farm') || '',
    size_of_farm: _.get(farmDetails, 'size_of_farm') || '',
    // location_id: _.get(farmDetails, 'location_id') || 0,
    location_code: _.get(farmDetails, 'location_code') || '',
    // village_id: _.get(farmDetails, 'village_id') || 0,
    village_code: _.get(farmDetails, 'village_code') || '',
    // taluka_id: _.get(farmDetails, 'taluka_id') || 0,
    taluka_code: _.get(farmDetails, 'taluka_code') || '',
    // city_id: _.get(farmDetails, 'city_id') || 0,
    city_code: _.get(farmDetails, 'city_code') || '',
    // district_id: _.get(farmDetails, 'district_id') || 0,
    district_code: _.get(farmDetails, 'district_code') || '',
    // state_id: _.get(farmDetails, 'state_id') || 0,
    state_code: _.get(farmDetails, 'state_code') || '',
    country_id: _.get(farmDetails, 'country_id') || 0,
    country_code: _.get(farmDetails, 'country_code') || 'India',
    address_lattitude: _.get(farmDetails, 'address_lattitude') || '',
    address_longitude: _.get(farmDetails, 'address_longitude') || '',
    address_line1: _.get(farmDetails, 'address_line1') || '',
    address_line2: _.get(farmDetails, 'address_line2') || '',
    address_line3: _.get(farmDetails, 'address_line3') || '',
    pin_code: _.get(farmDetails, 'pin_code') || '',
    crops: crops,
    user_id: _.get(window, 'user.userid') || 0,
    place_id: _.get(farmDetails, 'place_id') || '',
    locality: _.get(farmDetails, 'locality') || '',
  };

  if (!crops.length) {
    formFields['crops'] = [
      {
        farm_detail_id: 0,
        crop_id: 0,
        crop_area: '',
        harvest_date: '',
        crop_code: '',
      },
    ];
  }

  // console.log('formFields---->', formFields);

  return formFields;
};

const FarmCropsForm = (props) => {
  const user = useSelector((state) => state.user);
  const farms = useSelector((state) => state.farms);
  const farmId = props.farmId || 0;
  const id = props.id;
  const { setFarmDetails, isFacilitator, selectedRowData, setClosePopup, closePopup, isEdit, setIsEdit } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  let details = isFacilitator
    ? farmId != 0
      ? props.farmDetails.filter((item) => (item.person_address_id || item.id) == farmId)
      : props.farmDetails.filter((item) => !item.person_address_id && item?.location_code === selectedRowData?.location_code) || []
    : [];
  const farmDetails = isFacilitator ? details[0] : isEdit ? _.get(farms, `entities[${farmId}]`) : {};

  const [locDetailsValue, setLocDetailsValue] = useState({});
  const [locDetailsEloc, setLocDetailsEloc] = useState({});

  const [latLongData, setLatLongData] = useState({});
  const tokenState = useSelector((state) => state.locationEloc);
  const token = _.get(tokenState, 'entities.undefined');
  const [isEditorOpen, setIsEditorOpen] = useState(false);

  // const [isEditorOpen, setIsEditorOpen] = useState(false);
  // const [locationData, setLocationData] = useState({});

  // const locationToggle = () => {
  //   setIsEditorOpen(!isEditorOpen);
  // };

  // const getLocationEloc = async (params) => {
  //   const resData = await locationIdService.fetchLocationEloc(params);
  //   return resData;
  // }

  const getLocationDetails = async (params) => {
    const resData = await locationElocService.getLocationDetails(params);
    setLatLongData(resData);
    return resData;
  };

  useEffect(() => {
    let params = {
      place_id: locDetailsEloc,
      bearer_token: token,
    };
    if (locDetailsEloc) {
      const res = getLocationDetails(params);
    }
  }, [locDetailsEloc, token]);

  const followToggle = () => {
    setIsEditorOpen(!isEditorOpen);
  };

  const setLatLong = async (eloc, formikBag) => {
    let params = {
      place_id: eloc,
      bearer_token: token,
    };
    if (eloc) {
      const res = await getLocationDetails(params);
      formikBag.setFieldValue('address_lattitude', res.latitude?.toString());
      formikBag.setFieldValue('address_longitude', res.longitude?.toString());
    }
  };

  // if (farmId && _.isEmpty(farmDetails) && !isFacilitator && false) {
  //   return (
  //     <div className="bfc-modalform">
  //       <div className="bfc-modalform-header">
  //         <div className="icons-wrapper">
  //           <img src={Bidicon} alt="" />
  //           <span className="title">Farm</span>
  //         </div>
  //         <div className="close-wrapper">
  //           <a href="" className="d-none">
  //             -
  //           </a>
  //           <Link to={`/account/${id}/farms`}>X</Link>
  //         </div>
  //       </div>
  //       <div className="modalform-body">
  //         <div className="panel__refresh">
  //           <LogoLoader />
  //         </div>
  //       </div>
  //     </div>
  //   );
  // }

  const FarmSchema = yup.object().shape({
    name_of_farm: yup.string().required('Name of farm is a required field'),
    size_of_farm: yup.string().required('Size of farm is a required field'),
    address_line1: yup.string().required('Address Line 1 is a required field'),
    pin_code: yup.string().required('Pin Code is a required field'),
    location_code: yup.string().required('Location is a required field'),
    crops: yup.array().of(
      yup.object().shape({
        farm_detail_id: yup.number().required(),
        crop_id: yup.number().positive('Crop is a required field').typeError('Crop is a required field').required(),
        crop_area: yup.number().positive().typeError('Size of farm is a required field').required(),
        harvest_date: yup.string().required('Harvest date a required field'),
      }),
    ),
  });

  return (
    <React.Fragment>
      <Formik
        initialValues={getInitialValues(farmDetails)}
        onSubmit={async (values, formikBag) => {
          if (isFacilitator) {
            try {
              if (!_.isEmpty(farmDetails)) {
                const updatedData = props.farmDetails.map((item) => {
                  if (item.person_address_id === id || item.person_address_id === farmDetails.person_address_id) {
                    return values;
                  }
                  return item;
                });
                await helpers.validateSchema(FarmSchema, values);
                setFarmDetails(updatedData);
              } else {
                await helpers.validateSchema(FarmSchema, values);
                let newArr = [];
                newArr.push({ ...values, tempId: new Date().getTime() });
                setFarmDetails((prev) => {
                  return [...prev, newArr[0]];
                });
              }
              props.setIsOpen(false);
              setIsEdit && setIsEdit(false)
            } catch (error) {
              utils.displayErrors(error);
              throw error;
            }
          } else {
            farmId ? handlers.handleUpdate(values, formikBag) : handlers.handleCreate(values, formikBag);
            props.setIsOpen(false);
            setIsEdit && setIsEdit(false)
          }
          setClosePopup && setClosePopup(true)
        }}>
        {(formikBag) => {
          const { values, setFieldValue } = formikBag;
          values.user_id = id;
          // values.address_lattitude = (
          //   _.get(latLongData, 'latitude') ||
          //   _.get(farmDetails, 'address_lattitude') ||
          //   ''
          // ).toString();
          // values.address_longitude = (
          //   _.get(latLongData, 'longitude') ||
          //   _.get(farmDetails, 'address_longitude') ||
          //   ''
          // ).toString();
          return (
            <div>
              <div>
                <Form className="form-container">
                  <div className="form-wrapper full-width">
                    <div className="contailer-fluid">
                      <div className="row form__form-group">
                        <div className="col">
                          <span className="form-group-label">Name of farm</span>
                          <span class="required">*</span>
                          <div className="form-group-field">
                            <Field name="name_of_farm" component={FormikMaterialTextField} />
                          </div>
                        </div>
                      </div>
                      <div className="row form__form-group">
                        <div className="col">
                          <span className="form-group-label">Size of farm (acres)</span>
                          <span class="required">*</span>
                          <div className="form-group-field">
                            <Field type="number" name="size_of_farm" component={FormikMaterialTextField} />
                          </div>
                        </div>
                      </div>
                      {/* <div className="row form__form-group">
                        <div className="col">
                          <span className="form-group-label">
                            Map Location
                            <p className="mb-0 float-right"
                              style={{ color: 'red' }}
                              onClick={(e) => {
                                setIsEditorOpen(true);
                              }}>
                              {values.location_code ? (
                                'Update location.'
                              ) : ''}
                            </p>
                          </span>
                          <span class="required">*</span>
                          <div className="form-group-field mt-5">
                            <div
                              className="d-flex"
                              onClick={(e) => {
                                setIsEditorOpen(true);
                              }}>
                              <LocationOnIcon className="mt-5" />
                              <span>
                                {values.location_code ? (
                                  <span style={{ color: `#1b62ab` }}>{values.location_code}</span>
                                ) : (
                                  'Click here to location.'
                                )}
                              </span>
                            </div>
                            {isEditorOpen && (
                              <Field
                                name="location_code_geomap"
                                component={LocationModal}
                                disabled={values.location_code ? true : false}
                                initialDisplayText={values.location_code || ''}
                                isOpen={isEditorOpen}
                                toggle={locationToggle}
                                cb={async (locationValues) => {
                                  setLocationData(locationValues);
                                  let params = {
                                    location_code: locationValues.formatted_address,
                                    bearer_token: token,
                                    lat: locationValues.lat,
                                    lng: locationValues.lng,
                                  }
                                  let res = await getLocationEloc(params);
                                  let Eloc = _.get(res, 'suggested_locations[0].e_loc')
                                  let locationDetails = locationValues || '';
                                  //call this if statement at the end because sometimes setLocDetailsValue will unmount the component and hence will show old data
                                  // if (locationValues) {
                                  //   locationDetails = locationValues[0] || {};
                                  //   // setLocDetailsValue(locationValues[0]?.addressTokens);
                                  //   setLocDetailsEloc(locationValues[0]?.eLoc);
                                  // }
                                  let latitude = _.get(locationValues, 'lat', '1');
                                  let longitude = _.get(locationValues, 'lng', '1')
                                  let addressLine1 =
                                    (_.get(locationDetails, 'houseNumber', '')
                                      ? _.get(locationDetails, 'houseNumber', '') + ', '
                                      : '') +
                                    (_.get(locationDetails, 'houseName', '')
                                      ? _.get(locationDetails, 'houseName', '')
                                      : '');

                                  let addressLine2 =
                                    (_.get(locationDetails, 'street', '')
                                      ? _.get(locationDetails, 'street', '') + ', '
                                      : '') +
                                    (_.get(locationDetails, 'subSubLocality', '')
                                      ? _.get(locationDetails, 'subSubLocality', '')
                                      : '');

                                  let addressLine3 =
                                    (_.get(locationDetails, 'subLocality', '')
                                      ? _.get(locationDetails, 'subLocality', '') + ', '
                                      : '') +
                                    (_.get(locationDetails, 'locality', '')
                                      ? _.get(locationDetails, 'locality', '')
                                      : '');

                                  formikBag.setFieldValue('place_id', Eloc);
                                  formikBag.setFieldValue('address_lattitude', latitude.toString());
                                  formikBag.setFieldValue('address_longitude', longitude.toString());
                                  // let locationCode = (_.get(locationDetails.addressTokens, 'locality')|| '') + ' ' + (_.get(locationDetails.addressTokens, 'street') || '') + ' ' + (_.get(locationDetails.addressTokens, 'city')|| '') + ' ' + (_.get(locationDetails.addressTokens, 'state') || '') + ' ' + (_.get(locationDetails.addressTokens, 'pincode')|| '' ) ;
                                  formikBag.setFieldValue('pin_code', _.get(locationDetails, 'pincode') || '');
                                  formikBag.setFieldValue('address_line1', addressLine1);
                                  formikBag.setFieldValue('address_line2', addressLine2);
                                  formikBag.setFieldValue('address_line3', addressLine3);
                                  formikBag.setFieldValue('city_code', _.get(locationDetails, 'city') || '');
                                  formikBag.setFieldValue('locality', _.get(locationDetails, 'locality') || '');
                                  formikBag.setFieldValue(
                                    'village_code',
                                    _.get(locationDetails, 'village') ||
                                    _.get(locationDetails, 'city') ||
                                    _.get(locationDetails, 'subDistrict') ||
                                    '',
                                  );
                                  formikBag.setFieldValue('city_code', _.get(locationDetails, 'city') || '');
                                  formikBag.setFieldValue('district_code', _.get(locationDetails, 'district') || '');
                                  formikBag.setFieldValue('state_code', _.get(locationDetails, 'state') || '');
                                  // formikBag.setFieldValue(
                                  //   'location_code',
                                  //   (_.get(locationDetails, 'placeName', '')
                                  //     ? _.get(locationDetails, 'placeName', '') + ', '
                                  //     : '') + _.get(locationDetails, 'placeAddress', ''),
                                  // );
                                  if (locationValues) {
                                    // locationDetails = locationValues[0] || {};
                                    // setLocDetailsValue(locationValues[0]?.addressTokens);
                                    setLocDetailsEloc(locationValues[0]?.eLoc);
                                  }
                                }}
                              />
                            )}
                          </div>
                        </div>
                      </div> */}
                      <div className="row form__form-group">
                        <div className="col">
                          {/* <div className="form-group-field"> */}
                            <span className="form-group-label">Location</span>
                            <span class="required">*</span>
                            <Row style={{ marginLeft: 0 }}>
                            <div className="form-group-field" style={{ width: '85%' }}>
                              <Field
                                name={`location_code`}
                                component={isFacilitator ? searchLocationFacilitator : searchLocation}
                                initialDisplayText={_.get(formikBag, 'initialValues.location_code') || ''}
                                disabled={!_.isEmpty(farmDetails) && farmDetails?.can_edit === false}
                                cb={async (locationValues) => {
                                  let locationDetails = locationValues[0] || {};
                                  //call this if statement at the end because sometimes setLocDetailsValue will unmount the component and hence will show old data
                                  // if (locationValues) {
                                  //   locationDetails = locationValues[0] || {};
                                  //   // setLocDetailsValue(locationValues[0]?.addressTokens);
                                  //   setLocDetailsEloc(locationValues[0]?.eLoc);
                                  // }

                                  // let formikValues = formikBag.values || {};
                                  // formikBag.setValues({
                                  //   ...formikValues,
                                  //   ...locationDetails.addressTokens,
                                  // });
                                  let addressLine1 =
                                    (_.get(locationDetails, 'addressTokens.houseNumber', '')
                                      ? _.get(locationDetails, 'addressTokens.houseNumber', '') + ', '
                                      : '') +
                                    (_.get(locationDetails, 'addressTokens.houseName', '')
                                      ? _.get(locationDetails, 'addressTokens.houseName', '')
                                      : '');

                                  let addressLine2 =
                                    (_.get(locationDetails, 'addressTokens.street', '')
                                      ? _.get(locationDetails, 'addressTokens.street', '') + ', '
                                      : '') +
                                    (_.get(locationDetails, 'addressTokens.subSubLocality', '')
                                      ? _.get(locationDetails, 'addressTokens.subSubLocality', '')
                                      : '');

                                  let addressLine3 =
                                    (_.get(locationDetails, 'addressTokens.subLocality', '')
                                      ? _.get(locationDetails, 'addressTokens.subLocality', '') + ', '
                                      : '') +
                                    (_.get(locationDetails, 'addressTokens.locality', '')
                                      ? _.get(locationDetails, 'addressTokens.locality', '')
                                      : '');

                                  // let locationCode = (_.get(locationDetails.addressTokens, 'locality')|| '') + ' ' + (_.get(locationDetails.addressTokens, 'street') || '') + ' ' + (_.get(locationDetails.addressTokens, 'city')|| '') + ' ' + (_.get(locationDetails.addressTokens, 'state') || '') + ' ' + (_.get(locationDetails.addressTokens, 'pincode')|| '' ) ;
                                  formikBag.setFieldValue(
                                    'pin_code',
                                    _.get(locationDetails.addressTokens, 'pincode') || '',
                                  );
                                  formikBag.setFieldValue('address_line1', addressLine1);
                                  formikBag.setFieldValue('address_line2', addressLine2);
                                  formikBag.setFieldValue('address_line3', addressLine3);
                                  formikBag.setFieldValue(
                                    'city_code',
                                    _.get(locationDetails.addressTokens, 'city') || '',
                                  );
                                  formikBag.setFieldValue(
                                    'locality',
                                    _.get(locationDetails.addressTokens, 'locality') || '',
                                  );
                                  formikBag.setFieldValue(
                                    'village_code',
                                    _.get(locationDetails.addressTokens, 'village') ||
                                      _.get(locationDetails.addressTokens, 'city') ||
                                      _.get(locationDetails.addressTokens, 'subDistrict') ||
                                      '',
                                  );
                                  formikBag.setFieldValue(
                                    'district_code',
                                    _.get(locationDetails.addressTokens, 'district') || '',
                                  );
                                  formikBag.setFieldValue(
                                    'state_code',
                                    _.get(locationDetails.addressTokens, 'state') || '',
                                  );
                                  formikBag.setFieldValue('place_id', _.get(locationDetails, 'eLoc') || '');

                                  formikBag.setFieldValue(
                                    'location_code',
                                    (_.get(locationDetails, 'placeName', '')
                                      ? _.get(locationDetails, 'placeName', '') + ', '
                                      : '') + _.get(locationDetails, 'placeAddress', ''),
                                  );
                                  if (locationValues) {
                                    // locationDetails = locationValues[0] || {};
                                    // setLocDetailsValue(locationValues[0]?.addressTokens);
                                    setLocDetailsEloc(locationValues[0]?.eLoc);
                                    await setLatLong(locationValues[0]?.eLoc, formikBag);
                                  }
                                }}
                              />
                              </div>
                              <div className="ml-auto mr-auto">
                              <div className="icon-group">
                                <Popover content={"Click to select location"}>
                                  <span
                                    className="icon edit-icon"
                                    onClick={!_.isEmpty(farmDetails) && farmDetails?.can_edit === false ? false : () => setIsEditorOpen(true)}
                                    >
                                    <MUIButton
                                      variant="text"
                                      type="icon"
                                      Icon={LocationOnIcon}
                                      color={!_.isEmpty(farmDetails) && farmDetails?.can_edit === false ? 'rgba(0, 0, 0, 0.26)' : "red"}
                                      disabled={!_.isEmpty(farmDetails) && farmDetails?.can_edit === false}
                                      onClick={() => setIsEditorOpen(true)}>
                                    </MUIButton>
                                  </span>
                                </Popover>
                              </div>
                            </div>
                            </Row>
                            <div className="mt-2">
                              <div className="form-group-field">
                                <div>
                                  {/* <MUIButton
                                    variant="text"
                                    type="icon"
                                    Icon={LocationOnIcon}
                                    color="red"
                                    onClick={() => setIsEditorOpen(true)}></MUIButton>
                                  <MUITypography
                                    variant="button"
                                    className="cursor-pointer"
                                    onClick={() => setIsEditorOpen(true)}>
                                    Click to select location
                                  </MUITypography> */}
                                </div>
                                {isEditorOpen && (
                              <Field
                                name="latitude"
                                component={LocationModal}
                                entity="RoadTypeEnum"
                                isOpen={isEditorOpen}
                                toggle={followToggle}
                                cb={async (locationValues) => {
                                  let locationDetails = locationValues || {};
                                  let params = {
                                    Latitude: locationDetails?.lat,
                                    Longitude: locationDetails?.lng,
                                  };
                                  const resData = await locationElocService.getReverseLocationDetails(params);
                                  setFieldValue('pin_code', _.get(resData?.results?.[0], 'pincode') || '');
                                  setFieldValue('city_code', _.get(resData?.results?.[0], 'city') || '');
                                  let addressLine1 =
                                  (_.get(resData?.results?.[0], 'houseNumber', '')
                                    ? _.get(resData?.results?.[0], 'houseNumber', '') + ', '
                                    : '') +
                                  (_.get(resData?.results?.[0], 'houseName', '')
                                    ? _.get(resData?.results?.[0], 'houseName', '')
                                    : '');

                                let addressLine2 =
                                  (_.get(resData?.results?.[0], 'street', '')
                                    ? _.get(resData?.results?.[0], 'street', '') + ', '
                                    : '') +
                                  (_.get(resData?.results?.[0], 'sub_sub_locality', '')
                                    ? _.get(resData?.results?.[0], 'sub_sub_locality', '')
                                    : '');

                                let addressLine3 =
                                  (_.get(resData?.results?.[0], 'sub_locality', '')
                                    ? _.get(resData?.results?.[0], 'sub_locality', '') + ', '
                                    : '') +
                                  (_.get(resData?.results?.[0], 'locality', '')
                                    ? _.get(resData?.results?.[0], 'locality', '')
                                    : '');

                                formikBag.setFieldValue('address_line1', addressLine1);
                                formikBag.setFieldValue('address_line2', addressLine2);
                                formikBag.setFieldValue('address_line3', addressLine3);
                                  setFieldValue(
                                    'district_code',
                                    _.get(resData?.results?.[0], 'district') || '',
                                  );
                                  setFieldValue(
                                    'village_code',
                                    _.get(resData?.results?.[0], 'village') ||
                                      _.get(resData?.results?.[0], 'city') ||
                                      _.get(resData?.results?.[0], 'subDistrict') ||
                                      '',
                                  );
                                  setFieldValue('state_code', _.get(resData?.results?.[0], 'state') || '');
                                  setFieldValue('place_id', _.get(locationDetails, 'eLoc') || '');
                                  setFieldValue('address_lattitude', `${locationDetails?.lat}`);
                                  setFieldValue('address_longitude', `${locationDetails?.lng}`);
                                  setFieldValue(
                                    'location_code',
                                    _.get(locationDetails, 'formatted_address', ''),
                                  );
                                  setFieldValue('location_code', _.get(locationDetails, 'formatted_address', ''));
                                }}
                              />
                            )}
                                <MUITypography variant="body2">{values.location_code}</MUITypography>
                              </div>
                              <ErrorMessage name="latitude" component="div" className="text-danger" />
                            {/* </div> */}
                          </div>
                        </div>
                      </div>
                      <div className="row form__form-group">
                        <div className="col">
                          <div className="form-group-field">
                            <span className="form-group-label">Address Line 1</span>
                            <span class="required">*</span>
                            <Field name={`address_line1`} component={FormikMaterialTextField} />
                          </div>
                        </div>
                      </div>
                      <div className="row form__form-group">
                        <div className="col">
                          <div className="form-group-field">
                            <span className="form-group-label">Address Line 2</span>
                            <Field name={`address_line2`} component={FormikMaterialTextField} />
                          </div>
                        </div>
                      </div>
                      <div className="row form__form-group">
                        <div className="col">
                          <div className="form-group-field">
                            <span className="form-group-label">Address Line 3</span>
                            <Field name={`address_line3`} component={FormikMaterialTextField} />
                          </div>
                        </div>
                      </div>
                      <div className="row form__form-group">
                        <div className="col">
                          <div className="form-group-field">
                            <span className="form-group-label">Village / District</span>
                            <MUITextField
                              fullWidth
                              size="small"
                              disabled
                              variant={'outlined'}
                              value={`${values.village_code} / ${values.district_code}`}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row form__form-group">
                        <div className="col">
                          <div className="form-group-field">
                            <span className="form-group-label">Pincode</span>
                            <span class="required">*</span>
                            <Field name={`pin_code`} disabled component={FormikMaterialTextField} />
                          </div>
                        </div>
                      </div>
                      <div className="row form__form-group">
                        <div className="col">
                          <div className="form-group-field">
                            <span className="form-group-label">State</span>
                            <MUITextField
                              fullWidth
                              size="small"
                              disabled
                              variant={'outlined'}
                              value={locDetailsValue.state || `${_.get(formikBag, 'values.state_code') || ''}`}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row form__form-group">
                        <div className="col">
                          <div className="form-group-field">
                            <span className="form-group-label">Country</span>
                            <MUITextField
                              fullWidth
                              size="small"
                              disabled
                              variant={'outlined'}
                              value={`${_.get(formikBag, 'values.country_code') || 'India'}`}
                            />
                          </div>
                        </div>
                      </div>

                      <FieldArray name="crops" component={CropFields} />
                    </div>
                  </div>

                  <div className="btn-wrapper">
                    <Button
                      color="primary"
                      type="button"
                      disabled={formikBag.isSubmitting}
                      onClick={formikBag.handleSubmit}
                      className="next bfc-button-next">
                      {formikBag.isSubmitting && (
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      )}
                      {isEdit ? 'Update' : 'Save'}
                    </Button>
                    {/* {utils.debug(formikBag.values)} */}
                  </div>
                </Form>
              </div>
            </div>
          );
        }}
      </Formik>
    </React.Fragment>
  );
};

export default FarmCropsForm;
