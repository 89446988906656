import qs from 'query-string';
import * as yup from 'yup';

import * as fetch from 'services/fetch';
import utils from 'lib/utils';

const BASE_URL = `/BioFuelRequirementOffers/api`;

/** api fields
{
address_id: 1
commodity_deal_master_id: null
commodity_req_offer_id: 10012
commodity_req_offer_response_id: 50
company_affiliate_enum: 0
company_affiliate_enum_string: null
created_by: 15
created_datetime: "2020-11-19t11:56:13.907"
end_date: "2020-12-16t18:30:00"
offer_bid_price: 600
offer_bid_qty: 10000
offer_bid_qty_uom_code: "ounce"
offer_bid_qty_uom_id: 12
offer_bid_type_enum: 1
offer_bid_type_enum_string: "offer"
periodicity_enum: 3
periodicity_enum_string: "perweek"
person_id: 15
req_offer_response_status_enum: 1
req_offer_response_status_enum_string: "submitted"
total_offer_bid_qty: 10000
transportation_cost: 100
user_type_enum: 2
user_type_enum_string: "non enterprise"
}
*/

const RequirmentOfferDataSchema = yup
  .object()
  .shape({
    commodity_req_offer_id: yup.number().required().positive().integer(), // PK
    offer_bid_qty: yup.number().required().positive().integer(),
    offer_bid_uom_id: yup.number().required().positive().integer(),
    start_date: yup.date().required(),
    end_date: yup.date().required(),
    offer_bid_price: yup.number().required().positive().integer(),
    total_offer_bid_qty: yup.number().required().positive().integer(),
    periodicity_enum: yup.number().required().positive().integer(),
    address_id: yup.number().required().positive().integer(),
    transportation_cost: yup.number().required().positive().integer(),
    // person_id: yup.mixed().oneOf([1, 2]).required(),
    // user_type_enum: yup.mixed().oneOf([1, 2]).required(),
    // company_affiliate_enum: yup.mixed().oneOf([1, 2]).required(),
  })
  .defined();

const RequirmentOfferLabelSchema = yup
  .object()
  .shape({
    commodity_req_offer_response_id: yup.number().required().positive().integer(),
    offer_bid_uom_code: yup.string().defined(),
    offer_bid_qty_uom_id: yup.number().required().positive().integer(),
    offer_bid_qty_uom_code: yup.string().defined(),
    offer_bid_type_enum: yup.number().required().positive().integer(),
    offer_bid_type_enum_string: yup.string().defined(),
    periodicity_enum_string: yup.string().defined(),
    req_offer_response_status_enum: yup.number().required().positive().integer(),
    req_offer_response_status_enum_string: yup.string().defined(),
    address_code: yup.string().defined(),
    created_by: yup.number().required().positive().integer(),
    person_id: yup.number().required().positive().integer(),
    user_type_enum_string: yup.string().defined(),
    created_datetime: yup.date().defined(),
    end_date: yup.date().defined(),
  })
  .defined();

const RequirmentOfferMixedSchema = RequirmentOfferDataSchema.concat(RequirmentOfferLabelSchema);
const RequirmentOfferMixedArraySchema = yup.array().of(RequirmentOfferMixedSchema);

export type RequirmentOfferDataType = yup.InferType<typeof RequirmentOfferDataSchema>;
export type RequirmentOfferLabelType = yup.InferType<typeof RequirmentOfferLabelSchema>;
export type RequirmentOfferMixedType = yup.InferType<typeof RequirmentOfferMixedSchema>;

const SaveOffer = async (payload) => {
  const finalPayload = {
    ...payload,
    person_id: globalThis?.user?.userid,
    user_type_enum: globalThis?.user?.profile?.person?.type_of_individual_enum === 4 ? 1 : 2,
    company_affiliate_enum: 1, // @TODO: hardcoded
  };

  const url = `${BASE_URL}/Offer/SaveOffer_ELOC_PhaseTwo`;

  // let validtion = RequirmentOfferDataSchema.validateSync(payload);

  return await fetch.create<RequirmentOfferMixedType>(url, finalPayload);
};

const FetchAllOffersAgainstEntityId = async (filters = {}, page: number, pageSize: number) => {
  // const urlParams = {
  //   entity_id: globalThis?.user?.userid,
  //   user_type_enum: globalThis?.user?.profile?.person?.type_of_individual_enum === 4 ? 1 : 2,
  //   offset: (page - 1) * pageSize,
  //   page_size: pageSize,
  // };

  const finalPayload = {
    filters: {
      ...filters,
      // preferred_blocked_enum: 1,
    },
    entity_id: globalThis?.user?.userid,
    user_type_enum: globalThis?.user?.profile?.person?.type_of_individual_enum === 4 ? 1 : 2,
    company_affiliate_enum: 1, // @TODO: hardcoded
    offset: (page - 1) * pageSize,
    page_size: pageSize,
  };

  const acl = utils.getACL(_.get(window, 'user') || {});
  const companyId = _.get(window, 'user.profile.person.company_id') || 0;
  if (acl.isCompany) {
    finalPayload['entity_id'] = companyId;
    finalPayload['user_type_enum'] = 1;
    finalPayload['company_affiliate_enum'] = 1;
  } else {
    finalPayload['entity_id'] = _.get(window, 'user.userid') || 0;
    finalPayload['user_type_enum'] = 2;
  }

  const url = `${BASE_URL}/Offer/FetchAllOffersAgainstEntityId_ELOC`;

  const response = await fetch.create(url, finalPayload);

  return response;
};

const FetchOfferDetailsAgainstId = async (commodityReqOfferResponseId: number) => {
  // /api/Offer/FetchOfferDetailsAgainstId

  const urlParams = {
    commodity_req_offer_response_id: commodityReqOfferResponseId,
    person_id: _.get(window, 'user.userid') || 0,
  };
  const qs = utils.getQs(urlParams);
  const url = `${BASE_URL}/Offer/FetchOfferDetailsAgainstId_ELOC?${qs}`;

  const response = await fetch.fetchOne<RequirmentOfferMixedType>(url);

  // RequirmentOfferMixedSchema.validateSync(response.data);

  return response;
};

const FetchAutoOfferDetailsAgainstId = async (id: number) => {
  // /api/Offer/FetchOfferDetailsAgainstId

  const urlParams = {
    commodity_req_offer_id: 15222, //id,
    person_id: _.get(window, 'user.userid') || 0,
  };
  const qs = utils.getQs(urlParams);
  const url = `${BASE_URL}/PlainOffer/FetchPlainOfferAgainstId_ELOC?${qs}`;

  const response = await fetch.fetchOne<RequirmentOfferMixedType>(url);

  // RequirmentOfferMixedSchema.validateSync(response.data);

  return response;
};

const WithdrawOfferById = async (commodityReqOfferResponseId) => {
  // /api/Offer/WithdrawOfferById

  const urlParams = {
    Commodity_Req_Offer_Response_Id: commodityReqOfferResponseId,
    Person_Id: globalThis?.user?.userid,
  };
  const qs = utils.getQs(urlParams);
  const url = `${BASE_URL}/Offer/WithdrawOfferById?${qs}`;

  const updated = await fetch.update<Object>(url, {});

  return updated;
};

const AcceptOffer = async (commodityReqOfferResponseId) => {
  // /api/Requirement/AcceptOffer

  const urlParams = {
    Commodity_Req_Offer_Response_Id: commodityReqOfferResponseId,
    Person_Id: globalThis?.user?.userid,
  };
  const qs = utils.getQs(urlParams);
  const url = `${BASE_URL}/Requirement/AcceptOffer?${qs}`;

  const updated = await fetch.update<Object>(url, {});

  return updated;
};
const RejectOfferById = async (commodityReqOfferResponseId) => {
  // /api/Requirement/RejectOfferById

  const urlParams = {
    Commodity_Req_Offer_Response_Id: commodityReqOfferResponseId,
    Person_Id: globalThis?.user?.userid,
  };
  const qs = utils.getQs(urlParams);
  const url = `${BASE_URL}/Requirement/RejectOfferById?${qs}`;

  const updated = await fetch.update<Object>(url, {});

  return updated;
};

const FetchOffersAgainstRequirementId = async (
  commodityReqOfferId: string,
  filters,
  page: number,
  pageSize: number,
) => {
  console.log('CommodityReqOfferId', commodityReqOfferId);
  const cleanedCommodityReqOfferId = commodityReqOfferId.trim();
  const finalPayload = {
    filters: {
      ...filters,
      // // commodity_req_offer_id: commodityReqOfferId,
      // preferred_blocked_enum: 1,
    },
    entity_id: cleanedCommodityReqOfferId,
    invoking_person_id: globalThis?.user?.userid,
    offset: (page - 1) * pageSize,
    page_size: pageSize,
    // invoking_person_id: _.get(window, 'user.userid'),
  };

  // const url = `${BASE_URL}/Requirement/FetchOffersAgainstRequirementId_ELOC`;
  const url = `${BASE_URL}/AutoBidOffer/FetchAutoAndRegularBidOffers`;

  const listData = await fetch.create(url, finalPayload);

  return listData;
};

export default {
  SaveOffer,
  FetchOffersAgainstRequirementId,
  FetchOfferDetailsAgainstId,
  FetchAllOffersAgainstEntityId,

  //offer
  AcceptOffer,
  RejectOfferById,
  WithdrawOfferById,

  FetchAutoOfferDetailsAgainstId,
};
