import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Row, Col } from 'reactstrap';
import classNames from 'classnames';
import { Form, Formik, Field, getIn } from 'formik';
import _ from 'lodash';
import FormikMaterialTextField from './FormikMaterialTextField';
import { useSelector, useDispatch } from 'react-redux';
import { locationElocService } from 'services';
// import './locationModal.scss';

const LocationModalComponent = (props) => {
  const {
    isOpen,
    toggle,
    color,
    btn,
    form: { touched, errors, values, setFieldValue },
    colored,
    header,
    cb,
  } = props;
  const { isSubmitting } = props;
  const modalClass = classNames({
    'modal-dialog--colored': colored,
    'modal-dialog--header': header,
  });

  // const {
  //   field,
  //   form: { touched, errors },
  //   variant,
  //   disabled,
  //   cb,
  //   ...rest
  // } = props;

  // const hasError = !!(getIn(touched, field.name) && getIn(errors, field.name));
  // const errText = hasError ? getIn(errors, field.name) : '';
  const [tokenOAuth, setTokenOAuth] = useState('');

  const tokenState = useSelector((state) => state.locationEloc);
  const token = _.get(tokenState, 'entities.undefined');
  // setTokenOAuth(token);
  const [inputValue, setInputValue] = useState('');
  // useEffect(() => {
  //   setInputValue(field.value);
  // }, [field.value]);

  const handleInputChange = (e) => {
    let value = e.target.value;
    setInputValue(value);
  };
  const getLocationDetails = async (params) => {
    const resData = await locationElocService.getLocationDetails(params);
    return resData;
  };

  useEffect(() => {
    // const script = document.createElement('script');

    // script.src = `https://apis.mapmyindia.com/advancedmaps/api/${token}/map_sdk_plugins`;
    // script.async = true;

    function loadScript(url) {
      return new Promise(function (resolve, reject) {
        let script = document.createElement('script');
        script.src = url;
        script.async = false;
        script.onload = function () {
          resolve(url);
        };
        script.onerror = function () {
          reject(url);
        };
        document.body.appendChild(script);
        setTimeout(() => {
          document.body.removeChild(script);
        }, 100);
      });
    }

    let scripts = [
      `https://apis.mapmyindia.com/advancedmaps/v1/${token}/map_load?v=1.5`,
      `https://apis.mapmyindia.com/advancedmaps/api/${token}/map_sdk_plugins`,

    ];

    // save all Promises as array
    let promises = [];
    scripts.forEach(function (url) {
      promises.push(loadScript(url));
    });

    Promise.all(promises)
      .then(function () {
        var map = new MapmyIndia.Map(document.getElementById('businessMap'), {
          center: [values.address_lattitude || 18.5313, values.address_longitude || 73.8498],
          zoom: 15,
          search: false,
        });

        /*Place Picker plugin initialization*/
        var options = {
          map: map,
          callback: callback_method,
          geolocation: true,
          closeBtn: false,
          /*
              location:{lat:28.8787,lng:77.08888},//to open that location on map on initailization
              closeBtn:true,
              closeBtn_callback:closeBtn_callback,
              search:true,
              topText:'Location Search',
              pinImage:'pin.png', //custom pin image
              pinHeight:40
              */
        };
        var picker = new MapmyIndia.placePicker(options);
        async function callback_method(data) {
          let location_code = data.formatted_address || '';
          if (data.lat === '' || data.lng === '') {
            let params = {
              place_id: data.eLoc,
              bearer_token: token,
            }
            let res = await getLocationDetails(params);
            setFieldValue('address_lattitude', parseFloat(res.latitude || 0));
            setFieldValue('address_longitude', parseFloat(res.longitude || 0));
            setFieldValue('location_code', location_code);
            let newdata = {
              ...data,
              lat: res.latitude.toString(),
              lng: res.longitude.toString(),
            }
            cb(newdata);
            toggle();
          } else {
            setFieldValue('address_lattitude', parseFloat(data.lat || 0));
            setFieldValue('address_longitude', parseFloat(data.lng || 0));
            setFieldValue('location_code', location_code);
            cb(data);
            toggle();
          }
        }
      })
      .catch(function (script) {
        console.log(script + ' failed to load');
      });

    // script.onload = () => {
    //   // Place search plugin

    //   let optional_config = {
    //     location: [28.61, 77.23],
    //     bridge: false,
    //     tokenizeAddress: true,
    //     geolocation: false,
    //   };

    //   window.MapmyIndia.search(document.getElementById('auto'), optional_config, function (data) {
    //     //pass data to callback function

    //     if (!data.hasOwnProperty('error')) {
    //       if (
    //         !data[0]?.addressTokens?.district ||
    //         (!data[0]?.addressTokens?.village && !data[0]?.addressTokens?.city && !data[0]?.addressTokens?.subDistrict)
    //       ) {
    //         notifications.show({
    //           type: 'error',
    //           message: 'Village or Town not found. Please search your Village/ Town again',
    //           key: 'req-form',
    //         });
    //         cb({});
    //       } else {
    //         cb(data);
    //       }
    //     }

    //     // if(data[0].addressTokens.district != ""){
    //     // cb(data);
    //     // }
    //   });
    // };
    // document.head.appendChild(script);
    // // remove script after adding
    // setTimeout(() => {
    //   document.head.removeChild(script);
    // }, 100);
  }, [token]);

  return (
    <div className="bfc-post-text-editor-modal">
      <div className="bfc-follow-editor-section">
        <Button className="d-none" color={color} onClick={toggle}>
          {btn}
        </Button>
        <Modal
          isOpen={isOpen}
          toggle={toggle}
          modalClassName="ltr-support theme-light"
          className={`map-modal modal-dialog--${color} ${modalClass} `}>
          <div className="modal__header location-modal__header">
            <button aria-label="Close" className=" close" type="button" onClick={toggle}>
              <span aria-hidden>×</span>
            </button>
          </div>
          <div className="modal__body">
            <div className="locationModal-map profile-map__modal p-15">
              <div className="">
                <div id="businessMap" name="businessMap" style={{ backgroundColor: 'white', height: '100%', minHeight: '600px', width: '100%' }}>
                  {/* <span style={{ color: '#f44336', fontSize: '13px', fontFamily: 'Segoe UI' }}>
                  {errors.location_code}
                </span> */}
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

LocationModalComponent.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  color: PropTypes.string.isRequired,
  colored: PropTypes.bool,
  header: PropTypes.bool,
  btn: PropTypes.string.isRequired,
  // rtl: RTLProps.isRequired,
};

LocationModalComponent.defaultProps = {
  title: '',
  message: '',
  colored: false,
  header: false,
  color: 'primary',
};

export default LocationModalComponent;
