// ** MUI imports
import { styled } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import _ from 'lodash';
import { colord as colorFormat } from 'colord';
import getColor from '../colors';

// ** Util Import

export const hexToRGBA = (hexCode: string, opacity: number) => {
  let hex = hexCode.replace('#', '');

  if (hex.length === 3) {
    hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
  }

  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};

const DatePickerWrapper = styled(Box)(({ theme }) => {
  const config = _.get(window, 'user.profile.ui_config');
  const bgColor = getColor(config?.theme_color);
  const isLight = colorFormat(bgColor).isLight();
  const textColor = isLight ? colorFormat(bgColor).darken(1).toHex() : colorFormat(bgColor).lighten(1).toHex();
  const bgColorHex = colorFormat(bgColor).toHex();
  const isGradient = bgColor.includes('gradient');

  return {
    '& .react-datepicker-popper': {
      zIndex: 120,
      // width: '377px',
    },
    '& .react-datepicker-popper:has(.react-datepicker__month-container), & .react-datepicker-popper:has(.react-datepicker__time-container)':
      {
        width: 'auto',
      },
    '& .react-datepicker-popper:has(.react-datepicker__month-container + .react-datepicker__time-container)': {
      width: '377px',
    },
    '& .react-datepicker-wrapper': {
      width: '100%',
    },
    '& .react-datepicker': {
      border: 'none',
      boxShadow: theme.shadows[7],
      // padding: theme.spacing(2, 0),
      color: theme.palette.text.primary,
      borderRadius: theme.shape.borderRadius,
      // fontFamily: theme.typography.fontFamily,
      fontFamily: 'Segoe UI',
      backgroundColor: theme.palette.background.paper,
      letterSpacing: '1px',
      '& .react-datepicker__header': {
        padding: '0',
        border: 'none',
        fontWeight: 'normal',
        // backgroundColor: theme.palette.background.paper,
        // backgroundColor: bgColor,
        background: isGradient
          ? bgColor
          : `repeating-linear-gradient(45deg, ${bgColor}, ${hexToRGBA(bgColorHex, 0.9)} 100px)`,
        '& .react-datepicker__day-names': {
          padding: '6px 0',
        },
        '& .react-datepicker__day-name': {
          margin: 0,
          fontSize: '13px',
          letterSpacing: '1px',
          color: textColor,
        },
      },
      '& .react-datepicker-year-header': {
        lineHeight: 2.5,
        marginBottom: '0.5rem',
        // color: theme.palette.text.primary,
        color: textColor,
        fontSize: '22px',
        fontWeight: 'bold',
      },
      '& .react-datepicker__triangle': {
        display: 'none',
      },
      '& > .react-datepicker__navigation': {
        // top: theme.spacing(3),
        top: '12px',
        '&.react-datepicker__navigation--previous': {
          border: 'none',
          backgroundImage: `${"url('data:image/svg+xml,%3Csvg xmlns=\\'http://www.w3.org/2000/svg\\' style=\\'width:24px;height:24px\\' viewBox=\\'0 0 24 24\\'%3E%3Cpath fill=\\'currentColor\\' d=\\'M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z\\' /%3E%3C/svg%3E')"
            .replace('currentColor', textColor)
            .replace('#', '%23')}`,
          height: '32px',
          width: '32px',
          left: '200px',
          '& .react-datepicker__navigation-icon': {
            display: 'none',
          },
        },
        '&.react-datepicker__navigation--next': {
          border: 'none',
          backgroundImage: `${"url('data:image/svg+xml,%3Csvg xmlns=\\'http://www.w3.org/2000/svg\\' style=\\'width:24px;height:24px\\' viewBox=\\'0 0 24 24\\'%3E%3Cpath fill=\\'currentColor\\' d=\\'M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z\\' /%3E%3C/svg%3E')"
            .replace('currentColor', textColor)
            .replace('#', '%23')}`,
          height: '32px',
          width: '32px',
          right: '4px',
          '& .react-datepicker__navigation-icon': {
            display: 'none',
          },
        },
        '&.react-datepicker__navigation--next--with-time': {
          right: '122px',
        },
        '&:focus, &:active': {
          outline: 0,
        },
      },
      '& .react-datepicker__current-month': {
        lineHeight: 2.7,
        fontSize: '1rem',
        fontWeight: 'bold',
        letterSpacing: '1px',
        // marginBottom: theme.spacing(1),
        // color: theme.palette.text.primary,
        color: textColor,
        // display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'none',
      },
      '& .react-datepicker__day-name': {
        lineHeight: 1.5,
        width: '2.25rem',
        fontSize: '0.75rem',
        letterSpacing: '0.4px',
        color: theme.palette.text.secondary,
        fontWeight: 'bold',
      },
      '& .react-datepicker__day': {
        margin: 0,
        width: '2.25rem',
        lineHeight: 2.75,
        height: '2.25rem',
        borderRadius: '50%',
        color: theme.palette.text.primary,
        fontWeight: 'bold',
        '&.react-datepicker__day--selected, &.react-datepicker__day--keyboard-selected': {
          color: theme.palette.common.white,
          // backgroundColor: `${theme.palette.primary.main} !important`,
          backgroundColor: `${bgColor} !important`,
        },
        '&.react-datepicker__day--in-range, &.react-datepicker__day--in-selecting-range': {
          borderRadius: 0,
          // color: theme.palette.primary.main,
          color: bgColor,
          // backgroundColor: `${hexToRGBA(theme.palette.primary.main, 0.06)} !important`,
          backgroundColor: `${hexToRGBA(bgColor, 0.06)} !important`,
          '&:empty': {
            backgroundColor: 'transparent !important',
          },
        },
        '&.react-datepicker__day--selected.react-datepicker__day--in-selecting-range.react-datepicker__day--selecting-range-start, &.react-datepicker__day--selected.react-datepicker__day--range-start.react-datepicker__day--in-range, &.react-datepicker__day--range-start':
          {
            borderTopLeftRadius: '50%',
            borderBottomLeftRadius: '50%',
            color: theme.palette.common.white,
            // backgroundColor: `${theme.palette.primary.main} !important`,
            backgroundColor: `${bgColor} !important`,
          },
        '&.react-datepicker__day--range-end': {
          borderTopRightRadius: '50%',
          borderBottomRightRadius: '50%',
          color: theme.palette.common.white,
          // backgroundColor: `${theme.palette.primary.main} !important`,
          backgroundColor: `${bgColor} !important`,
        },
        '&:focus, &:active': {
          outline: 0,
        },
        '&.react-datepicker__day--outside-month': {
          height: 'auto',
        },
        '&.react-datepicker__day--outside-month, &.react-datepicker__day--disabled:not(.react-datepicker__day--selected)':
          {
            color: theme.palette.text.disabled,
            '&:hover': {
              backgroundColor: 'transparent',
            },
          },
        '&.react-datepicker__day--highlighted, &.react-datepicker__day--highlighted:hover': {
          color: theme.palette.success.main,
          backgroundColor: hexToRGBA(theme.palette.success.main, 0.12),
        },
        '&.react-datepicker__day--today': {
          fontWeight: 'bold',
        },
      },
      '& .react-datepicker__header__dropdown': {
        display: 'flex',
        // justifyContent: 'space-around',
        // justifyContent: 'center',
        // lineHeight: '2.7',
        padding: '8px 12px',
        borderBottom: `1px solid ${textColor}`,
        '& .react-datepicker__month-dropdown-container:not(:last-child)': {
          // marginRight: theme.spacing(8),
        },
        '& .react-datepicker__month-dropdown-container, & .react-datepicker__year-dropdown-container': {
          // marginBottom: theme.spacing(4),
          // marginBottom: '10px',
          fontWeight: 'bold',
        },
        '& .react-datepicker__month-read-view, .react-datepicker__year-read-view': {
          visibility: 'visible !important',
        },
        '& .react-datepicker__month-read-view--selected-month, & .react-datepicker__year-read-view--selected-year': {
          // fontSize: '0.875rem',
          fontSize: '22px',
          marginRight: theme.spacing(1),
          // color: theme.palette.text.primary,
          color: textColor,
        },
        '& .react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow, & .react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow':
          {
            // borderTopColor: theme.palette.text.secondary,
            borderTopColor: textColor,
            // borderRightColor: theme.palette.text.secondary,
          },
        '& .react-datepicker__month-read-view--down-arrow, & .react-datepicker__year-read-view--down-arrow': {
          // top: 4,
          // borderTopColor: theme.palette.text.disabled,
          borderTopColor: textColor,
          // borderRightColor: theme.palette.text.disabled,
          display: 'none',
        },
        '& .react-datepicker__month-dropdown, & .react-datepicker__year-dropdown': {
          paddingTop: theme.spacing(1.5),
          paddingBottom: theme.spacing(1.5),
          // borderColor: theme.palette.divider,
          borderColor: 'transparent',
          borderRadius: theme.shape.borderRadius,
          backgroundColor: theme.palette.background.paper,
          // boxShadow: theme.palette?.['mode'] === 'light' ? theme.shadows[8] : theme.shadows[9],
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 1fr',
          left: 0,
          top: '86px',
          width: '100%',
          height: '232px',
          overflow: 'hidden',
          // gap: '8px'
        },
        '& .react-datepicker__month-option, & .react-datepicker__year-option': {
          // paddingTop: theme.spacing(0.5),
          // paddingBottom: theme.spacing(0.5),
          padding: '17.4px 0',
          height: '50px',
          width: '50px',
          lineHeight: 1.2,
          borderRadius: '50%',
          '&:hover': {
            // backgroundColor: theme.palette.action.hover,
            backgroundColor: hexToRGBA(bgColor, 0.2),
          },
        },
        '& .react-datepicker__month-option.react-datepicker__month-option--selected_month': {
          // backgroundColor: hexToRGBA(theme.palette.primary.main, 0.08),
          backgroundColor: hexToRGBA(bgColor, 1),
          // borderRadius: '4px',
          color: textColor,
          '&:hover': {
            // backgroundColor: hexToRGBA(theme.palette.primary.main, 0.12),
            backgroundColor: hexToRGBA(bgColor, 0.8),
          },
          '& .react-datepicker__month-option--selected': {
            display: 'none',
          },
        },
        '& .react-datepicker__year-option:first-child, .react-datepicker__year-option:last-child:has(a)': {
          position: 'absolute',
          left: '0',
          top: '44%',
          transform: 'rotate(-90deg)',
          display: 'inline-block',
          width: '24px',
          height: '24px',
          padding: '12px 0 0 0',
          borderRadius: '50%',
          '&:hover': {},
        },
        '& .react-datepicker__year-option:last-child:has(a)': {
          right: '-237px',
          padding: '3px 0 0 0 !important',
        },
        '& .react-datepicker__year-option.react-datepicker__year-option--selected_year': {
          // backgroundColor: hexToRGBA(theme.palette.primary.main, 0.08),
          backgroundColor: hexToRGBA(bgColor, 1),
          borderRadius: '50%',
          color: textColor,
          height: '50px',
          width: '50px',
          lineHeight: '1.2',
          '&:hover': {
            // backgroundColor: hexToRGBA(theme.palette.primary.main, 0.12),
            backgroundColor: hexToRGBA(bgColor, 0.12),
          },
          '& .react-datepicker__year-option--selected': {
            display: 'none',
          },
        },
        '& .react-datepicker__year-option': {
          // TODO: Remove some of the following styles for arrow in Year dropdown when react-datepicker give arrows in Year dropdown
          '& .react-datepicker__navigation--years-upcoming': {
            width: 9,
            height: 9,
            borderStyle: 'solid',
            borderWidth: '3px 3px 0 0',
            transform: 'rotate(-45deg)',
            borderTopColor: theme.palette.text.disabled,
            borderRightColor: theme.palette.text.disabled,
            margin: `${theme.spacing(2.75)} auto ${theme.spacing(0)}`,
          },
          '&:hover .react-datepicker__navigation--years-upcoming': {
            borderTopColor: theme.palette.text.secondary,
            borderRightColor: theme.palette.text.secondary,
          },
          '& .react-datepicker__navigation--years-previous': {
            width: 9,
            height: 9,
            borderStyle: 'solid',
            borderWidth: '0 0 3px 3px',
            transform: 'rotate(-45deg)',
            borderLeftColor: theme.palette.text.disabled,
            borderBottomColor: theme.palette.text.disabled,
            margin: `${theme.spacing(0)} auto ${theme.spacing(2.75)}`,
          },
          '&:hover .react-datepicker__navigation--years-previous': {
            borderLeftColor: theme.palette.text.secondary,
            borderBottomColor: theme.palette.text.secondary,
          },
        },
      },
      '& .react-datepicker__year--container:has(div.react-datepicker-year-header) ~ .react-datepicker__navigation--previous':
        {
          left: '0 !important',
        },
      '& .react-datepicker__month': {
        marginTop: theme.spacing(1.2),
      },
      [theme.breakpoints.down('sm')]: {
        '& .react-datepicker__month': {
          marginLeft: 0,
          marginRight: 0,
          marginBottom: 0,
        },
      },
      '& .react-datepicker__month, & .react-datepicker__year': {
        '& .react-datepicker__month-text, & .react-datepicker__year-text, & .react-datepicker__quarter-text': {
          width: '50px',
          height: '50px',
          borderRadius: '50%',
          alignItems: 'center',
          display: 'inline-flex',
          justifyContent: 'center',
          margin: '2px 12px',
          fontWeight: 'bold',
          // '&:hover': {
          //   borderRadius: theme.shape.borderRadius,
          // },
          '&:focus, &:active': {
            outline: 0,
          },
        },
        '& .react-datepicker__quarter--selected, & .react-datepicker__year-text--selected, & .react-datepicker__month--selected, & .react-datepicker__quarter-text--keyboard-selected, & .react-datepicker__month-text--keyboard-selected, & .react-datepicker__year-text--keyboard-selected':
          {
            color: theme.palette.common.white,
            // borderRadius: theme.shape.borderRadius,
            backgroundColor: `${bgColor} !important`,
            borderRadius: '50%',
          },
        '& .react-datepicker__month--in-range, & .react-datepicker__quarter--in-range': {
          backgroundColor: `${bgColor} !important`,
          color: textColor,
        },
        '& .react-datepicker__week-number': {
          fontWeight: 600,
          color: theme.palette.text.primary,
        },
      },
      '& .react-datepicker__year': {
        margin: '0.4rem 1.4rem',
        '& .react-datepicker__year-text': {
          margin: '2px 0 !important',
        },
      },
      '& .react-datepicker__year-wrapper': {
        maxWidth: 205,
        justifyContent: 'center',
        margin: 'auto',
      },
      '& .react-datepicker__input-time-container': {
        display: 'flex',
        alignItems: 'center',
      },
      '& .react-datepicker__today-button': {
        borderRadius: '1rem',
        margin: '0 1rem 0.3rem',
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,
      },

      // ** Time Picker
      '& .react-datepicker__time-container': {
        borderLeftColor: theme.palette.divider,
      },
      '&.react-datepicker--time-only, & .react-datepicker__time-container': {
        width: '7rem',
        padding: theme.spacing(0),
        '& .react-datepicker-time__header': {
          // marginBottom: theme.spacing(1),
          padding: '30.3px 6px',
          // color: theme.palette.text.primary,
          color: textColor,
          fontSize: theme.typography.body2.fontSize,
        },

        '& .react-datepicker__time': {
          padding: '6px 0',
          background: theme.palette.background.paper,
          '& .react-datepicker__time-box .react-datepicker__time-list-item--disabled': {
            color: theme.palette.text.disabled,
          },
        },

        '& .react-datepicker__time-list-item': {
          lineHeight: 1.75,
          height: 'auto !important',
          marginLeft: theme.spacing(1.2),
          marginRight: theme.spacing(1.2),
          color: theme.palette.text.primary,
          borderRadius: theme.shape.borderRadius,
          fontWeight: 'bold',
          '&:focus, &:active': {
            outline: 0,
          },
          '&:hover': {
            backgroundColor: `${theme.palette.action.hover} !important`,
          },
          '&.react-datepicker__time-list-item--selected': {
            color: `${theme.palette.common.white} !important`,
            // backgroundColor: `${theme.palette.primary.main} !important`,
            backgroundColor: `${bgColor} !important`,
          },
        },

        '& .react-datepicker__time-box': {
          width: '100%',
        },
        '& .react-datepicker__time-list': {
          '&::-webkit-scrollbar': {
            width: 6,
          },

          /* Track */
          '&::-webkit-scrollbar-track': {
            background: colorFormat(bgColorHex)?.alpha(0.13),
          },

          /* Handle */
          '&::-webkit-scrollbar-thumb': {
            background: bgColor,
            borderRadius: '10px',
          },

          /* Handle on hover */
          '&::-webkit-scrollbar-thumb:hover': {
            background: colorFormat(bgColor).darken(50),
          },
        },
      },
      '&.react-datepicker--time-only .react-datepicker__time-container': {
        // width: 'calc(7rem - 2px)',
        width: 'calc(7rem - 0px)',
      },
      '& .react-datepicker__day:hover, & .react-datepicker__month-text:hover, & .react-datepicker__quarter-text:hover, & .react-datepicker__year-text:hover':
        {
          // backgroundColor: theme.palette.action.hover,
          backgroundColor: hexToRGBA(bgColor, 0.2),
        },
    },
    '& .react-datepicker__close-icon': {
      // paddingRight: theme.spacing(4),
      top: '3px',
      height: 'auto',
      '&:after': {
        width: 'unset',
        height: 'unset',
        fontSize: '1.6rem !important',
        color: theme.palette.text.primary,
        backgroundColor: 'transparent !important',
      },
    },
  };
});

export default DatePickerWrapper;
