import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

import utils from 'lib/utils';
import { muiComponentService } from 'services';

const adapter = createEntityAdapter({});

const slice = createSlice({
  name: 'MUIDropdownData',
  initialState: adapter.getInitialState({
    busy: false,
    data: {},
  }),
  reducers: {
    setData: (state, { payload }) => {
      const { entity, resData } = payload;
      state.data[entity] = resData;
    },
    setBusy: (state, { payload }) => ({ ...state, busy: payload }),
  },
});

const getDataFromApi = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  let { reqUrl } = params;
  const entity = reqUrl?.split('/')?.[reqUrl.split('/').length - 1];
  try {
    const resData = await muiComponentService.getDataFromApi(params);
    dispatch(slice.actions.setData({ entity, resData }));
    dispatch(slice.actions.setBusy(false));
    return resData;
  } catch (err) {
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const actions = {
  ...slice.actions,
  getDataFromApi,
};

export { actions };

export default slice.reducer;
