import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';
import { reqTransform, resTransform } from './transforms';

const { baseUrl } = config;

export const fetchList = async (params) => {
  try {
    const bodyParams = reqTransform.fetchList(params);
    const url = `${baseUrl}/BioFuelCommonUtilities/api/Configuration/FetchAllPreferredBlockedVendors`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchList(resData);
  } catch (err) {
    console.log('api-FetchAllPreferredBlockedVendors-fetchList->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchAllPreferredBlockedVendors');
    throw errors;
  }
};

export const create = async (params) => {
  try {
    let bodyParams = reqTransform.create(params);
    const url = `${baseUrl}/BioFuelCommonUtilities/api/Configuration/SaveAndUpdatePreferredBlockedVendor`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify([bodyParams]),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.create(resData);
  } catch (err) {
    console.log('SaveAndUpdatePreferredBlockedVendor-create->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'SaveAndUpdatePreferredBlockedVendor');
    throw errors;
  }
};

export const fetchAllVendorsList = async (params) => {
  try {
    const bodyParams = reqTransform.fetchAllVendorsList(params);
    const url = `${baseUrl}/BioFuelCommonUtilities/api/Configuration/FetchAllVendors`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchAllVendorsList(resData);
  } catch (err) {
    console.log('api-FetchAllVendors-fetchList->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchAllVendors');
    throw errors;
  }
};

export const destroy = async (params) => {
  try {
    const bodyParams = reqTransform.destroy(params);
    const url = `${baseUrl}/BioFuelCommonUtilities/api/Configuration/DeletePreferredBlockedVendor?${bodyParams}`;
    const res = await utils.fetch(url, {
      method: 'POST',
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.destroy(resData);
  } catch (err) {
    console.log('api-DeletePreferredBlockedVendor-destroy->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'DeletePreferredBlockedVendor');
    throw errors;
  }
};

export const fetchAllAutorizedSignatory = async (params) => {
  try {
    const bodyParams = reqTransform.fetchAllAutorizedSignatory(params);
    const url = `${baseUrl}/BioFuelCommonUtilities/api/Configuration/FetchAllAutorizedSignatory`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchAllAutorizedSignatory(resData);
  } catch (err) {
    console.log('api-FetchAllAutorizedSignatory-fetchList->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchAllAutorizedSignatory');
    throw errors;
  }
};

export const createSignatory = async (params) => {
  try {
    const formData = new FormData();

    formData.append('entity_Id', params.entity_id);
    formData.append('user_id', params.user_id);
    formData.append('person_company_affiliate_enum', params.person_company_affiliate_enum);
    formData.append('authorized_signatory_id', params.authorized_signatory_id);
    formData.append('signature_image', params.signature_image);
    formData.append('operation_enum', params.operation_enum);
    formData.append('signature_type_enum', params.signature_type_enum);
    formData.append('signerid', params.signerid);

    const url = `${baseUrl}/BioFuelCommonUtilities/api/Configuration/SaveAndUpdateAuthorizedSignatory`;
    const res = await utils.fetch(
      url,
      {
        method: 'POST',
        body: formData,
      },
      false,
    ).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.createSignatory(resData);
  } catch (err) {
    console.log('SaveAndUpdateAuthorizedSignatory-create->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'SaveAndUpdateAuthorizedSignatory');
    throw errors;
  }
};

export const deleteSignatory = async (id) => {
  try {
    let qsParams = reqTransform.deleteSignatory(id);
    const url = `${baseUrl}/BioFuelCommonUtilities/api/Configuration/DeleteAuthorizedSignatory?${qsParams}`;
    const res = await utils.fetch(url, {
      method: 'PUT',
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.deleteSignatory(resData);
  } catch (err) {
    console.log('DeleteAuthorizedSignatory-delete->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'DeleteAuthorizedSignatory');
    throw errors;
  }
};

export const fetchCompanyList = async () => {
  try {
    const url = `${baseUrl}/BioFuelCommonUtilities/api/Configuration/FetchCompaniesForAuthorizedSignatory`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchCompanyList(resData);
  } catch (err) {
    console.log('api-FetchCompaniesForAuthorizedSignatory-fetchList->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchCompaniesForAuthorizedSignatory');
    throw errors;
  }
};
export const fetchUserList = async () => {
  try {
    const url = `${baseUrl}/BioFuelCommonUtilities/api/Configuration/FetchUsersForAuthorizedSignatory`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchUserList(resData);
  } catch (err) {
    console.log('api-FetchUsersForAuthorizedSignatory-fetchList->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchUsersForAuthorizedSignatory');
    throw errors;
  }
};

export const fetchAllLoadingUnloading = async (params) => {
  try {
    const url = `${baseUrl}/BioFuelRegistration/api/Packaging/FetchAllPackagingOperationsForUser`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(params),
    }).ready;
    const resData = await utils.processApiRes(res);
    // return resTransform.fetchPackagingList(resData);
    return resData?.map((item, index) => ({ ...item, id: index }));
  } catch (err) {
    console.log('api-FetchAllPackagingOperationsForUser-fetchList->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchAllPackagingOperationsForUser');
    throw errors;
  }
};

export const createPackaging = async (params) => {
  try {
    let bodyParams = reqTransform.createPackaging(params);
    const url = `${baseUrl}/BioFuelRegistration/api/Packaging/SaveUpdatePackagingOperations`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.create(resData);
  } catch (err) {
    console.log('SaveUpdatePackagingOperations-create->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'SaveUpdatePackagingOperations');
    throw errors;
  }
};
export const deletePackaging = async (id) => {
  try {
    let qsParams = reqTransform.deletePackaging(id);
    const url = `${baseUrl}/BioFuelRegistration/api/Packaging/DeletePackagingOperationAgainstId?${qsParams}`;
    const res = await utils.fetch(url, {
      method: 'PUT',
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.deleteSignatory(resData);
  } catch (err) {
    console.log('DeleteAuthorizedSignatory-delete->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'DeletePackagingOperationAgainstId');
    throw errors;
  }
};

export const invoiceConfiguration = async (params) => {
  try {
    const bodyParams = reqTransform.invoiceConfiguration(params);
    const url = `${baseUrl}/BioFuelCommonUtilities/api/Configuration/SaveUpdateInvoiceNumberConfig`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.invoiceConfiguration(resData);
  } catch (err) {
    console.log('api-SaveUpdateInvoiceNumberConfig-->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'SaveUpdateInvoiceNumberConfig');
    throw errors;
  }
};

export const saveAndUpdateZohoAccountCreds = async (params) => {
  try {
    const qs = utils.getQs(params);
    const url = `${baseUrl}/BiofuelThirdPartyIntegration/api/Zoho/SaveAndUpdateZohoAccountCreds?${qs}`;
    const res = await utils.fetch(url, {
      method: 'POST',
    }).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err: any) {
    const errors = await utils.processApiErrors(err, '', 'SaveAndUpdateZohoAccountCreds');
    throw errors;
  }
};

export const createTokenForZohoAccount = async (params) => {
  try {
    const qs = utils.getQs(params);
    const url = `${baseUrl}/BiofuelThirdPartyIntegration/api/Zoho/CreateTokenForZohoAccount?${qs}`;
    const res = await utils.fetch(url, {
      method: 'POST',
    }).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err: any) {
    const errors = await utils.processApiErrors(err, '', 'CreateTokenForZohoAccount');
    throw errors;
  }
};

export const fetchZohoAccountCreds = async (params) => {
  try {
    const qs = utils.getQs(params);
    const url = `${baseUrl}/BiofuelThirdPartyIntegration/api/Zoho/FetchZohoAccountCreds?${qs}`;
    const res = await utils.fetch(url, {
      method: 'GET',
    }).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err: any) {
    const errors = await utils.processApiErrors(err, '', 'FetchZohoAccountCreds');
    throw errors;
  }
};

export const fetchZohoInvoiceLog = async (params) => {
  try {
    const qs = utils.getQs(params);
    const url = `${baseUrl}/BiofuelThirdPartyIntegration/api/Zoho/FetchZohoInvoiceLog`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(params),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err: any) {
    const errors = await utils.processApiErrors(err, '', 'FetchZohoInvoiceLog');
    throw errors;
  }
};

export const fetchZohoPaymentLog = async (params) => {
  try {
    const qs = utils.getQs(params);
    const url = `${baseUrl}/BiofuelThirdPartyIntegration/api/Zoho/FetchZohoPaymentLog`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(params),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err: any) {
    const errors = await utils.processApiErrors(err, '', 'FetchZohoPaymentLog');
    throw errors;
  }
};

export const fetchGPAAcceptance = async (params) => {
  try {
    const url = `${baseUrl}/BioFuelRegistration/api/KYC/FetchGPAAcceptance`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(params),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err: any) {
    const errors = await utils.processApiErrors(err, '', 'FetchGPAAcceptance');
    throw errors;
  }
};
