import _ from 'lodash';
import React, { useEffect } from 'react';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { Col, Container, Row, Card, CardBody, ButtonToolbar, Button } from 'reactstrap';
import { Formik, Field, Form, FieldArray } from 'formik';
import Bidicon from 'containers/Html/images/icon-bid.svg';
import FormikMaterialTextField from './../../components/FormikMaterialTextField';
import ReferenceDataSelect from './../../components/ReferenceDataSelect';
import BusinessReferenceDataSelect from '../components/BusinessReferenceDataSelect';
import MUITextField from '@material-ui/core/TextField';
import { FarmCropsSchema } from './handlers';
import { useSelector, useDispatch } from 'react-redux';
import { actions as userActions } from 'redux/user';
import LogoLoader from 'common/LogoLoader';

// import Collapse from './../components/Collapse';
import utils from 'lib/utils';
import * as handlers from './handlers';
const getInitialValues = (companyData = {}) => {

  let formFields = {
    ...companyData,
    business_type_enum: companyData.business_type_enum || 0,
    company_type_enum: companyData.company_type_enum || 0,
    nature_of_business_enum: companyData.nature_of_business_enum || 0,
    company_code: companyData.company_code || '',
    company_legal_name: companyData.company_legal_name || '',
    company_website: companyData.company_website || '',
    company_id: companyData.company_id || 0,
  };


  return formFields;
};

const BusinessInfoForm = (props) => {
  const companyId = props.companyId || 0;
  const companyData = props.companyData || 0;
  const id = props.id || 0;
  const isCompanyUser = props.isCompanyUser;
  const user = useSelector((state) => state.user);
  const profileUpdatedAt = _.get(user, 'profile.updatedAt') || false;
  const companiesUpdatedAt = _.get(user, 'company.updatedAt') || false;
  const dispatch = useDispatch();
  const history = useHistory();

  const busy = _.get(user, 'busy') || false;

  let idCompare = parseInt(id);

  let isAdmin;
  if (idCompare !== window.user.userid) {
    isAdmin = true;
  }
  if (idCompare === window.user.userid) {
    isAdmin = false;
  }

  // if (props.loading) {
  //   return (
  //     <div
  //       style={{
  //         position: 'relative',
  //         minHeight: '150px',
  //       }}>
  //       <div className="panel__refresh">
  //         <LogoLoader />
  //       </div>
  //     </div>
  //   );
  // }

  return (
    <React.Fragment>
      <Formik
        initialValues={getInitialValues(companyData)}
        onSubmit={companyId ? handlers.handleUpdate : handlers.handleCreate}>
        {(formikBag) => {
          const { values } = formikBag;


          return (
            <Form className="form-container">
              <div className="row form__form-group">
                <div className="col-md-6 col-lg-5 col-xl-4">
                  <div className="form-group-field">
                    <span className="form-group-label">Business Type</span>
                    <span class="required">*</span>
                    <Field
                      name="business_type_enum"
                      component={BusinessReferenceDataSelect}
                      entity="BusinessTypeEnum"
                      size="small"
                      disabled={isAdmin || isCompanyUser || companyData.is_kyc_done}
                    />
                  </div>
                </div>
                <div className="col-md-6 col-lg-5 col-xl-4">
                  <div className="form-group-field">
                    <span className="form-group-label">Nature of Business</span>
                    <span class="required">*</span>
                    <Field
                      name="nature_of_business_enum"
                      component={ReferenceDataSelect}
                      entity="natureOfBusinessEnum"
                      size="small"
                      disabled={isAdmin || isCompanyUser}
                    />
                  </div>
                </div>
              </div>
              <div className="row form__form-group">
                <div className="col-md-6 col-lg-5 col-xl-4">
                  <span className="form-group-label">Registered Business Name</span>
                  <span class="required">*</span>

                  <div className="form-group-field">
                    <Field
                      name="company_code"
                      component={FormikMaterialTextField}
                      disabled={isAdmin || isCompanyUser}
                    />
                    <div className="notes-block">
                      <p className="greenbg">
                        Must exactly match the name as per your business registration document/ bank account
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-5 col-xl-4">
                  <span className="form-group-label">Display Name</span>
                  <span class="required">*</span>
                  <div className="form-group-field">
                    <Field
                      name="company_legal_name"
                      component={FormikMaterialTextField}
                      disabled={isAdmin || isCompanyUser}
                    />
                  </div>
                </div>
              </div>
              <div className="row form__form-group">
                <div className="col-md-6 col-lg-5 col-xl-4">
                  <span className="form-group-label">Website URL</span>
                  <div className="form-group-field">
                    <Field
                      name="company_website"
                      component={FormikMaterialTextField}
                      disabled={isAdmin || isCompanyUser}
                    />
                  </div>
                </div>
                <div className="col-md-6 col-lg-5 col-xl-4">
                  <div className="form-group-field">
                    <span className="form-group-label">Company Type</span>
                    <Field
                      name="company_type_enum"
                      component={ReferenceDataSelect}
                      entity="companyTypeEnum"
                      size="small"
                      disabled={isAdmin || isCompanyUser}
                    />
                  </div>
                </div>
              </div>

              {!isAdmin && !isCompanyUser && (
                <div className="btn-wrapper justify-content-center">
                  <div>
                    <Button
                      color="primary"
                      type="button"
                      size="sm"
                      disabled={formikBag.isSubmitting}
                      onClick={formikBag.handleSubmit}
                      className="next bfc-button-next">
                      {props.isSubmitting && (
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      )}{' '}
                      Save
                    </Button>
                  </div>
                </div>
              )}
            </Form>
          );
        }}
      </Formik>
    </React.Fragment>
  );
};

export default BusinessInfoForm;
