import _ from 'lodash';
import * as Sentry from '@sentry/react';
import storage from 'lib/storage';
import moment from 'moment';
import inMemoryJWT from 'services/auth/inMemoryJWT';

const apiErrorLogger = async (error) => {
  try {
    const searchString = 'JWT Token Not Found in the System';
    if (
      error?.status === 511 ||
      error?.status === 503 ||
      error?.message?.toLowerCase().includes(searchString.toLowerCase())
    ) {
      const dateString = await storage.getItem('accessTokenExpiry');
      if (moment(dateString).isValid()) {
        const currentDate = moment();
        const accessTokenExpiry = moment(dateString);
        let token = inMemoryJWT.getToken() || '';
        if (accessTokenExpiry.isAfter(currentDate)) {
          let message = 'Api_Error' || '';
          message = `${message}_${moment().format('MM-YYYY')}`;
          console.log('BFC - error message -->', error?.message || error['message'] || 'Error');
          console.log('BFC - stringify.error-->', JSON.stringify(error));
          console.log('BFC - accessToken-->', (await storage.getItem('accessToken')) || 'NA');
          console.log('BFC - inMemoryJWT-->', token || 'NA');
          console.log('BFC - accessTokenExpiry-->', (await storage.getItem('accessTokenExpiry')) || 'NA');
          console.log('BFC - refreshToken-->', (await storage.getItem('refreshToken')) || 'NA');
          console.log('BFC - error?.status-->', error?.status);
          console.log('BFC - error?.message-->', error?.message);
          Sentry.captureException(message, {
            extra: {
              message: 'Api error info',
              pid: (await storage.getItem('pid')) || 'NA',
            },
            tags: {
              type: 'API_ERROR',
            },
          });
        }
      }
    }
    return true;
  } catch (error) {
    console.error('Error in apiErrorLogger', error);
    Sentry.captureException(error.message || error.status, {
      tags: {
        type: 'API_ERROR',
      },
    });
  }
};

export default apiErrorLogger;
