import _ from 'lodash';
import * as api from './api';
import * as yup from 'yup';
import * as helpers from 'services/helpers';

export const createDealSchema = yup.object().shape({
  offered_qty: yup.number().required('Quantity is required').positive('Invalid Quantity'),
  offered_price: yup.number().required('Price is required').positive('Invalid Price'),
  seller_person_id: yup.number().required('Please Select Farmer').positive('Please Select Farmer'),
  seller_address_id: yup.number().required(`Please Select Farmer's Address`).positive(`Please Select Farmer's Address`),
  tare_weight: yup
    .number()
    .typeError('Tare Weight is required')
    .max(20000, 'Tare Weight seems too large (over 20 MT/ 20,000 KG). Check Qtys in MT vs KG')
    .min(1, 'Tare Weight invalid')
    .required('Tare Weight is required')
    .test('is-decimal', 'Invalid', (value) => (value + '').match(/^\d+$/)),
  gross_weight: yup
    .number()
    .typeError('Gross Weight is required')
    .max(60000, 'Gross Weight seems too large (over 60 MT/ 60,000 KG). Check Qtys in MT vs KG')
    .min(1, 'Gross Weight invalid')
    .moreThan(yup.ref('tare_weight'), 'Gross weight must be greater than Tare weight')
    .required('Gross Weight is required')
    .test('is-decimal', 'Invalid', (value) => (value + '').match(/^\d+$/)),
});
export const createDealSchemaWalkIn = yup.object().shape({
  offered_qty: yup.number().required('Quantity is required').positive('Invalid Quantity'),
  offered_price: yup.number().required('Price is required').positive('Invalid Price'),
  seller_person_id: yup.number().required('Please Select Seller').positive('Please Select Seller'),
  seller_address_id: yup.number().required(`Please Select Seller's Address`).positive(`Please Select Seller's Address`),
  tare_weight: yup
    .number()
    .typeError('Tare Weight is required')
    .max(20000, 'Tare Weight seems too large (over 20 MT/ 20,000 KG). Check Qtys in MT vs KG')
    .min(1, 'Tare Weight invalid')
    .required('Tare Weight is required')
    .test('is-decimal', 'Invalid', (value) => (value + '').match(/^\d+$/)),
  gross_weight: yup
    .number()
    .typeError('Gross Weight is required')
    .max(60000, 'Gross Weight seems too large (over 60 MT/ 60,000 KG). Check Qtys in MT vs KG')
    .min(1, 'Gross Weight invalid')
    .moreThan(yup.ref('tare_weight'), 'Gross weight must be greater than Tare weight')
    .required('Gross Weight is required')
    .test('is-decimal', 'Invalid', (value) => (value + '').match(/^\d+$/)),
});

export const create = async (params = {}, isPickup = false) => {
  try {
    await helpers.validateSchema(isPickup ? createDealSchema : createDealSchemaWalkIn, params);
    const res = await api.create(params);
    return res;
  } catch (err) {
    throw err;
  }
};
