import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';

const { baseUrl } = config;

export const getDataFromApi = async (params) => {
  const { reqUrl, method, isQueryParams, reqParams } = params;
  try {
    const qs = utils.getQs(reqParams);
    const url = `${baseUrl}/${reqUrl}${isQueryParams ? `?${qs}` : ''}`;
    const res = await utils.fetch(url, {
      method,
      body: _.isEmpty(reqParams) || isQueryParams ? '' : JSON.stringify(reqParams),
    }).ready;
    let resData = await utils.processApiRes(res);
    resData = resData?.length ? resData.map((item, index) => ({ ...item, id: index })) : resData;
    return resData;
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', reqUrl);
    throw errors;
  }
};
