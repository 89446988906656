import { Dispatch } from 'redux';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import RequirmentOfferService, { RequirmentOfferDataType, RequirmentOfferMixedType } from 'services/requirmentOffer';

export interface RequirmentOfferState {
  data: Record<string, RequirmentOfferMixedType>;
  order: number[];
  busy: boolean;
  errors: null | [] | Object;
  errorCode: null | string | number;
  updatedAt: number;
}

const initialState: RequirmentOfferState = {
  data: {},
  order: [],
  busy: false,
  errors: null,
  errorCode: null,
  updatedAt: Date.now(),
};

const slice = createSlice({
  name: 'requimentOffers',
  initialState,
  reducers: {
    setData: (state, { payload }) => ({ ...state, data: payload }),
    startFetching(state) {
      state.busy = true;
    },
    endFetching(state) {
      state.busy = false;
    },
    handleErrors(state, action) {
      state.errors = action.payload.errors;
      state.errorCode = action.payload.errorCode;
      state.busy = false;
    },
    add(state, action: PayloadAction<RequirmentOfferMixedType[]>) {
      const order: number[] = [];
      const data = {};

      for (let reqOffer of action.payload) {
        const id = reqOffer.commodity_req_offer_response_id || reqOffer.buy_sell_compatibility_matrix_id;
        data[id] = { ...reqOffer };
        order.push(id);
      }
      state.data = data;
      state.order = order;
      state.updatedAt = Date.now();

      state.busy = false;
    },
    update(state, action) {
      const { id, data } = action.payload;
      state.data[id] = {
        ...state.data[id],
        ...data,
        detailsUpdatedAt: Date.now(),
      };
    },
  },
});

const FetchOffersAgainstRequirementId = (
  commodityReqOfferId: string,
  filters = { ...filters },
  page: number = 1,
  pageSize: number = 10,
) => {
  return async (dispatch: Dispatch, getState) => {
    try {
      dispatch(slice.actions.startFetching());
      const resData = await RequirmentOfferService.FetchOffersAgainstRequirementId(
        commodityReqOfferId,
        filters,
        page,
        pageSize,
      );
      dispatch(slice.actions.add(resData.data));
      // dispatch(slice.actions.setData(resData.data));
      // dispatch(slice.actions.endFetching());
    } catch (errors) {
      // dispatch(slice.actions.handleErrors({ ...errors }));
      dispatch(slice.actions.add([]));
    }
  };
};

const FetchAllOffersAgainstEntityId = (filters, page: number = 1, pageSize: number = 10) => {
  return async (dispatch: Dispatch, getState) => {
    try {
      dispatch(slice.actions.startFetching());
      const resData = await RequirmentOfferService.FetchAllOffersAgainstEntityId(filters, page, pageSize);
      dispatch(slice.actions.add(resData.data));
    } catch (errors) {
      dispatch(slice.actions.add([]));
    }
  };
};

const FetchOfferDetailsAgainstId = (commodityReqOfferResponseId: number) => {
  return async (dispatch: Dispatch, getState) => {
    try {
      dispatch(slice.actions.startFetching());
      const resData = await RequirmentOfferService.FetchOfferDetailsAgainstId(commodityReqOfferResponseId);
      // dispatch(slice.actions.add([resData.data]));
      dispatch(
        slice.actions.update({
          id: commodityReqOfferResponseId,
          data: resData.data,
        }),
      );
    } catch (errors) {
      // dispatch(slice.actions.handleErrors({ ...errors }));
      // dispatch(slice.actions.add([]));
    }

    dispatch(slice.actions.endFetching());
  };
};

const FetchAutoOfferDetailsAgainstId = (id: number) => {
  return async (dispatch: Dispatch, getState) => {
    try {
      dispatch(slice.actions.startFetching());
      const resData = await RequirmentOfferService.FetchAutoOfferDetailsAgainstId(id);
      // dispatch(slice.actions.add([resData.data]));
      dispatch(
        slice.actions.update({
          id: id,
          data: resData.data,
        }),
      );
    } catch (errors) {
      // dispatch(slice.actions.handleErrors({ ...errors }));
      // dispatch(slice.actions.add([]));
    }

    dispatch(slice.actions.endFetching());
  };
};

const SaveOffer = async (payload: Partial<RequirmentOfferDataType>) => {
  const resData = await RequirmentOfferService.SaveOffer(payload);
  return resData;
};

export const actions = {
  ...slice.actions,
  //requirmentOffer
  SaveOffer,
  FetchOffersAgainstRequirementId,
  FetchOfferDetailsAgainstId,
  FetchAllOffersAgainstEntityId,
  FetchAutoOfferDetailsAgainstId,
};

export default slice.reducer;
