import crossFetch from 'cross-fetch';
import _ from 'lodash';
// import moment from 'moment';
import dayjs from 'dayjs';
import normalize from 'normalize-object';
import notifications from 'lib/notifications';
import inMemoryJWT from 'services/auth/inMemoryJWT';
import { yupToFormErrors } from 'formik';
import config from '../config';
import { renderDebugBtn, renderFormFillBtn } from './helpers';
const { baseUrl, encryption_secret_key, encryption_secret_iv, isApiEncryptionEnabled, isRouteEncryptionEnabled } =
  config;
import { priceFormatter, amountFormatter, quantityFormatter } from './formatters';
// import * as mockData from './mockData';
import storage from 'lib/storage';
import { toast } from 'react-toastify';
import ReactGA from 'react-ga';
import CryptoJS from 'crypto-js';
import apiErrorLogger from 'services/sentry/index';
import queryStringParser from 'query-string';

const tranfromObjectIntoWithFlatKeys = (obj) => {
  const temp = { ...obj };

  for (const key of Object.keys(obj)) {
    if (obj[key] && typeof obj[key] === 'object') {
      temp[utils.lowerStr(key)] = tranfromObjectIntoWithFlatKeys(obj[key]);
    } else {
      temp[utils.lowerStr(key)] = obj[key];
      temp[key.trim().toLowerCase()] = obj[key];
    }
  }

  const temp2 = {
    ...temp,
    ...obj,
  };

  return temp2;
};

const caculateFinalQuantity = (baseQuantity, periodicity, fromDate = new Date(), toDate = new Date()) => {
  // console.log('\n\n=====================================')
  // console.log('caculateFinalQuantity--->baseQuantity--->', baseQuantity)
  // console.log('caculateFinalQuantity--->periodicity--->', periodicity)
  // console.log('caculateFinalQuantity--->fromDate--->', fromDate)
  // console.log('caculateFinalQuantity--->toDate--->', toDate)
  // console.log('=====================================')
  let finalQuantity = baseQuantity;
  switch (periodicity) {
    case 2:
      finalQuantity = baseQuantity * (dayjs(toDate).diff(dayjs(fromDate), 'days') + 1);
      break;
    case 3:
      finalQuantity = baseQuantity * (dayjs(toDate).diff(dayjs(fromDate), 'weeks') + 1);
      break;
    case 4:
      const v1 = dayjs(toDate).diff(dayjs(fromDate), 'months', true);
      const v2 = Math.round(v1);
      finalQuantity = baseQuantity * v2;
      // finalQuantity = baseQuantity * dayjs(toDate).diff(dayjs(fromDate), 'months');
      break;
    case 5:
      finalQuantity = baseQuantity * (dayjs(toDate).diff(dayjs(fromDate), 'years') + 1);
      break;
  }

  return finalQuantity;
};

const formatLocationString = (location) => {
  if (typeof location === 'string') {
    const locationList = location
      .trim()
      .split(',')
      .filter((l) => {
        const temp = l.trim();
        if (temp !== 'India' && temp !== 'india') {
          return true;
        }
      });
    return locationList; // is array
  }

  return [];
};

const importPublicPrivetKey = (pem, type) => {
  // base64 decode the string to get the binary data
  const binaryDerString = window.atob(pem);
  // convert from a binary string to an ArrayBuffer
  function str2ab(str) {
    const buf = new ArrayBuffer(str.length);
    const bufView = new Uint8Array(buf);
    for (let i = 0, strLen = str.length; i < strLen; i++) {
      bufView[i] = str.charCodeAt(i);
    }
    return buf;
  }
  const binaryDer = str2ab(binaryDerString);
  if (type === 'public') {
    return window.crypto.subtle.importKey(
      'spki',
      binaryDer,
      {
        name: 'RSA-OAEP',
        hash: 'SHA-256',
      },
      true,
      ['encrypt'],
    );
  } else {
    return window.crypto.subtle.importKey(
      'pkcs8',
      binaryDer,
      {
        name: 'RSA-OAEP',
        hash: 'SHA-256',
      },
      false,
      ['decrypt'],
    );
  }
};

const getRandomString = (length) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
};

const iv = getRandomString(16);

export let encryption = (encryption_text) => {
  const key = CryptoJS.enc.Utf8.parse(encryption_secret_key);
  const iv = CryptoJS.enc.Utf8.parse(encryption_secret_iv);
  try {
    let encrypted = CryptoJS.AES.encrypt(encryption_text, key, {
      keySize: 256 / 32,
      iv: iv,
      mode: CryptoJS.mode.CBC,
    });
    return encrypted.toString(CryptoJS.format.Utf8);
  } catch (error) {
    console.log('Encryption Error', error);
    throw error;
  }
};

export let decryption = (decryption_text) => {
  const key = CryptoJS.enc.Utf8.parse(encryption_secret_key);
  const iv = CryptoJS.enc.Utf8.parse(encryption_secret_iv);
  try {
    let decrypted = CryptoJS.AES.decrypt(decryption_text, key, {
      keySize: 256 / 32,
      iv: iv,
      mode: CryptoJS.mode.CBC,
    });
    return decrypted.toString(CryptoJS.enc.Utf8);
  } catch (error) {
    console.log('Decryption Error', error);
    // throw error;
    return false;
  }
};

export let encryptionInHex = (encryption_text) => {
  if (isRouteEncryptionEnabled == 'false') {
    return encryption_text;
  }
  if (encryption_text) {
    const key = CryptoJS.enc.Utf8.parse(encryption_secret_key);
    const iv = CryptoJS.enc.Utf8.parse(encryption_secret_iv);
    try {
      let encrypted = CryptoJS.AES.encrypt(encryption_text?.toString(), key, {
        keySize: 256 / 32,
        iv: iv,
        mode: CryptoJS.mode.CBC,
      });
      return encrypted.toString(CryptoJS.format.Hex);
    } catch (error) {
      console.log('Encryption Error', error);
      throw error;
    }
  }
};

export let decryptionInHex = (decryption_text) => {
  if (isRouteEncryptionEnabled == 'false') {
    return decryption_text;
  }
  if (decryption_text) {
    const key = CryptoJS.enc.Utf8.parse(encryption_secret_key);
    const iv = CryptoJS.enc.Utf8.parse(encryption_secret_iv);
    var encryptedHex = CryptoJS.enc.Hex.parse(decryption_text);
    var encryptedBase64 = CryptoJS.enc.Base64.stringify(encryptedHex);
    try {
      let decrypted = CryptoJS.AES.decrypt(encryptedBase64, key, {
        keySize: 256 / 32,
        iv: iv,
        mode: CryptoJS.mode.CBC,
      });
      return decrypted.toString(CryptoJS.enc.Utf8);
    } catch (error) {
      console.log('Decryption Error', error);
      return false;
    }
  }
};

export const getUrlWithEncryptedQueryParams = (url) => {
  if (isRouteEncryptionEnabled === 'false') {
    return url;
  }
  const urlObj = url && url?.split('?');
  const routeUrl = urlObj?.[0]
    ?.split('/')
    ?.map((item) => (isNaN(item) ? item : encryptionInHex(item)))
    ?.join('/');
  const queryParams = urlObj?.[1] ? '?' + encryptionInHex(urlObj?.[1]) : '';
  return routeUrl + queryParams;
};

export const getDecryptedUrl = (url) => {
  if (isRouteEncryptionEnabled == 'false') {
    return url;
  }
  const extractedUrl = new URL(url)?.pathname + new URL(url)?.search;
  const urlObj = extractedUrl?.split('?');
  const routeUrl = urlObj?.[0]
    ?.split('/')
    ?.map((item) => (decryptionInHex(item) ? decryptionInHex(item) : item))
    ?.join('/');
  const queryParams = urlObj?.[1]
    ? decryptionInHex(urlObj?.[1])
      ? '?' + decryptionInHex(urlObj?.[1])
      : `?${urlObj?.[1]}`
    : '';
  return new URL(url)?.origin + routeUrl + queryParams;
};

export const getDecryptedRouteParams = (params) => {
  if (isRouteEncryptionEnabled == 'false') {
    return params;
  }
  let decryptedParams = {};
  if (!_.isEmpty(params)) {
    for (const [key, value] of Object.entries(params)) {
      decryptedParams[key] = decryptionInHex(value) ? decryptionInHex(value) : value;
    }
  }
  return decryptedParams;
};
const isExcludedFromEncryption = (reqUrl) => {
  const excludeEncryptionApiArray = [
    'CreateSocialPost',
    'EditPost',
    'SaveAndUpdateAuthorizedSignatory',
    'AttachDocument_Azure',
    'UpdateAndUploadBanner',
    'UpdateProfile',
    'AttachDocumentAzureStorage',
    'ImportMembers',
  ];
  const utlPath = new URL(reqUrl)?.pathname?.split('/');
  const apiToExclude = utlPath[utlPath?.length - 1];
  return excludeEncryptionApiArray.includes(apiToExclude);
};

const utils = {
  getSpecificDecimalRoundedNumber(number, decimalsToShow) {
    if (Number.isInteger(parseFloat(number))) {
      return number;
    } else if (`${number}`.split('.')?.[1]?.length <= decimalsToShow) {
      return number;
    } else if (`${number}`.split('.')?.[1]?.length > decimalsToShow) {
      return number.toFixed(decimalsToShow);
    }
  },
  scrollToBottom(reactRef) {
    if (reactRef) {
      reactRef.current.scrollTop = reactRef.current.scrollHeight;
    }
  },
  isDecimal(num) {
    if (num % 1 != 0) {
      return true;
    } else {
      return false;
    }
  },
  // getMockRes(key) {
  //   return _.get(mockData, key);
  // },
  getUnixTs(dt = '') {
    return dayjs(dt).unix();
  },
  debugStr(str) {
    if (utils.isProd()) {
      return null;
    }

    return str;
  },
  // debug(data) {
  //   return renderDebugBtn({ data });
  // },
  // fillBtn(formikBag, dataKey) {
  //   return renderFormFillBtn({ dataKey, formikBag });
  // },
  getACL(user) {
    if (!user) {
      user = _.get(globalThis, 'user') || {};
    }
    const uType = _.get(user, 'profile.person.type_of_individual_enum') || 0;
    const companyAdmin = _.get(user, 'profile.person.is_company_admin');
    const hasRole = !!uType;
    const isEmailLogin = (_.get(user, 'profile.login_through_enum') || 0) === 2;
    const isPhoneLogin = (_.get(user, 'profile.login_through_enum') || 0) === 1;
    const isCompanyAdmin = _.get(user, 'profile.person.is_company_admin') || false;
    const isInvited = _.get(user, 'profile.person.is_invited') || false;
    let isCompany = false;
    const userTypeEnum = _.get(user, 'profile.person.user_type_enum');
    if (userTypeEnum === 1) {
      // removing && isCompanyAdmin === true condition so that every team member gets enterprise treatment
      isCompany = true;
    }
    let isUserCompany; // need to remove this and use isCompany and isCompanyAdmin flags
    const isFarmer = uType === 1;
    const isQALab = uType === 8;
    const isAgriBio = uType === 3;
    const isDriver = _.get(user, 'profile.person.is_driver') || false;
    const isTransporter = _.get(user, 'profile.person.is_transporter') || false;
    const isControlTowerUser = _.get(user, 'profile.person.is_control_tower_user') || false;
    // const isRelatedBusinessUser = (_.get(user, 'profile.type_of_individual_enum') || 0) === 3;
    const isChecker = _.get(user, 'profile.person.is_checker') || false;
    const isMaker = _.get(user, 'profile.person.is_maker') || false;
    const isSuperUser = _.get(user, 'profile.person.is_superuser') || false;

    if (userTypeEnum === 1 && companyAdmin === false) {
      isUserCompany = true;
    } else {
      isUserCompany = false;
    }

    const isIndividual = hasRole && !isCompany && !isUserCompany;

    let canUpdateEmail = false;
    let canUpdatePhone = false;
    let isEmailDisabled = false;
    let isPhoneDisabled = false;

    if (isEmailLogin) {
      isEmailDisabled = true;
      canUpdateEmail = true;
    }

    if (isPhoneLogin) {
      isPhoneDisabled = true;
      canUpdatePhone = true;
    }

    if (isInvited) {
      canUpdateEmail = false;
      isEmailDisabled = true;
    }

    let acl = {
      isEmailLogin,
      isPhoneLogin,
      isCompanyAdmin,
      isInvited,
      isIndividual,
      isFarmer,
      isQALab,
      isAgriBio,
      isDriver,
      isTransporter,
      isCompany,
      isUserCompany,
      canUpdateEmail,
      canUpdatePhone,
      isEmailDisabled,
      isPhoneDisabled,
      hasRole,
      isControlTowerUser,
      isChecker,
      isMaker,
      isSuperUser,
    };

    // console.log('\n\n===========ACL===========');
    // console.log('\n-------->acl', acl);
    // console.log('\n\n===========/ACL===========');

    return acl;
  },

  getQs(params = {}) {
    let queryString = queryStringParser.stringify(params);
    queryString = queryStringParser.parse(queryString);
    queryString = Object.keys(queryString)
      .map((key) => `${key}=${params[key]}`)
      .join('&');
    let encryptedBody = encryption(queryString);
    return isApiEncryptionEnabled === 'true' ? encryptedBody : queryString;
  },
  getLocationData(data) {
    data = data || {};
    const defaultValues = {
      location_id: 0,
      location_code: '',
      address_text: '',
      lattitude: '',
      longitude: '',
      village_id: 0,
      village_code: '',
      taluka_id: 0,
      taluka_code: '',
      city_id: 0,
      city_code: '',
      district_id: 0,
      district_code: '',
      state_id: 0,
      state_code: '',
      country_id: 0,
      country_code: '',
      // pin_code: '',
    };

    let locationData = _.pick(data, Object.keys(defaultValues));

    if (!locationData['location_code'] && data['location_data']) {
      locationData['location_code'] = data['location_data'];
    }

    if (!locationData['address_text'] && data['location_code']) {
      locationData['address_text'] = data['location_code'];
    }

    return {
      ...defaultValues,
      ...locationData,
    };
  },
  getAddressDisplayText(locationData) {
    let address = [];
    let line1 = [];
    let line2 = [];
    let line3 = [];
    if (locationData['address_line1']) {
      line1.push(locationData['address_line1']);
    }

    if (locationData['address_line2']) {
      line1.push(locationData['address_line2']);
    }

    if (locationData['address_line3']) {
      line1.push(locationData['address_line3']);
    }

    if (locationData['village_code']) {
      line2.push(locationData['village_code']);
    }

    if (locationData['taluka_code']) {
      line2.push(locationData['taluka_code']);
    }

    if (locationData['district_code']) {
      line2.push(locationData['district_code']);
    }

    if (locationData['state_code']) {
      line3.push(locationData['state_code']);
    }

    if (locationData['pin_code']) {
      line3.push(locationData['pin_code']);
    }

    return {
      line1,
      line2,
      line3,
      addStr: `${line1.join(',')} <br/> ${line2.join(',')} <br/> ${line3.join('-')}`,
    };
  },

  getFarmAddress(locationData) {
    let address = [];
    let line1 = [];
    let line2 = [];
    let line3 = [];
    if (locationData['address_line1']) {
      line1.push(locationData['address_line1']);
    }

    if (locationData['address_line2']) {
      line1.push(locationData['address_line2']);
    }

    if (locationData['village_code']) {
      line2.push(locationData['village_code']);
    }

    if (locationData['taluka_code']) {
      line2.push(locationData['taluka_code']);
    }

    if (locationData['district_code']) {
      line2.push(locationData['district_code']);
    }

    if (locationData['state_code']) {
      line3.push(locationData['state_code']);
    }

    if (locationData['pin_code']) {
      line3.push(locationData['pin_code']);
    }
    // if (locationData['circle_code']) {
    //   line4.push('(' + locationData['circle_code'] + ')');
    // }

    return {
      line1,
      line2,
      line3,
      addStr: `${line1.join(',')}\n${line2.join(',')}\n${line3.join('-')}`,
    };
  },

  getLocationAddress(locationData) {
    // console.log('locationData', locationData);
    let address = [];
    let add1 = [];
    let line1 = [];
    let line2 = [];
    let line3 = [];
    if (locationData['address_line1']) {
      add1.push(locationData['address_line1']);
    }

    if (locationData['address_line2']) {
      line1.push(locationData['address_line2']);
    }
    if (locationData['address_line3']) {
      line1.push(locationData['address_line3']);
    }

    if (locationData['village_code']) {
      line2.push(locationData['village_code']);
    }

    if (locationData['taluka_code']) {
      line2.push(locationData['taluka_code']);
    }

    if (locationData['district_code']) {
      line2.push(locationData['district_code']);
    }

    if (locationData['state_code']) {
      line3.push(locationData['state_code']);
    }

    if (locationData['pin_code']) {
      line3.push(locationData['pin_code']);
    }
    // if (locationData['circle_code']) {
    //   line4.push('(' + locationData['circle_code'] + ')');
    // }

    return {
      line1,
      line2,
      line3,
      addStr: `${add1.join(',')}\n${line1.join(',')}\n${line2.join(',')}\n${line3.join('-')}`,
    };
  },

  async validateSchema(schema, values) {
    try {
      let result = await schema.validate(values, {
        abortEarly: false,
      });
      return result;
    } catch (err) {
      // console.log('\n\n==============validateSchema===========');
      // console.log('validateSchema-->errors---->', err);
      // console.log('schema---->', schema);
      // console.log('values---->', values);
      // console.log('\n\n==============validateSchema===End========');

      throw {
        errors: yupToFormErrors(err),
        message: 'Schema validation error',
      };
    }
  },

  getErrMessage(errors = {}) {
    const loc = window.location.href;
    const isProdInstance = loc.includes('supply.biofuelcircle.com') || loc.includes('my.biofuelcircle.com');
    if (Object.keys(errors) === 0) {
      return '';
    }

    let errMessage = '';
    let key = _.first(Object.keys(errors));
    let val = errors[key];
    if (typeof val === 'object') {
      errMessage = utils.getErrMessage(errors[key]);
    } else if (typeof val === 'string') {
      errMessage = isProdInstance ? val : `${key} - ${val}`;
    }

    return errMessage;
  },
  displayMessage(message = '', type = 'info') {
    // notifications.show({
    //   type: type,
    //   message: message,
    //   key: 'req-form',
    //   duration: 20,
    // });
    if (type === 'info') {
      toast.info(message);
    } else if (type === 'success') {
      toast.success(message);
    } else if (type === 'error') {
      toast.error(message);
    } else if (type === 'warning') {
      toast.warn(message);
    }
  },
  displayErrors(err, formikBag = false, customId = '') {
    let errors = _.get(err, 'errors') || {};
    let errMessage = navigator.onLine
      ? _.get(err, 'message') || 'Unable to process request.'
      : 'Please check your internet connection.';
    if (!_.isEmpty(errors)) {
      if (formikBag) {
        formikBag.setErrors({
          ...errors,
        });

        // formikBag.setTouched({
        //   ...errors,
        //   // formData: {
        //   //   ...errors,
        //   // },
        // });
      }

      // let errData = _.flatMapDeep(errors);
      errMessage = utils.getErrMessage(errors) || errMessage;

      // console.log('\n\n==============displayErrors===========');
      // console.log('err', err);
      // console.log('errors->', errors);
      // console.log('formikBag---->', formikBag);
      // // console.log('flatMapDeep--->errData-->', errData);
      // console.log('errMessage---->', errMessage);
      // console.log('\n\n==============displayErrors===End========');
    }
    // notifications.show({
    //   type: 'error',
    //   message: errMessage,
    //   key: 'req-form',
    //   duration: 20,
    // });

    if (errMessage.includes('JWT Token Not Found in the System')) {
      errMessage = 'You have been logged out to protect your account.';
    }

    toast.error(
      errMessage,
      customId
        ? {
            toastId: customId,
          }
        : {},
    );
  },
  displayFormDataErrors(err, formikBag = false) {
    // console.log('\n\n==========================\n\n');
    // console.log('displayFormDataErrors');
    // console.log('need to remove');
    // console.warn('need to remove');
    // console.error('need to remove');
    // console.log('\n\n==========================\n\n');

    let errors = _.get(err, 'errors') || {};
    let errMessage = _.get(err, 'message') || 'Unable to process request.';
    if (!_.isEmpty(errors)) {
      if (formikBag) {
        formikBag.setErrors({
          formData: {
            ...errors,
          },
        });

        // formikBag.setTouched({
        //   ...errors,
        //   formData: {
        //     ...errors,
        //   },
        // });
      }

      // let errData = _.flatMapDeep(errors);
      errMessage = utils.getErrMessage(errors) || errMessage;

      // console.log('\n\n==============displayErrors===========');
      // console.log('err', err);
      // console.log('errors->', errors);
      // console.log('formikBag---->', formikBag);
      // // console.log('flatMapDeep--->errData-->', errData);
      // console.log('errMessage---->', errMessage);
      // console.log('\n\n==============displayErrors===End========');
    }
    notifications.show({
      type: 'error',
      message: errMessage,
      key: 'req-form',
      duration: 20,
    });
  },

  isProd: () => {
    return config.isProd;
  },

  decodeJWT(token) {
    try {
      return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
      return null;
    }
  },

  async getResJSON(res) {
    // debugger;
    // console.log('getLocations-res-raw->', res);

    if (!res.ok) {
      throw res;
    }

    if (res.status < 200 || res.status >= 300) {
      throw res;
    }

    let jsonRes = res;

    if (typeof res.json === 'function') {
      jsonRes = await res.json();
    }

    if (_.get(jsonRes, 'stat', '') === false) {
      throw jsonRes;
    }

    return jsonRes;
  },

  async processApiRes(res = {}) {
    const isExcluded = isExcludedFromEncryption(res?.url);

    let resJSON = res;
    resJSON = isApiEncryptionEnabled === 'true' && !isExcluded ? await res.text() : res;

    if (isApiEncryptionEnabled === 'true' && !isExcluded) {
      let decryptedData = decryption(resJSON);
      resJSON = JSON.parse(decryptedData);
      if (!resJSON?.stat) {
        throw resJSON;
      }
    } else if (typeof res.json === 'function') {
      resJSON = await utils.getResJSON(resJSON);
    }

    const resData = _.get(resJSON, 'data', {}) || {};
    let snakeRes = normalize(resData, 'snake');
    return snakeRes;

    // if (_.isArray(resData)) {
    //   const updatedData = [];

    //   for (const d of resData) {
    //     const temp = tranfromObjectIntoWithFlatKeys(d);
    //     updatedData.push(temp);
    //   }

    //   return {
    //     ...updatedData,
    //     ...snakeRes,
    //   };
    // }

    // for (const key of Object.keys(resData)) {
    //   resData[utils.lowerStr(key)] = resData[key];
    // }

    // return {
    //   ...resData,
    //   ...snakeRes,
    // };
  },

  async processApiResELoc(res = {}) {
    let resJSON = res;
    if (typeof res.json === 'function') {
      resJSON = await utils.getResJSON(res);
    }

    const resData = _.get(resJSON, 'suggestedLocations', {}) || {};
    let snakeRes = normalize(resData, 'snake');
    return snakeRes;
  },

  getBackendParams(backendFields = {}, params = {}) {
    // let backendParams = {}
    for (const field in backendFields) {
      const frontendField = utils.lowerStr(field);
      const lowerCasedField = field.trim().toLowerCase();

      if (params[field] !== undefined) {
        backendFields[field] = params[field];
      } else if (params[frontendField] !== undefined) {
        backendFields[field] = params[frontendField];
      } else if (params[lowerCasedField] !== undefined) {
        backendFields[field] = params[lowerCasedField];
      }
    }
    return backendFields;
  },

  async processApiErrors(err = {}, fetchDetails = '', apiName = '') {
    await apiErrorLogger(err);

    const errData = {
      errors: {},
    };

    if (err.status === 401) {
      return utils.forceLogout();
    }

    const message = _.get(err, 'message', '');
    // to handle to CORS error during downtime

    // if (message === 'Network request failed') {
    //   errData.errors = {};
    //   errData.message = 'Unable to process request. please check your internet connection';
    //   // return errData;
    //   inMemoryJWT.ereaseAccessToken();
    //   inMemoryJWT.ereaseToken();
    //   let finalErrorMessage = message || `You have been logged out to protect your account.`;
    //   await storage.setItem('LogoutMessage', finalErrorMessage);
    //   window.location.reload(false);
    // }

    if (err.status === 500) {
      errData.errors = {
        500: 'Internal Server Error',
      };
      errData.message = '500 - Internal Server Error';
      return errData;
    }
    const searchString = 'JWT Token Not Found in the System';
    if (
      err.status === 511 ||
      fetchDetails == 'fetchPersonalDetails' ||
      err?.message?.toLowerCase().includes(searchString.toLowerCase())
    ) {
      inMemoryJWT.ereaseAccessToken();
      const result = await inMemoryJWT.getRefreshedToken();
      if (!result) {
        console.log('BFC-LOGGER - Get Refresh Token Api Failed');
        inMemoryJWT.ereaseToken();
        let finalErrorMessage = `You have been logged out to protect your account.`;
        await storage.setItem('LogoutMessage', finalErrorMessage);
        window.location.reload(false);
      } else {
        if (result != 'REQUEST_ABORTED') {
          window.location.reload(false);
        }
      }
    }

    if (err.status === 503) {
      inMemoryJWT.ereaseAccessToken();
      inMemoryJWT.ereaseToken();
      let finalErrorMessage = `You have been logged out to protect your account.`;
      await storage.setItem('LogoutMessage', finalErrorMessage);
      window.location.reload(false);
    }

    // console.log('err--statusText->', err.statusText)
    // console.log('err--status->', err.status)
    // console.log('err--message->', err.message)
    // console.log('err--ok->', err.ok)

    // console.log('err--->', err)
    let body = {};
    let stacktrace;
    const errorTrace = new Error();
    const loc = window.location.href;
    const isProdInstance = loc.includes('supply.biofuelcircle.com') || loc.includes('my.biofuelcircle.com');
    try {
      // console.log('err------------------------->', err);
      stacktrace = `statusCode: ${err.status} \n ${errorTrace.stack}`;
      body = err.status ? await err.json() : err;
      // console.log('body-------------------------->', body);
      const backendFieldErrors = _.get(body, 'data') || _.get(body, 'errors') || {};
      let errMessage = _.get(body, 'message') || `Error Processing Request.${isProdInstance ? '' : ` API: ${apiName}`}`;
      // errors.message = _.get(body, 'data[0]', '');
      errMessage =
        errMessage === 'Aborted'
          ? `Request Timeout. Unable to process request.${isProdInstance ? '' : ` API: ${apiName}`} `
          : errMessage;

      const frontendFieldErrors = normalize(backendFieldErrors, 'snake');

      //removing lowerStr usage
      // for (const field of Object.keys(backendFieldErrors)) {
      //   frontendFieldErrors[utils.lowerStr(field)] = backendFieldErrors[field];
      // }

      errData.errors = {
        ...frontendFieldErrors,
        // ...backendFieldErrors,
      };
      errData['message'] = errMessage || `Unable to process request.${isProdInstance ? '' : ` API: ${apiName}`}`;
    } catch (err) {
      let statusText = _.get(err, 'statusText') || '';
      const message = _.get(body, 'message') || '';
      errData['message'] = message
        ? message
        : statusText
        ? statusText
        : `Unable to process request.${isProdInstance ? '' : ` API: ${apiName}`}`;
    }
    if (errData['message'].toString().includes('Unable to process request')) {
      try {
        let bodyString = JSON.stringify(!_.isEmpty(body) ? body : stacktrace);
        let token = inMemoryJWT.getToken() || '';
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json', Authorization: token },
        };
        fetch(
          `${baseUrl}/BioFuelCommonUtilities/api/CommonUtilities/InsertExceptionLog?exceptionat=${apiName}&exceptionstacktrace=${bodyString}`,
          requestOptions,
        )
          .then((response) => response.json())
          .then((data) => console.log('InsertExceptionLog--->response', data));
      } catch (err) {
        console.log('InsertExceptionLog--->error', err);
      }
    }
    return errData;
  },

  fetch(request, opts = {}, isJson = true, isSsoLogin = false, ssoId) {
    const controller = new AbortController();
    const { signal } = controller;
    const isExcluded = isExcludedFromEncryption(request);

    let token = inMemoryJWT.getToken() || '';
    if (!token) {
      const user = window.user || {};
      token = _.get(user, 'jwttoken', '') || '';
    }

    opts = {
      method: 'GET',
      headers: {
        Authorization: token,
        // initiator: window.btoa(iv),
        initiator: iv,
      },
      // credentials: 'include',
      ...opts,
    };
    if (isJson) {
      opts.headers['Content-Type'] = 'application/json';
    }
    if (isSsoLogin) {
      if (ssoId === 'iProfit') {
        let loc = window.location.href;
        if (loc.includes('prep') || loc.includes('test') || loc.includes('demo')) {
          opts.headers['AccessToken'] =
            'ALRjjDOrhrzwECauPuQGkviNfjJOEMqMTNXNJjPkrYYdWSueDUNRXdNzYVShfJLJZhKnymiMdOPFqnQoJXOtGmjwoFxyFWQfHXtJPYhDEzAvYTGFRKMwPhJFmUyEKcYS';
        } else if (loc.includes('my.biofuelcircle.com') || loc.includes('supply.biofuelcircle.com')) {
          opts.headers['AccessToken'] = 'DUNRXdNzYVShfJLJZhKnymiMdOPFqnQoJXOtGmjwoFxyFWQfHXtJPYhDEzAvYTGFR';
        }
      } else {
        opts.headers['pat'] = 'bd@bdc@hgvcccxzbcvbdsucbsdcsd';
      }
    }

    if (
      request?.includes('BioFuelOAuthServer/Users/login') ||
      request?.includes('BioFuelOAuthServer/Users/verify-login') ||
      request?.includes('BioFuelOAuthServer/Users/refresh-token')
    ) {
      opts.headers['AccessToken'] =
        'JfqmmhZkDqZziLwzKaOrdnDLsiMDaxOcCTwRNVCgwJDFnCgmyNCmazJkMZsZShKsPmJVGoOxbdbUDGFcGzneUKGzfhKWjwRammUZrvAObZnJbsqoKxUCNQwBvEcbLcyC';
    }

    const getRequest = async (req, opts) => {
      const reqString = opts?.['body'];
      let encryptedBody = encryption(reqString);
      return crossFetch(req, { ...opts, body: encryptedBody, signal });
    };

    let urlWithEncryptedQs;
    if (isApiEncryptionEnabled === 'true' && !isExcluded) {
      const parsedUrl = new URL(request);
      if (parsedUrl.search) {
        const queryParams = parsedUrl.search.split('?')[1];
        parsedUrl.search = '';
        const isAlreadyEncrypted = decryption(queryParams);
        urlWithEncryptedQs =
          isApiEncryptionEnabled === 'true'
            ? isAlreadyEncrypted
              ? request
              : `${parsedUrl?.origin}${parsedUrl?.pathname}?${this.getQs(queryStringParser.parse(queryParams))}`
            : request;
      }
    }
    console.log('Header-request-payload-utils->fetch', `${request} => ${JSON.stringify(opts.headers)}`);

    const req = {
      abort: () => controller.abort(),
      ready:
        isApiEncryptionEnabled === 'true' && !isExcluded
          ? opts?.['body']
            ? getRequest(urlWithEncryptedQs || request, { ...opts, signal })
            : crossFetch(urlWithEncryptedQs || request, { ...opts, signal })
          : crossFetch(request, { ...opts, signal }),
    };

    setTimeout(() => {
      req.abort();
    }, 30 * 1000);

    return req;
  },

  fetchShare(request, opts = {}, isJson = true) {
    const controller = new AbortController();
    const { signal } = controller;
    opts = {
      method: 'GET',
      headers: {},
      ...opts,
    };
    if (isJson) {
      opts.headers['Content-Type'] = 'application/json';
    }

    const getRequest = async (req, opts) => {
      const reqString = opts?.['body'];
      let encryptedBody = encryption(reqString);
      return crossFetch(req, { ...opts, body: encryptedBody, signal });
    };

    const req = {
      abort: () => controller.abort(),
      ready:
        isApiEncryptionEnabled === 'true'
          ? opts?.['body']
            ? getRequest(request, { ...opts, signal })
            : crossFetch(request, { ...opts, signal })
          : crossFetch(request, { ...opts, signal }),
    };

    setTimeout(() => {
      req.abort();
    }, 30 * 1000);

    return req;
  },

  fetchMMI(request, opts = {}, isJson = true) {
    const controller = new AbortController();

    const { signal } = controller;

    const storeState = globalThis.store.getState();
    const tokenState = _.get(storeState, 'locationEloc') || {};
    const token = _.get(tokenState, 'entities.undefined') || '';
    // console.log('token====>MMI', token);

    opts = {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
      },
      // credentials: 'include',
      ...opts,
    };
    // debugger;
    if (isJson) {
      opts.headers['Content-Type'] = 'application/json';
    }

    const getRequest = async (req, opts) => {
      const reqString = opts?.['body'];
      let encryptedBody = encryption(reqString);
      return crossFetch(req, { ...opts, body: encryptedBody, signal });
    };

    const req = {
      abort: () => controller.abort(),
      ready:
        isApiEncryptionEnabled === 'true'
          ? opts?.['body']
            ? getRequest(request, { ...opts, signal })
            : crossFetch(request, { ...opts, signal })
          : crossFetch(request, { ...opts, signal }),
    };

    setTimeout(() => {
      req.abort();
    }, 30 * 1000);

    return req;
  },

  logObj(obj) {
    // console.log('\n\n=======================logObj=====================================');
    try {
      // console.log('obj---->', obj);
      // console.log('\n\n');
      for (const key in obj) {
        console.log('\nkey-->', key);
        console.log('\nval-->', obj[key]);
      }
    } catch (err) {
      console.log('unable to log obj');
    }
    // console.log('\n\n=======================logObj=====================================');
  },

  lowerStr(str = '') {
    str = str.trim().toLowerCase();
    // str = str.replace(/\./g, '')
    // str = str.replace(/\*/g, '')
    // str = str.replace(/-/g, '')
    // str = str.replace(/ /g, '')
    // str = str.replace(/_/g, '')
    str = str.replace(/[^a-z0-9]/gi, '');

    return str;
  },

  sleep(sec = 1) {
    return new Promise((resolve, rejcet) => {
      setTimeout(() => {
        resolve();
      }, sec * 1000);
    });
  },

  isNaN(value) {
    return !(!(value instanceof Array) && !isNaN(parseFloat(value)) && isFinite(value));

    // from angular
    // return !(!isNaN(value - parseFloat(value)));
  },
  isNumber(value) {
    return !utils.isNaN(value);
  },
  // isNaN(value) {

  //     return !(!(value instanceof Array) && !isNaN(parseFloat(value)) && isFinite(value))

  //     //from angular
  //     return !(!isNaN(value - parseFloat(value)));
  // },

  secToHrsMins(num) {
    if (num < 60 && num >= 30) {
      return '1 min';
    }
    if (num < 30 && num > 0) {
      return '0 min';
    }

    num = parseInt(num / 60);

    let hours = Math.floor(num / 60);
    if (hours) {
      if (hours === 1) {
        hours = `${parseInt(hours)} hr`;
      } else {
        hours = `${parseInt(hours)} hrs`;
      }
    }

    let minutes = num % 60;

    if (minutes) {
      if (minutes === 1) {
        minutes = `${parseInt(minutes)} min`;
      } else {
        minutes = `${parseInt(minutes)} mins`;
      }
    }

    let str = '';
    if (minutes) {
      str = minutes;
    }
    if (hours) {
      str = `${hours} ${str}`;
    }

    return str.trim();
  },
  padZero(str = '', size = 12) {
    str = str || '';
    let s = str.toString();
    while (s.length < (size || 2)) {
      s = `0${s}`;
    }
    return s;
  },

  wait: (time = 1) =>
    // time = time * 1000;
    // console.log(--------------wating for ${time} seconds...)
    new Promise((resolve, reject) => {
      setTimeout(() => {
        // console.log(--------------wating finish-----);
        resolve();
      }, time * 1000);
    }),

  clone: (obj) => JSON.parse(JSON.stringify(obj)),

  forceLogout: async () => {
    await storage.removeItem('refreshToken');
    // await storage.removeItem('pid');
    // await storage.removeItem('sid');
    // await storage.removeItem('persist:root');
    if (typeof window !== undefined) {
      window.location.href = '/';
    }
  },

  getRandomInt: (min, max) => Math.floor(Math.random() * (max - min + 1)) + min,

  uid: (len = 255) => {
    const buf = [];
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charlen = chars.length;

    for (let i = 0; i < len; ++i) {
      buf.push(chars[utils.getRandomInt(0, charlen - 1)]);
    }

    return buf.join('');
  },

  nl2br: (str, is_xhtml) => {
    if (typeof str === 'undefined' || str === null) {
      return '';
    }
    const breakTag = is_xhtml || typeof is_xhtml === 'undefined' ? '<br />' : '<br>';
    return `${str}`.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, `$1${breakTag}$2`);
  },

  isMobile: () => {
    if (window.matchMedia('(max-width: 766px)').matches) {
      return true;
      /* The viewport is at least 991.98 pixels wide */
    }
    return false;
    /* The viewport is less than 991.98 pixels wide */
  },

  addHandler(elSelector, eventName, selector, fn) {
    const element = document.querySelector(elSelector);

    element.addEventListener(eventName, (event) => {
      const possibleTargets = element.querySelectorAll(selector);
      const { target } = event;

      for (let i = 0, l = possibleTargets.length; i < l; i++) {
        let el = target;
        const p = possibleTargets[i];

        while (el && el !== element) {
          if (el === p) {
            return fn.call(p, event);
          }

          el = el.parentNode;
        }
      }
      return true;
    });
  },

  getURLParam: (key) => {
    const value = [];

    // eslint-disable-next-line no-undef
    const query = String(document.location).split('?');

    if (query[1]) {
      const part = query[1].split('&');

      for (let i = 0; i < part.length; i += 1) {
        const data = part[i].split('=');

        if (data[0] && data[1]) {
          value[data[0]] = data[1];
        }
      }

      if (value[key]) {
        return value[key];
      }
      return '';
    }
  },

  formatDate: (date = '') => {
    if (!dayjs(date).isValid()) {
      return 'N/A';
    }
    const formatedDate = dayjs(date).format('DD-MMM-YYYY');
    // console.log('\n------formatDate------');
    // console.log('formatDate-->', date);
    // console.log('formatted->date->', formatedDate);
    // console.log('------------------------');
    return formatedDate;
  },

  formatDateTime: (datetime = '') => {
    if (!dayjs(datetime).isValid()) {
      return 'N/A';
    }
    return dayjs(datetime).format('DD-MMM-YYYY h:mm A');
  },

  formatToModernDateTime: (datetime = '') => {
    if (!dayjs(datetime).isValid()) {
      return 'N/A';
    }
    return `${dayjs(datetime).format('MMMM D, YYYY')} at ${dayjs(datetime).format('h:mm A')}`;
  },

  unformat: (price = 0) => {
    if (!price) {
      return '0';
    }
    // return parseFloat(price.toString().replace(/[^0-9.]/g, '')).toString(); // 12345.99
    price = price.toString();
    price = price.replace(/,/g, '');
    price = price.replace(/\$/g, '');
    price = price.replace(/[^0-9-.]/g, '');
    return price;
  },

  formatPrice: (number, removeZeros = true) => {
    // To set it up as a global function:
    number = number || 0;
    number = parseFloat(number.toString().replace(/[^0-9-.]/g, '')).toFixed(2);
    number = priceFormatter.format(number);
    if (removeZeros) {
      number = number.replace(/\D00(?=\D*$)/, '');
    }
    return number;
  },
  formatNumberPrice: (number, removeZeros = true) => {
    // To set it up as a global function:
    number = number || 0;
    number = parseFloat(number.toString().replace(/[^0-9-.]/g, '')).toFixed(2);
    number = quantityFormatter.format(number);
    if (removeZeros) {
      number = number.replace(/\D00(?=\D*$)/, '');
    }
    return number;
  },
  formatQty: (number, suffix = '', removeZeros = true) => {
    // To set it up as a global function:
    number = number || 0;
    number = parseFloat(number.toString().replace(/[^0-9-.]/g, '')).toFixed(3);

    number = quantityFormatter.format(number);
    if (removeZeros) {
      number = number.replace(/\D00(?=\D*$)/, '');
    }

    if (number == '0') {
      number = ' ';
    }

    if (suffix) {
      number = `${number} ${suffix}`;
    }

    return number;
  },

  formatQtyPriceUptoTwoDecimal: (number) => {
    // check if the number is a valid number and not null
    if (number === 0) {
      return '';
    } else {
      if (_.isNumber(number) && !_.isNull(number)) {
        return parseFloat(number).toFixed(2);
      } else {
        return '';
      }
    }
  },

  formatQtyPriceUptoTwoDecimalForStorage: (number) => {
    // check if the number is a valid number and not null
    if (number === null) {
      return '0.00';
    } else {
      if (_.isNumber(number) && !_.isNull(number)) {
        return parseFloat(number).toFixed(2);
      } else {
        return '0.00';
      }
    }
  },

  formatQtyPrice: (number, suffix = '', removeZeros = true) => {
    // To set it up as a global function:
    number = number || 0;
    number = parseFloat(number.toString().replace(/[^0-9-.]/g, '')).toFixed(3);

    number = amountFormatter.format(number);
    if (removeZeros) {
      number = number.replace(/\D00(?=\D*$)/, '');
    }

    if (suffix) {
      number = `${number} / ${suffix}`;
    }

    return number;
  },

  formatQtyPricePayment: (number, suffix = '', removeZeros = true) => {
    // To set it up as a global function:
    number = number || 0;
    number = parseFloat(number.toString().replace(/[^0-9-.]/g, '')).toFixed(3);

    const numberParts = amountFormatter.formatToParts(number);
    number = numberParts
      .filter(({ type, value }) => {
        if (type !== 'currency') {
          return value;
        }
        return false;
      })
      .map((num) => num.value)
      .reduce((string, part) => string + part);

    const currency = numberParts
      .filter(({ type, value }) => {
        if (type === 'currency') {
          return value;
        }
        return false;
      })
      .map((num) => num.value)
      .reduce((string, part) => string + part);

    if (removeZeros) {
      number = number.replace(/\D00(?=\D*$)/, '');
    }

    if (!suffix) {
      number = `${currency} ${number}`;
    }

    return number;
  },

  //periodicity_enum_code
  formatperiodicity: (periodicity) => {
    // To set it up as a global function:
    let periodicity_enum_code = periodicity?.split('Per');
    return periodicity_enum_code ? periodicity_enum_code[1] : '';
  },

  encodeBackUrl: () => {
    return btoa(window.location.pathname + window.location.search);
  },
  decodeBackUrl: (backUrl) => {
    let decodeUrl = '';
    if (backUrl !== undefined) {
      decodeUrl = atob(backUrl);
    }
    return decodeUrl;
  },

  encodeCustomUrl: (backLink) => {
    return btoa(backLink);
  },

  formatPeriodicityEnum: (periodicityEnum = 1) => {
    const PERIODICITY_ENUM_CODE_MAP = {
      1: '',
      2: 'Day',
      3: 'Week',
      4: 'Month',
    };
    return PERIODICITY_ENUM_CODE_MAP[periodicityEnum] || '';
  },

  formatQtyPricePerUOM: (number, suffix = '', removeZeros = true) => {
    // To set it up as a global function:
    number = number || 0;
    number = parseFloat(number.toString().replace(/[^0-9-.]/g, '')).toFixed(3);

    const numberParts = amountFormatter.formatToParts(number);
    number = numberParts
      .filter(({ type, value }) => {
        if (type !== 'currency') {
          return value;
        }
        return false;
      })
      .map((num) => num.value)
      .reduce((string, part) => string + part);

    const currency = numberParts
      .filter(({ type, value }) => {
        if (type === 'currency') {
          return value;
        }
        return false;
      })
      .map((num) => num.value)
      .reduce((string, part) => string + part);

    if (removeZeros) {
      number = number.replace(/\D00(?=\D*$)/, '');
    }

    if (suffix) {
      number = `${currency} ${number} /${suffix}`;
    }

    return number;
  },
  formatNegativePricePerUOM: (number, suffix = '', removeZeros = true) => {
    // To set it up as a global function:
    number = number || 0;
    number = parseFloat(number.toString().replace(/[^0-9-.]/g, '')).toFixed(3);

    const numberParts = amountFormatter.formatToParts(number);
    number = numberParts
      .filter(({ type, value }) => {
        if (type !== 'currency') {
          return value;
        }
        return false;
      })
      .map((num) => num.value)
      .reduce((string, part) => string + part);

    const currency = numberParts
      .filter(({ type, value }) => {
        if (type === 'currency') {
          return value;
        }
        return false;
      })
      .map((num) => num.value)
      .reduce((string, part) => string + part);

    if (removeZeros) {
      number = '(' + number.replace(/\D00(?=\D*$)/, '') + ')';
    }

    if (suffix) {
      number = `${currency} ${number} /${suffix}`;
    }

    return number;
  },

  // redirect: (url) => {
  //   window.location.href = url;
  // },

  /*
    /// Disabled cause need moment replacement
    getRemainingTime: datetime => {

        if (moment().isAfter(moment(datetime))) {
            return '0';
        }

        let diff = moment(datetime).unix() - moment().unix();
        const days = parseInt(diff / (24 * 60 * 60));

        diff = diff % (24 * 60 * 60);

        const hours = parseInt(diff / (60 * 60));
        diff = diff % (60 * 60);

        const mins = parseInt(diff / 60);

        return days + ' Days: ' + hours + ' Hrs: ' + mins + ' mins';
    },
    */
  formatPhone: (input) => {
    // Strip all characters from the input except digits
    input = input.replace(/\D/g, '');

    // Trim the remaining input to ten characters, to preserve phone number format
    input = input.substring(0, 10);

    // Based upon the length of the string, we add formatting as necessary
    const size = input.length;
    if (size === 0) {
      // input = input;
    } else if (size < 4) {
      input = `(${input}`;
    } else if (size < 7) {
      input = `(${input.substring(0, 3)}) ${input.substring(3, 6)}`;
    } else {
      input = `(${input.substring(0, 3)}) ${input.substring(3, 6)} - ${input.substring(6, 10)}`;
    }
    return input;
  },

  youtubeParser: (url) => {
    let youTubeLink;
    function getAllLinks(linkText) {
      return linkText.match(/<a\s+(?:[^>]*?\s+)?href="([^"]+[^"]+)"/g) || [];
    }
    let regExp =
      /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/gm;
    const youTubeLinks = getAllLinks(url).map((link) => link.slice(9, -1));

    for (let link of youTubeLinks) {
      const match = link.match(regExp);
      if (match) {
        youTubeLink = match[0];
        break;
      }
    }
    return youTubeLink;
  },

  getDistanceFromLatLong: (coords1, coords2) => {
    const toRad = (x) => {
      return (x * Math.PI) / 180;
    };

    let dLat = toRad(coords2.latitude - coords1.latitude);
    let dLon = toRad(coords2.longitude - coords1.longitude);

    let a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRad(coords1.latitude)) * Math.cos(toRad(coords2.latitude)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);

    return (12742 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))).toFixed(2) + ' ' + 'km';
  },

  DeleteRequirementOrOfferForMatchingPopup: ({
    id,
    history,
    type,
    COMMODITY_TYPE,
    requirementsActions,
    offersActions,
  }) => {
    const unSubscribe = history.listen(() => {
      // console.log('MatchingPopUp .busy commodity Details', history);
      if (
        history.location.pathname === `/commodity/requirements/edit/${id}` ||
        history.location.pathname === `/commodity/offers/edit/${id}`
      ) {
        if (type === COMMODITY_TYPE.REQUIREMENTS) {
          globalThis.store.dispatch(requirementsActions.delete(id));
          return;
        }
        if (type === COMMODITY_TYPE.OFFERS) {
          globalThis.store.dispatch(offersActions.delete(id));
          return;
        }
      }
    });
    return unSubscribe;
  },

  caculateFinalQuantity,
  formatLocationString,

  // Native Format Constructor Methods
  formatPrice: (price, country = 'en-IN', currency = 'INR', maximumFractionDigits = 3, options) =>
    new Intl.NumberFormat(country, { style: 'currency', currency, maximumFractionDigits, ...options }).format(price),
  formatPriceWithoutDecimal: (price, country = 'en-IN', currency = 'INR', maximumFractionDigits = 0) =>
    new Intl.NumberFormat(country, { style: 'currency', currency, maximumFractionDigits }).format(price),
  formatNumber: (number, country = 'en-IN', maximumFractionDigits = 4) => {
    let formattedPrice = new Intl.NumberFormat(country, maximumFractionDigits ? { maximumFractionDigits } : {}).format(
      number,
    );
    if (number.includes('.', number.length - 1)) {
      formattedPrice = formattedPrice + '.';
    }
    return formattedPrice;
  },

  getSearchParams: () => {
    const url = new URL(getDecryptedUrl(window.location.href));
    let params = new URLSearchParams(url?.search);
    if (params?.size) {
      params?.delete('page');
      params?.delete('back');
      return params?.toString();
    }
  },
};

export default utils;

/**
 * Lighten or Darken Color
 *
 * The CSS preprocessors Sass and LESS can take any color and darken() or
 * lighten() it by a specific value. But no such ability is built into
 * JavaScript. This function takes colors in hex format (i.e. #F06D06, with or
 * without hash) and lightens or darkens them with a value.
 *
 * @param {String} colorCode The hex color code (with or without # prefix).
 * @param {Int} amount
 */
export const LightenDarkenColor = (colorCode, amount) => {
  let usePound = false;

  if (colorCode[0] === '#') {
    colorCode = colorCode.slice(1);
    usePound = true;
  }

  const num = parseInt(colorCode, 16);

  let r = (num >> 16) + amount;

  if (r > 255) {
    r = 255;
  } else if (r < 0) {
    r = 0;
  }

  let b = ((num >> 8) & 0x00ff) + amount;

  if (b > 255) {
    b = 255;
  } else if (b < 0) {
    b = 0;
  }

  let g = (num & 0x0000ff) + amount;

  if (g > 255) {
    g = 255;
  } else if (g < 0) {
    g = 0;
  }

  return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
};

export const ColorLuminance = (hex, lum) => {
  // validate hex string
  hex = String(hex).replace(/[^0-9a-f]/gi, '');
  if (hex.length < 6) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  lum = lum || 0;

  // convert to decimal and change luminosity
  let rgb = '#';
  let c;
  let i;
  for (i = 0; i < 3; i++) {
    c = parseInt(hex.substr(i * 2, 2), 16);
    c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
    rgb += `00${c}`.substr(c.length);
  }

  return rgb;
};

const formatPrice = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const useAnalyticsEventTracker = () => {
  const eventTracker = (eventCategory) => {
    // console.log('hitType, eventCategory, eventAction,eventLabel<-->', eventCategory);
    ReactGA.send(eventCategory);
    // console.log('Update_Supplier_Profile',ReactGA.ga('event', 'Update_Supplier_Profile', { ProfileData : params }))
    //gtag('event', 'Update_Supplier_Profile', { ProfileData : params })
    // ReactGA.event(eventCategory)
  };
  return eventTracker;
};
