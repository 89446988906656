import React, { useState, useEffect, useRef } from 'react';
import { Button, ButtonToolbar, Row, Card, CardBody } from 'reactstrap';
// import { Field, reduxForm } from 'redux-form';
import { Link, NavLink, useHistory, useRouteMatch } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import * as handlers from './handlers';
import { actions as userActions } from 'redux/user';
import { actions as locationActions } from 'redux/locations';
import EditIcon from 'mdi-react/SquareEditOutlineIcon';
import DeleteIcon from 'mdi-react/DeleteIcon';
import PlusIcon from 'mdi-react/PlusIcon';
import LogoLoader from 'common/LogoLoader';
import {  Skeleton } from 'antd';

import ConfirmDeleteModal from './../../components/ConfirmDeleteModal';
import utils, {  decryptionInHex, getUrlWithEncryptedQueryParams } from 'lib/utils';
import LocationForm from './LocationForm';
import PopupForm from 'common/Form/PopupForm';

import { Table, Tag } from 'antd';
import AntTableActions from 'common/AntTableActions';
import ErrorOutlineRoundedIcon from '@material-ui/icons/ErrorOutlineRounded';
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import AntPagination from 'common/AntTablePagination';
import useQuery from 'lib/hooks/useQuery';
import FilterUtils from 'lib/filterUtils';
import TextSearchFilter from 'common/Form/AntFilters/TextSearchFilter';
import { SearchOutlined, FilterOutlined, CalendarOutlined } from '@ant-design/icons';


const Organization = (props) => {
  //optimize this
  const id = props.id;
  const companyId =props.companyId;
  const isCompanyUser = props.isCompanyUser;
  // console.log('id in location index', id);
  const user = useSelector((state) => state.user);
  const locations = useSelector((state) => state.locations);
  const locationList = locations.entities || {};
  const locationsBusy = locations.busy || false;
  // const person = _.get(user, 'profile.person') || {};
  // const address = _.get(user, 'profile.person_adress') || {};
  // const hashTags = _.get(user, 'profile.person_hashtags') || [];
  // console.log('locationList', user);

  const dispatch = useDispatch();
  const history = useHistory();
  const tableRef = useRef();
  let { page = 1, back, filter_company_name, filter_address_text, filter_address_type_enum, filter_gstin, filter_location_code } = useQuery();
  const [deleteLocationId, setDeleteLocationId] = useState(0);
  const [filters, setFilters] = useState({});
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [scrollToFirstRow, setScrollToFirstRow] = useState(false);
  const [isRerenderRequired, setIsRerenderRequired] = useState(false);

  const createMatch = useRouteMatch(getUrlWithEncryptedQueryParams(`/account/${id}/organisations/locations/create`));
  const editMatch = useRouteMatch(getUrlWithEncryptedQueryParams(`/account/${id}/organisations/locations/edit/:id`));

  let tableData = Object.values(locationList || {});
  const totalRecords = _.get(tableData, `[0].total_count`) || 0;

  let locationId = null;
  if (createMatch) {
    locationId = 0;
  } else if (editMatch) {
    locationId = _.get(editMatch, 'params.id') || 0;
    locationId = decryptionInHex(locationId);
  }

  let idCompare = parseInt(id);

  let isAdmin;
  if (idCompare !== window.user.userid) {
    isAdmin = true;
  }
  if (idCompare === window.user.userid) {
    isAdmin = false;
  }

  tableData = tableData.map((d) => {

    return {
      ...d,
      editPageLink: getUrlWithEncryptedQueryParams(`/account/${id}/organisations/locations/edit/${d['company_address_id']}?page=${page}`),
    };
  });

  const handleDelete = async (id) => {
    try {
      let res = await handlers.handleDestroyLocation(id);
      if(res) {
        setIsRerenderRequired(!isRerenderRequired)
      }
    } catch (err) {
      console.log('ConfirmDeleteModal-->handleConfirmErr---->', err);
    }
  };

  const cancel = (e) => {
    console.log(e);
  };

  const handleReset = (clearFilters, dataIndex) => {
    clearFilters();
    let params = {
      ...filters,
    };
    switch (dataIndex) {
      default:
        delete params[dataIndex];
        setFilters(params);
        history.push(FilterUtils.removeUrl(dataIndex));
        break;
    }
  };

  const onFilterChange = (e, dataIndex, confirm) => {
    confirm();
    let params = {
      ...filters,
    };
    switch (dataIndex) {
      default:
        params = {
          ...params,
          [dataIndex]: e,
        };
        break;
    }
    setFilters(params);
    history.push(FilterUtils.createUrl(params));
    setScrollToFirstRow(true);
  };


  const clearFilters = () => {
    setFilters({});
    let pathname = window.location.pathname;
    if (back != undefined || back != null) {
      history.push(pathname + '?' + `back=${back}&page=1`);
    } else {
      history.push(pathname + '?' + `&page=1`);
    }
  };

  const handlePageChange = (updatePage) => {
    if (!_.isEmpty(updatePage)) {
      let params = {
        ...filters,
        page: updatePage ? updatePage.current : 1,
      };
      history.push(FilterUtils.createUrl(params));
      if (updatePage.pageSize !== rowsPerPage) {
        setRowsPerPage(updatePage.pageSize);
      }
    }
  };

  if (page === undefined) {
    page = 1;
    handlePageChange({ current: page, pageSize: rowsPerPage });
  }

  const getSearchIcon = (filtered, dataIndex) => {
    return <SearchOutlined style={{ color: filtered ? 'var(--unnamed-color-1890ff)' : undefined }} />;
  };

  const getFilteredIcon = (filtered, dataIndex) => {
    return (
      <FilterOutlined
        style={{ color: filters.hasOwnProperty(dataIndex) ? 'var(--unnamed-color-1890ff)' : undefined }}
      />
    );
  };

  const LOCATION_TABLE_HEADER = [
    {
      title: 'Business Entity',
      dataIndex: 'company_name',
      width: '150px',
      align: 'center',
      render: (value, row, index) => {
        const obj = {
          children: <div style={{ wordBreak: 'break-word' }}>{row?.company_affiliate_enum === 2 ? row?.affiliate_name : row?.company_name}</div>,
          props: {},
        };
        if (index == 0) {
          obj.props.rowSpan = row.rowSpan;
        } else {
          obj.props.rowSpan = row.rowSpan;
        }
        return obj;
      },
      filterDropdown: (props) => (
        <TextSearchFilter
          dataIndex={'filter_company_name'}
          data={props}
          onFilterChange={onFilterChange}
          handleReset={handleReset}
          value={filter_company_name}
          name={'Business Entity'}
        />
      ),
      filterIcon: (filtered) => getSearchIcon(filtered, 'filter_company_name'),
      filteredValue: filter_company_name !== undefined ? filter_company_name : null,
    },
    {
      title: 'GSTIN',
      dataIndex: 'gstin',
      width: '150px',
      align: 'center',
      render: (value, row, index) => {
        const obj = {
          children: value,
          props: {},
        };
        if (index == 0) {
          obj.props.rowSpan = row.rowSpan;
        } else {
          obj.props.rowSpan = row.rowSpan;
        }
        return obj;
      },
      filterDropdown: (props) => (
        <TextSearchFilter
          dataIndex={'filter_gstin'}
          data={props}
          onFilterChange={onFilterChange}
          handleReset={handleReset}
          value={filter_gstin}
          name={'GSTIN'}
        />
      ),
      filterIcon: (filtered) => getSearchIcon(filtered, 'filter_gstin'),
      filteredValue: filter_gstin !== undefined ? filter_gstin : null,
    },
    {
      title: 'Location Name',
      dataIndex: 'address_text',
      width: '150px',
      align: 'center',
      render: (value, row, index) => {
        const obj = {
          children: (
            <div>
              <span style={{ wordBreak: 'break-word' }}>{value}</span>
              <div
                style={{ position: 'absolute', top: 0, right: 0 }}
                className="w-100 d-flex justify-content-between align-items-center">
                {row?.is_sez_location && row?.sez_registration_number && (
                  <Tag className="ml-1 mt-1 d-flex justify-content-between align-items-center" color="gold">
                    <ErrorOutlineRoundedIcon style={{ height: '14px', width: '14px', marginRight: 6 }} /> SEZ
                  </Tag>
                )}
                {row?.is_lut_location && row?.lut_number && (
                  <Tag className="ml-1 mr-1 mt-1 d-flex justify-content-between align-items-center" color="lime">
                    <CheckCircleOutlineRoundedIcon style={{ height: '14px', width: '14px', marginRight: 6 }} /> LUT
                  </Tag>
                )}
              </div>
            </div>
          ),
          props: {},
        };
        if (index == 0) {
          obj.props.rowSpan = row.rowSpan;
        } else {
          obj.props.rowSpan = row.rowSpan;
        }
        return obj;
      },
      filterDropdown: (props) => (
        <TextSearchFilter
          dataIndex={'filter_address_text'}
          data={props}
          onFilterChange={onFilterChange}
          handleReset={handleReset}
          value={filter_address_text}
          name={'Location Name'}
        />
      ),
      filterIcon: (filtered) => getSearchIcon(filtered, 'filter_address_text'),
      filteredValue: filter_address_text !== undefined ? filter_address_text : null,
    },
    {
      title: 'Address',
      dataIndex: 'location',
      width: '200px',
      align: 'start',
      render: (value, row, index) => {
        const obj = {
          children: <div style={{ wordBreak: 'break-word' }}>{utils.getLocationAddress(row).addStr}</div>,
          props: {},
        };
        if (index == 0) {
          obj.props.rowSpan = row.rowSpan;
        } else {
          obj.props.rowSpan = row.rowSpan;
        }
        return obj;
      },
      filterDropdown: (props) => (
        <TextSearchFilter
          dataIndex={'filter_location_code'}
          data={props}
          onFilterChange={onFilterChange}
          handleReset={handleReset}
          value={filter_location_code}
          name={'Address'}
        />
      ),
      filterIcon: (filtered) => getSearchIcon(filtered, 'filter_location_code'),
      filteredValue: filter_location_code !== undefined ? filter_location_code : null,
    },
    {
      title: 'Location type',
      dataIndex: 'address_type_enum_code',
      width: '150px',
      align: 'center',
      render: (value, row, index) => {
        const obj = {
          children: value,
          props: {},
        };
        if (index == 0) {
          obj.props.rowSpan = row.rowSpan;
        } else {
          obj.props.rowSpan = row.rowSpan;
        }
        return obj;
      },
      filterDropdown: (props) => (
        <TextSearchFilter
          dataIndex={'filter_address_type_enum'}
          data={props}
          onFilterChange={onFilterChange}
          handleReset={handleReset}
          value={filter_address_type_enum}
          name={'Location type'}
        />
      ),
      filterIcon: (filtered) => getSearchIcon(filtered, 'filter_address_type_enum'),
      filteredValue: filter_address_type_enum !== undefined ? filter_address_type_enum : null,
    },
    {
      title: 'Circle',
      dataIndex: 'circle_code',
      width: '150px',
      align: 'center',
      render: (value, row, index) => {
        const obj = {
          children: value,
          props: {},
        };
        if (index == 0) {
          obj.props.rowSpan = row.rowSpan;
        } else {
          obj.props.rowSpan = row.rowSpan;
        }
        return obj;
      },
    },
    {
      title: 'Actions',
      dataIndex: 'address_text_',
      width: '100px',
      align: 'center',
      className: { display: 'none' },
      render: (value, row, index) => {
        const obj = {
          children: (
            <AntTableActions
              row={row}
              handleDelete={handleDelete}
              id={row.company_address_id}
              name={row.company_name}
              cancel={cancel}
              showFlag={!isAdmin && isCompanyUser}
            />
          ),
          props: {},
        };
        if (index == 0) {
          obj.props.rowSpan = row.rowSpan;
        } else {
          obj.props.rowSpan = row.rowSpan;
        }
        return obj;
      },
    },
  ];


  //==============================================================
  useEffect(() => {
    // if (locations.dirty) {
    // const params = {
    //   person_id: parseInt(id),
    // };
    // dispatch(locationActions.fetchList(params));
    const params = {
      filters,
      offset: (parseInt(page) - 1) * rowsPerPage,
      page_Size: rowsPerPage,
      company_id:parseInt(companyId)
    };
    // const company_Id = parseInt(companyId);
    dispatch(locationActions.fetchCompanyAndAffiliateLocations(params));
    // }
  }, [locations.dirty, page, rowsPerPage, filter_company_name, filter_address_text, filter_address_type_enum, filter_gstin, isRerenderRequired, filter_location_code]);

  // if (locations.busy) {
  //   return (
  //     <div
  //       className=""
  //       style={{
  //         position: 'relative',
  //         minHeight: '150px',
  //       }}>
  //       <div className="panel__refresh">
  //         <LogoLoader />
  //       </div>
  //     </div>
  //   );
  // }

  // console.log('\n--------------Organization--------------------------');
  // console.log('companyId--->', companyId);
  // console.log('companyData--->', companyData);
  // console.log('\n---------------/Organization-------------------------');

  useEffect(() => {
    if (scrollToFirstRow && tableRef.current) {
      const firstRow = tableRef.current.querySelector('.ant-table-row');
      if (firstRow) {
        firstRow.scrollIntoView({ behavior: 'smooth' });
      }
      setScrollToFirstRow(false);
    }
  }, [scrollToFirstRow]);

  return (
    <React.Fragment>
      <div className="form-container">
        <div className="farm-wrapper">
          <div className="farm-table">
            <div className="table-farms-wrapper">
              {!isAdmin && isCompanyUser && (
                <div className="addnew-row add-new-btn-box">
                  <Button
                    onClick={() => history.push(getUrlWithEncryptedQueryParams(`/account/${id}/organisations/locations/create`))}
                    type="button"
                    className="add-new-btn"
                    size="sm">
                    <span className="icon">+</span>
                    <span className="text">Add Business Location</span>
                  </Button>
                </div>
              )}
              <Table
                ref={tableRef}
                columns={LOCATION_TABLE_HEADER}
                dataSource={tableData}
                bordered
                size="small"
                pagination={false}
                className="ant-table"
                style={{ whiteSpace: 'pre' }}
                onChange={handlePageChange}
                scroll={{ y: 550 }}
              />
              <AntPagination
                total={parseInt(totalRecords)}
                handlePageChange={handlePageChange}
                page={page}
                clearFilters={clearFilters}
                rowsPerPage={rowsPerPage}
                setScrollToFirstRow={setScrollToFirstRow}
              />
            </div>
          </div>
        </div>
        <ConfirmDeleteModal
          entityId={deleteLocationId}
          handleDelete={handlers.handleDestroyLocation}
          closeModal={() => setDeleteLocationId(0)}
          color="danger"
          title="Confirm Delete"
          header
          btn="Danger"
          message="Are you sure you want to delete this Location?"
          backLink="/account/organisations"
        />
      </div>

      {editMatch || createMatch ? (
        <div>
          <PopupForm
            title={'Business Location'}
            onClose={() => history.push(getUrlWithEncryptedQueryParams(`/account/${id}/organisations?page=${page}`))}>
            <LocationForm locationId={locationId} id={id} setIsRerenderRequired={setIsRerenderRequired} isRerenderRequired={isRerenderRequired} page={page} />
          </PopupForm>
        </div>
      ) : null}
    </React.Fragment>
  );
};

export default Organization;
